import React from 'react'
import { Document, Page, Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";
import Emitter from './Emitter';
import Receiver from './Receiver';
import InfoDgi from './InfoDgi'
import Table from './Table/Table';
import FontsRoboto from './Table/Fonts/Roboto-Bold.ttf'
//Utils 
import { RenderIf } from '../utils/RenderIf';
import QRCode from 'qrcode';


Font.register({
    family: 'Roboto',
    fonts: [
        {
            src: FontsRoboto,
            fontWeight: 'bold',
        },
    ],
});

const styles = StyleSheet.create({

    body: {
        paddingTop: 15,
        paddingBottom: 20,
        paddingHorizontal: 15,

    },
    subHeader: {
        fontFamily: 'Roboto',
        textAlign: 'center',
    },
    page: {
        backgroundColor: 'white'
    },
    mainTitle: {
        fontSize: 13,
        fontWeight: 500,
        textTransform: 'uppercase',
    },

    mainSubTitle: {
        fontSize: 12,
        textAlign: 'center',

    },

    textNormal: {
        fontSize: 10,
        fontWeight: 20
    },
    textNormalH: {
        fontSize: 10,
        left: 290
    },
    textNumber: {
        fontSize: 10,
        position: 'absolute',
        left: 300
    },
    section: {
        flexDirection: 'row',
    },

    marginTop: {
        marginTop: 10,
    },

    products: {
        fontSize: 12,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 10,
        paddingVertical: 1,
    },
    card: {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
    },
    image: {
        width: 150,
        height: 75,
        marginVertical: 1,
        objectfit: 'cover',
    },

    imageQr: {
        width: 170,
        height: 170,
        marginVertical: 1,
        objectfit: 'cover',
        position: 'absolute',
        top: '0',
        right: '0',
    },

    table: {
        fontSize: 11,
        flexDirection: "column",
    },
    footer: {
        fontSize: 9,
        marginTop: 15,
        marginBottom: 2,
        paddingHorizontal: 50,
        paddingVertical: 5,
        textAlign: 'center',
        color: 'grey',
    },
});

function DocPdf({ doc_type, emitter, receptor, products, totalCashReceived, invoiceTotal, invoiceTotalItbms, paymentMethod, invoiceSubtotal, totalTaxes, cufe, auth_protocol, invoiceDate, invoiceQR }) {
    // Generar el código QR a partir del texto dado
    const qrCodeCanvas = document.createElement('canvas');
    QRCode.toCanvas(qrCodeCanvas, invoiceQR, { width: 200, errorCorrectionLevel: 'H' });
    const qrCodeDataUrl = qrCodeCanvas.toDataURL('image/png');

    return (
        <>

            <Document >
                <Page size="A4" style={styles.body}>
                    {/* //Encabezado */}
                    <View style={styles.subHeader} >
                        {/* <Text style={styles.mainSubTitle}>DGI</Text> */}
                        <Text style={styles.mainTitle}>

                            <RenderIf isTrue={doc_type === 'price'}>
                                Cotizacion
                            </RenderIf>

                            <RenderIf isTrue={doc_type === "invoice"}>
                                Comprobante Auxiliar de Factura Electrónica
                            </RenderIf>

                            <RenderIf isTrue={doc_type === "credit_note"}>
                                Comprobante Auxiliar de Nota de credito
                            </RenderIf>
                        </Text>
                        <Text style={styles.mainSubTitle}>


                            <RenderIf isTrue={doc_type === 'price'}>
                                Documento valido para la cotización
                            </RenderIf>

                            <RenderIf isTrue={doc_type === 'invoice'}>
                                Factura de Operación Interna
                            </RenderIf>

                            <RenderIf isTrue={doc_type === 'credit_note'}>
                                Nota de credito referenta a una o varias FE
                            </RenderIf>
                        </Text>
                    </View>
                    {doc_type !== 'price' ?<><View style={styles.header}>
                        <Image style={styles.imageQr} src={qrCodeDataUrl} />
                    </View></>:<></>}
                    


                    <View>
                        {/* //Datos del emisor   */}
                        <Emitter emitter_data={emitter} />
                        {/* //Datos del Receptor  */}
                        <Receiver receptor_data={receptor} invoiceDate={invoiceDate} />
                    </View>

                    <View style={styles.section}>
                        {doc_type !== 'price' ?<><InfoDgi cufe={cufe} auth_protocol={auth_protocol} invoiceDate={invoiceDate} /></>:<></>}
                    </View>

                    {/* Data  Table */}
                    <Table doc_type={doc_type} totalTaxes={totalTaxes} products={products} totalCashReceived={totalCashReceived} invoiceTotal={invoiceTotal} invoiceTotalItbms={invoiceTotalItbms} paymentMethod={paymentMethod} invoiceSubtotal={invoiceSubtotal} />

                    {/* Footer*/}
                    <Text style={styles.footer} render={({ pageNumber, totalPages }) => (
                        `Página ${pageNumber} / ${totalPages}`
                    )} fixed />
                    <Text style={styles.footer} fixed>
                        Documento validado por La Empresa, SISTEMA DE PRODUCCIÓN DESARROLLO Y ADMINISTRACIÓN STK PANAMÁ, S. A. con RUC 155704603-2-2021,
                        es Proveedor Autorizado Calificado,
                        Resolución No. 201-9720 de 12/10/2021.
                    </Text>
                </Page>
            </Document>


        </>
    )
}

export default DocPdf

