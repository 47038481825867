import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'

import { PreOrderFetchAPI } from '../../api/Paymentpost/PreOrder';
import { DeletedTempOrdersFetchAPI } from '../../api/DeletedTemporalOrders';

import Swal from "sweetalert2";
import imgPreOrder from './img/preOrder.png'
import ErrorAlert from '../Alerts/ErrorAlert';
import LoadingAlert from '../Alerts/LoadingAlert';
import { GiPlayerTime } from 'react-icons/gi';
import Empty from '../utils/Empty';
import ModalDetailPreOrder from './ModalDetailPreOrder';
import refund from '../img/icons/refund.png'


function ModalDivisionequalparts({ order, setShowDivision, getAll, codeParthner }) {
    const [showParts, setShowParts] = useState(false);
    let count = 0
    const [dataOrder, setDataOrder] = useState({})
    const [division, setDivision] = useState("");
    const [eventHandler, setEventHandler] = useState(0);

    const [dataPreviewTotals, setDataPreviewTotals] = useState({ discount: 0, subtotal: 0, tax: 0, total: 0 })

    let parts = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

    //create preOrder
    const createPreOrder = async (dataOrder, part, invoiceTotalDiscount, invoiceSubtotal, invoiceTotalItbms, invoiceTotal, taxesArray) => {
        let token = localStorage.getItem('x-access-machine-token');
        let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
        let machine = JSON.parse(localStorage.getItem('machine'))
        let user = JSON.parse(localStorage.getItem('user'));


        let json = {
            "client_name": dataOrder.client_name + " " + (count + 1),
            "localizador": dataOrder.locator,
            "client_phone": dataOrder.client_phone,
            "ruc": dataOrder.client_ruc,
            "dv": dataOrder.client_dv,
            "id_Client": 0,
            "dgi_qr": "0",
            "Orden": dataOrder.groups,
            "combos": dataOrder.combos,
            "Descuento": parseFloat(invoiceTotalDiscount),
            "discount": parseFloat(invoiceTotalDiscount),
            "Total": parseFloat(invoiceTotal),
            "itbms": parseFloat(invoiceTotalItbms),
            "taxes": taxesArray,
            "subtotal": parseFloat(invoiceSubtotal),
            "machine_id": machine.id,
            "branchoffice_id": branchoffice.id,
            "tipoOrden": dataOrder.order_type,
            "paymethod": 0,
            "is_discount_notification": false,
            "is_cupon": false,
            "is_devolution_notification": false,
            "is_pre_order": true, "user": {
                id: user.id,
                name: user.name,
                code: user.user_code
            }
        }



        try {
            const response = await PreOrderFetchAPI.createPreOrder(json, token)
            count = (count + 1)
            let message = "Precuenta creada con exito."
            let title = "Operacion exitosa."
            let icon = "success"

            console.log(part + "    " + count);
            if (part > count) {
                console.log(" entre: " + count);
                createPreOrder(dataOrder, part, invoiceTotalDiscount, invoiceSubtotal, invoiceTotalItbms, invoiceTotal)
            } else {
                count = 0
                setShowParts(false);
                setShowDivision(false);
                deletePreOrder(dataOrder.code);
                ErrorAlert(message, title, icon);


            }

        } catch (err) {
            ErrorAlert("", "Ocurrio un error ", "error")
        }

    }

    //delete preorder
    const deletePreOrder = async (code) => {
        LoadingAlert()
        let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
        let business = JSON.parse(localStorage.getItem('business'))
        let user = JSON.parse(localStorage.getItem('user'));
        let machine_token = localStorage.getItem('x-access-machine-token');

        let business_code = business.code 
        let branch_code = branchoffice.code
  
        if (codeParthner != 0) {
           business_code = codeParthner.business_code
           branch_code = codeParthner.branchoffice_code
        }

        let jsonUpdate = {
            "order_code": code,
            "business_code": business_code,
            "branchoffice_code": branch_code,
            "status": 5,
            "user_id": user.id
        }
        try {
            //We save the preorder
            await DeletedTempOrdersFetchAPI.newDeletedTempOrder(jsonUpdate, machine_token);
            //We delete the preorder
            await PreOrderFetchAPI.deletePreOrder(jsonUpdate, machine_token)
            let message = "precuenta eliminada con exito."
            let title = "Precuenta eliminada"
            let icon = "success"

            console.log("precuenta original eliminada");
            //ErrorAlert(message, title, icon)
            getAll();
        } catch (err) {
            console.log(err);
            ErrorAlert("Esta precuenta pertenece a otra sucursal", "Permiso denegado", "info")
        }
    }

    const getPreOrderByid = async (state) => {
        let machine_token = localStorage.getItem('x-access-machine-token');
        try {
            const response = await PreOrderFetchAPI.getPreOrderById(order._id, machine_token);
            setDataOrder(response.data.pre_order)

            if (state == true) {
                setDataPreviewTotals({ discount: parseFloat(response.data.pre_order.discount), subtotal: parseFloat(response.data.pre_order.subtotal), tax: parseFloat(response.data.pre_order.tax), total: parseFloat(response.data.pre_order.total) });
            }
        } catch (err) {
            console.log(err);
        }
    }



    const separateOrder = (part, state) => {
        if (part != 0 && part != "") {
            dataOrder.groups.forEach(group => {
                divitionPartGroups(group)
            });

            dataOrder.combos.forEach(combo => {
                let priceGroup = (combo.price / part).toFixed(2);
                combo.price = parseFloat(priceGroup)
                let discount = (combo.discount / part).toFixed(2);
                let unit_discount = (combo.unit_discount / part).toFixed(2);

                console.log(combo.discount);
                console.log(discount);
                if (discount != NaN && discount != null && discount != undefined) {
                    combo.discount = parseFloat(discount)
                    combo.unit_discount = parseFloat(unit_discount)
                }

                combo.setGroups.forEach(setGroup => {
                    setGroup.groups.forEach((group => {
                        divitionPartGroups(group)
                    }))
                });

            });

            function divitionPartGroups(group){
                let priceGroup = (group.price / part).toFixed(2);
                group.price = parseFloat(priceGroup)
                let discount = (group.discount / part).toFixed(2);
                let unit_discount = (group.unit_discount / part).toFixed(2);

                console.log(group.discount);
                console.log(discount);
                if (discount != NaN && discount != null && discount != undefined) {
                    group.discount = parseFloat(discount)
                    group.unit_discount = parseFloat(unit_discount)
                }

                group.segments.forEach(item => {
                    console.log("data del segmento");
                    console.log(item);
                    item.price = (item.item.price / part).toFixed(2);
                    item.item.price = (item.item.price / part).toFixed(2);
                });

                group.DetalleSegmentos = group.segments
            }

         

            calculateInvoiceTotals(dataOrder.groups, dataOrder.combos, state, part)
            console.log(dataOrder);

            /*
            let discount = 0;
            let subtotal = 0;
            let tax = 0;
            let total = 0;

            if (dataOrder.discount > 0) {
                discount = (dataOrder.discount / part).toFixed(2);
            }

            subtotal = (dataOrder.subtotal / part).toFixed(2);
            tax = (dataOrder.tax / part).toFixed(2);
            total = (dataOrder.total / part).toFixed(2);*/

        } else {

            setDataPreviewTotals({ discount: parseFloat(dataOrder.discount), subtotal: parseFloat(dataOrder.subtotal), tax: parseFloat(dataOrder.tax), total: parseFloat(dataOrder.total) });
        }
    }

    const calculateInvoiceTotals = (products, combos, state, part) => {

        //For every new calculate we set the totals
        let taxesArray = [];
        let invoiceTotalDiscount = 0
        let invoiceSubtotal = 0
        let invoiceTotalItbms = 0
        let invoiceTotal = 0

        //if we dont we products we put the totals in 0 for default
        if ((products.length+combos.length) > 0) {
            //For every product we calculate
            combos.forEach((combo) => {
                byProductandCombo(combo)
             })
    
             products.forEach((group) => {
                byProductandCombo(group)
             })

            function byProductandCombo(item) {
                let product_info = item
                //Variables
                let total_product_price = 0;
                let total_product_itbms = 0;
                let product_total_discount = 0;
                //We set the total discount of the product
                product_total_discount = (product_info.unit_discount * product_info.amount);
                //We set the total of the product
                total_product_price = (product_info.amount * (product_info.price - product_info.unit_discount));


                //Calculate tax by group--------------------------------
                let taxesGroup = product_info.taxes;
                taxesGroup.forEach(tax => {

                    //calculate tax  value and add it to array
                    let valueTax = (product_info.amount * ((product_info.price - product_info.unit_discount) * tax.taxtValue));
                    tax.total = valueTax;
                    //Identifier tax of the group position
                    var index = taxesArray.findIndex(function (info, i) {
                        return info.tax_id === tax.tax_id
                    });

                    if (index < 0) {
                        let taxInfoNow = {
                            "dTasaITBMSValue": tax.dTasaITBMSValue,
                            "percentage": tax.percentage,
                            "tax": tax.tax,
                            "tax_id": tax.tax_id,
                            "taxtValue": tax.taxtValue,
                            "total": valueTax
                        }
                        taxesArray.push(taxInfoNow);
                    } else {
                        taxesArray[index].total = (taxesArray[index].total + valueTax)
                    }
                    total_product_itbms = (total_product_itbms + valueTax);

                });
                //-------------------------------------------------------


                //We set the itbms of the product
                //total_product_itbms = (product_info.amount * ((product_info.price - product_info.unit_discount) * product_info.taxtValue));

                //We calculate the totals of the invoice
                invoiceTotalDiscount = invoiceTotalDiscount + product_total_discount;
                invoiceSubtotal = invoiceSubtotal + total_product_price;
                invoiceTotalItbms = invoiceTotalItbms + total_product_itbms;
                invoiceTotal = invoiceTotal + total_product_price + total_product_itbms;
            }

            console.log("Decuento:" + invoiceTotalDiscount);
            console.log("SubTotal:" + invoiceSubtotal);
            console.log("Itbms:" + invoiceTotalItbms);
            console.log("Total:" + invoiceTotal);


            console.log("Total de todas las partes:" + (invoiceTotal * part).toFixed(2));


            console.log({ discount: invoiceTotalDiscount, subtotal: invoiceSubtotal, tax: invoiceTotalItbms, total: invoiceTotal });
            setDataPreviewTotals({ discount: parseFloat(invoiceTotalDiscount), subtotal: parseFloat(invoiceSubtotal), tax: parseFloat(invoiceTotalItbms), total: parseFloat(invoiceTotal) });


            if (state == true) {
                createPreOrder(dataOrder, part, invoiceTotalDiscount, invoiceSubtotal, invoiceTotalItbms, invoiceTotal, taxesArray)
            } else {
                setDivision(part);
                setEventHandler(1)
            }

        }


    }


    const keyBoardNumeric = (value) => {
        getPreOrderByid();
        setDivision(division + value)
        separateOrder(division + value);
    }


    useEffect(() => {
        (async () => {
            getPreOrderByid(true);


        })();
    }, [])

    useEffect(() => {
        setEventHandler(0);
    }, [eventHandler])


    return (
        <>
            <button type="button" class="btn btn-outline-primary btn-lg btn-block m-3 p-lg-5 p-sm-2" onClick={() => setShowParts(true)}><div><i class="uil uil-apps display-1"></i><h4 className='fw-bold'> Partes iguales </h4></div></button>

            <Modal
                show={showParts}
                fullscreen
                onHide={() => setShowParts(false)}
                aria-labelledby="example-custom-modal-styling-title"
            >

                <Modal.Body scrollable>

                    <div>
                        <div className='mt-2 text-center p-1'>
                            <h4 className='fw-bold'>  <i class="uil uil-apps"></i> Dividir en partes iguales </h4>
                        </div>

                        <div >
                            {/*<ModalDetailPreOrder orderData={order}></ModalDetailPreOrder>*/}
                        </div>
                    </div>



                    <div className='container mt-5'>

                        <div className='row'>
                            <div className='col-md-4 d-flex justify-content-center alight-item-center'>

                                <div className=''>
                                    <h3 className=" text-center"><i class="uil uil-calculator"></i> Calculadora de cuentas</h3>
                                    <div className=' m-3'>
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                                <a href="#" className="list-group-item list-group-item-action d-flex gap-3 py-3 rounded" aria-current="true">
                                                    <div className="d-flex gap-2 w-100 justify-content-around">
                                                        <div>
                                                            <h6 className=" text-center">Ingrese cantidad de cuentas a dividir</h6>
                                                            <h3 className=" text-center">{division == "" ? "1" : division} / {order.total.toFixed(2)} = {dataPreviewTotals.total.toFixed(2)} </h3>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='d-flex justify-content-center alight-item-center mt-3'>
                                        <div className=' row  col-6'>
                                            {parts.map((index) => (
                                                <> <div className='col-sm-4 d-flex justify-content-center alight-item-center '><button type="button" class="btn btn-outline-primary m-2" onClick={() => (keyBoardNumeric(index))}><div><h4 className='fw-bold p-2'> {index} </h4></div></button></div>
                                                </>
                                            ))}
                                            <div className='col-sm-12 d-flex justify-content-center alight-item-center '><button type="button" class="btn btn-outline-primary m-2" onClick={() => (getPreOrderByid(), setDivision(""), separateOrder(""))}><div><h4 className='fw-bold p-2'> Limpiar </h4></div></button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className='col-md-8 d-flex justify-content-center alight-item-center row'>
                                <ModalDetailPreOrder orderData={order}></ModalDetailPreOrder>
                                <div className="item3 border-top shadow-sm py-2 m-5 ">
                                    <div className="  ">
                                        <h6 className=" text-center">Detalle de los totales en: {division}  partes</h6>
                                        <div className="mb-0 small lh-sm mt-1">
                                            <div className="d-flex justify-content-between">
                                                <small className="text-muted h6 subtitle"> Descuento:</small>
                                                <span className="text-warning text-secondary h6 subtitle" translate='no'>
                                                    <span className="px-2 subtitle">- $</span>
                                                    <strong> {dataPreviewTotals.discount.toFixed(2)} </strong>
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-between ">
                                                <small className="text-muted h6 subtitle"> Subtotal: </small>
                                                <span className="text-black text-secondary h6 subtitle" translate='no'>
                                                    <span className="px-2 subtitle">$</span>{dataPreviewTotals.subtotal.toFixed(2)}</span>
                                            </div>


                                            {order.taxes.map((tax) => (
                                                <>
                                                    {tax.total.toFixed(3) > 0 ?
                                                        <div className="d-flex justify-content-between">
                                                            <small className=" text-muted h6 subtitle"> Impuesto {tax.tax}:
                                                            </small><span className="text-black text-secondary h6 subtitle" translate='no'>
                                                                <span className="px-2 subtitle ">$</span>{tax.total.toFixed(3)}</span>
                                                        </div> : <></>}
                                                </>
                                            ))}

                                            <div className="d-flex justify-content-between total-shopping">
                                                <strong className="text-black fw-bold h6 mt-1 subtitle"> Total <small>(Incluye itbms)</small>: </strong>
                                                <span className="text-black fw-bold h5 subtitle" translate='no'>
                                                    <span className="px-2 ">$
                                                    </span>{dataPreviewTotals.total.toFixed(2)}</span>
                                            </div>
                                        </div>
                                    </div>


                                </div>


                            </div>
                        </div>
                    </div>




                </Modal.Body>

                <Modal.Footer>
                    <div className='d-flex justify-content-between mt-3'>
                        <button className='btn btn-danger btn-lg' onClick={() => setShowParts(false)}> Cancelar </button>
                        <button className='btn btn-primary btn-lg m-1' onClick={() => separateOrder(division, true)} > Dividir </button>
                    </div>
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default ModalDivisionequalparts