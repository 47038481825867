import React from 'react'
import Hero from './Hero'

function Index() {
  return (
    <>    
      <Hero />
    </>
  )
}

export default Index