import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
//Img
import logo_sp from '../img/logos/icon-black.png';
//Utils 
import { RenderIf } from '../utils/RenderIf';

const NavBar = () => {

    //Nav Bar 
    const [menuShow, setMenuShow] = useState(false);
    const [outLogShow, setOutLogShow] = useState(false);

    //Params 
    let { business_code, branchoffice_code } = useParams();
    let user = localStorage.getItem('user')

    const navigate = useNavigate();


    //We valilink if the user is logged in
    if (user === null || user === undefined || user === '') {
        user = {
            name: 'No registrado',
            img: 'https://www.pngitem.com/pimgs/m/30-307416_profile-icon-png-image-free-download-searchpng-employee.png'
        }
    } else {
        user = JSON.parse(localStorage.getItem('user'))
    }


    function signout() {
        //We delete the token 
        localStorage.setItem('x-access-token', "");
        //We check the role of the user and depending of it we redirect 
        if (user.role === 4) {
            navigate(`/cashier/login/${business_code}`)
        } else if (user.role === 5) {
            navigate(`/dispatcher/login/${business_code}`)
        } else if (user.role === 3 || user.role === 6) {
            navigate(`/login/${business_code}`)
        }
    }

    const toggleMenu = () => {
        setMenuShow(!menuShow);

    };

    const toggleLogOut = () => {
        setOutLogShow(!outLogShow);

    };

    const menuMain = [
        {
            title: 'Pagos',
            icon: "uil uil-bill px-1",
            link: `/transactions/${business_code}`,
            integrationOption: false
        },
        {
            title: 'Analíticas',
            icon: 'uil uil-file-graph px-1',
            link: `/analitics/${business_code}`,
            integrationOption: false
        },
        {
            title: 'Informes',
            icon: 'uil uil-file-landscape-alt px-1',
            link: `/reports/${business_code}`,
            integrationOption: false
        },
        {
            title: 'Productos',
            icon: 'uil uil-truck-loading px-1',
            link: `/products/${business_code}`,
            integrationOption: false
        },
        {
            title: 'Compras',
            icon: 'uil-shopping-bag px-1',
            link: `/buys/${business_code}`,
            integrationOption: false
        },
        {
            title: user.business.posIntegration !== null && user.business.posIntegration !== undefined ? user.business.posIntegration.name : 'Ninguna integracion',
            icon: 'uil-info-circle px-1',
            link: `/manager/integration/${business_code}`,
            integrationOption: true
        },
        {
            title: 'Precuentas',
            icon: 'uil uil-invoice px-1',
            link: `/temporal/${business_code}`, 
            integrationOption: false
        },
        {
            title: 'Webhook',
            icon: 'uil uil-sliders-v-alt px-1',
            link: `/webhook/${business_code}`, 
            integrationOption: false
        },
    ];

    const menuSecond = [

        {
            title: 'Pagos',
            icon: "uil uil-bill px-1",
            link: `/transactions/${business_code}`,
            integrationOption: false

        },
        {
            title: 'Analíticas',
            icon: 'uil uil-file-graph px-1',
            link: `/analitics/${business_code}`,
            integrationOption: false
        },
    ]




    return (
        <>
            <header className="header-nav-sp  shadow-sm">

                <span className="navbar-logo logo">
                    <img className="navbar-logo-icon" src={logo_sp} alt="Logo Smart Pay" />
                    <span className="navbar-logo-text">Smart Pay</span>
                </span>
                <div className={menuShow ? "nabvar-sp open-menu shadow-sm " : "nabvar-sp "}>
                    <RenderIf isTrue={user.role === 3 || user.role === 6}>
                        {menuMain.map((option, index) => (
                            <>
                                {/** We make a condition that render the options depending if the user has available the intregation option or not  */}
                                {option.integrationOption === false || (option.integrationOption === true && user.business.pos_integration_id !== null) ?
                                    <li key={index} >
                                        <span>
                                            <Link to={option.link} className=" text-sp-nav"  >
                                                <i className={option.icon}></i>  {option.title}
                                            </Link>
                                        </span>
                                    </li> : ""}
                            </>
                        ))}
                    </RenderIf>

                    <RenderIf isTrue={user.role === 5}>
                        <li>
                            <span>
                                <i className="uil uil-notes"></i>
                                <Link to={`/orders_customers/${business_code}/${branchoffice_code}`} className=" text-sp-nav" >
                                    Ordenes
                                </Link>
                            </span>
                        </li>
                    </RenderIf>

                    <RenderIf isTrue={user.role === 4}>
                        {menuSecond.map((option, index) => (
                            <>
                                <li key={index} >
                                    <span>
                                        <i className={option.icon}></i>
                                        <Link to={option.link} className=" text-sp-nav" >
                                            {option.title}
                                        </Link>
                                    </span>
                                </li>
                            </>
                        ))}
                        {/* <Link to={`/cashier/${business_code}/${branchoffice_code}`} className="button-nav-sp uil uil-tablet" title="Maquinas"></Link>
                        <Link to={`/cashier/report/${business_code}/${branchoffice_code}`} className="button-nav-sp uil uil-file-graph" title="Reportes cajera"></Link> */}

                    </RenderIf>

                    <li
                        className={!outLogShow ? "nabvar-sp-login-out" : "nabvar-sp-login-out open-log-out "}
                    >
                        <span onClick={() => signout()}>
                            <i className="uil uil-signout px-1"></i>
                            Cerrar Sesión
                        </span>
                        <div className="position-absolute top-0 start-100 translate-middle close-log-out">
                            <button className="btn btn-danger btn-sm rounded-pill" onClick={toggleLogOut}>
                                <i className="uil uil-multiply">
                                </i>
                            </button>
                        </div>
                    </li>

                </div>

                <div className="main-sp">
                    <span className="text-dark unselectable rounded-pill border border-2 px-1" onClick={toggleLogOut}>
                        <i className="uil uil-user-circle"></i>
                        {user.name + " " + user.lastName}
                    </span>
                    <span>
                        <i id="menu-icon"
                            className={menuShow

                                ? "uil uil-times ms-2 bg-light rounded-circle"
                                : "uil uil-bars ms-2 px-2"}
                            onClick={toggleMenu}>
                        </i>
                    </span>
                </div>

            </header>

            {/**We render the nav options depending the type of the user */}
            {/* <nav className="navbar  shadow-sm">
                <h1 className="navbar-logo">
                    <img className="navbar-logo-icon" src={logo_sp} alt="Logo Smart Pay" />
                    <span className="h5 navbar-logo-text">Smart Pay</span>
                </h1>
                <div className="navbar-menu">
                
                    <RenderIf isTrue={user.role === 3}>
                        <Link to={`/transactions/${business_code}`} className="button-nav-sp uil uil-bill" title="Pagos"></Link>
                        <Link to={`/analitics/${business_code}`} className="button-nav-sp  uil uil-file-landscape-alt" title="Analíticas"></Link>
                        <Link to={`/reports/${business_code}`} className="button-nav-sp uil uil-file-graph" title="Informes"></Link>
                        <Link to={`/products/${business_code}`} className="button-nav-sp uil uil-truck-loading" title="Productos"></Link>
                       */}  {/*<Link to={`/temporal/${business_code}`} className="button-nav-sp uil uil-transaction" title="Transaccion temporal"></Link>*/}
            {/* {<Link to={`/buys/${business_code}`} className="button-nav-sp uil-shopping-bag" title="Compras"></Link>}

                    </RenderIf>

                    <RenderIf isTrue={user.role === 5}>
                        <Link to={`/orders_customers/${business_code}/${branchoffice_code}`} className="button-nav-sp uil uil-notes" title="Ordenes"></Link>
                    </RenderIf>

                    <RenderIf isTrue={user.role === 4}>
                        <Link to={`/cashier/${business_code}/${branchoffice_code}`} className="button-nav-sp uil uil-tablet" title="Maquinas"></Link>
                        <Link to={`/cashier/report/${business_code}/${branchoffice_code}`} className="button-nav-sp uil uil-file-graph" title="Reportes cajera"></Link>

                    </RenderIf>


                    <div onClick={() => signout()} className="button-nav-sp text-dark  uil uil-signout" ></div>

                </div>
            </nav> */}
        </>
    );
}

export default NavBar;
