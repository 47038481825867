import axios from "axios";

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { "Content-Type": "application/json" },
});


export class PluxeeFetchAPI {


    // Function to get the Pluxee transaction
    static async getPluxeeTransaction(pluxee_transaction_id, pluxee_instance_id, machine_id, token) {
        const res = await server(`/pluxee?paymentIntentId=${pluxee_transaction_id}&instanceId=${pluxee_instance_id}&machine_id=${machine_id}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to make the reverse of a Pluxee transaction
    static async reversePluxeeTransaction(siteId, authID, machine_id, token) {
        const res = await server(`/pluxee?siteId=${siteId}&authID=${authID}&machine_id=${machine_id}`, {
            method: "delete",
            headers: { 'x-access-token': token }
        });
        return res
    }
    //Function to cancel the Pluxee transaction
    static async cancelPluxeeTransaction(pluxee_transaction_id, pluxee_instance_id, token) {
        const res = await server(`/pluxee?paymentIntentId=${pluxee_transaction_id}&instanceId=${pluxee_instance_id}`, {
            method: "put",
            headers: { 'x-access-token': token }
        });
        return res
    }

    //Function to create or get a pluxee voucher payment 
    static async pluxeeVoucherPayment(data, token) {
        const res = await server(`/pluxee/voucher`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token }
        });
        return res
    }

    //Function to make the reverse of a voucher Pluxee transaction
    static async reversePluxeeVoucherTransaction(authID, machine_id, token) {
        const res = await server(`/pluxee/voucher`, {
            method: "delete",
            headers: { 'x-access-token': token },
            data:{
                authID: authID,
                machine_id: machine_id
            }
        });
        return res
    }

}