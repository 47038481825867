//React components
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//Components 
import Navbar from './Nav/NavBar';
import TransactionHistory from "../transactions/TransactionHistory";
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import Modal from 'react-bootstrap/Modal';
//Internal components 
import ReportTable from '../reports/table/ReportTable';
import OrderDetails from '../Orders/OrderDetails';
//API
import { OrdersFetchAPI } from '../../api/Orders';
//We import the process payment function
import { PaymentOperations } from '../../utils/PaymentOperations';
//img
import empty from '../img/icons/empty.png'
import logo_sp from '../../assets/img/logos/icon-black.png';
//Utils 
import { RenderIf } from '../utils/RenderIf';

//External modules 
import moment from 'moment-timezone';



function AnaliticsPayment() {
  //Params
  let { business_code, branchoffice_code } = useParams();
  let selectedBranch = branchoffice_code
  //We get the user data 
  let user = localStorage.getItem('user');
  let token = localStorage.getItem('x-access-token');
  let generalData = localStorage.getItem('generalData');
  //We validate if the user is logged in
  if (user === null || user === undefined || user === '' || user === 'null') {
    user = {
      name: 'No registrado',
      img: 'https://www.pngitem.com/pimgs/m/30-307416_profile-icon-png-image-free-download-searchpng-employee.png'
    }
  } else {
    user = JSON.parse(localStorage.getItem('user'))
  }

  //States
  const [branchOrdersData, setBranchOrdersData] = useState([]);
  const [branchAnaliticsData, setBranchAnaliticsData] = useState([]);
  const [temporal, setTemporal] = useState([]);
  const [minDate, setMinDate] = useState(new Date().toJSON().slice(0, 10));
  const [userBranchoffices, setUserBranchs] = useState([]);
  //const [selectedBranch, setSelectedBranch] = useState({code: 0});
  const [totalOrders, setTotalOrders] = useState(0)
  const [subtotalOrders, setSubtotalOrders] = useState(0)
  const [taxOrders, setTaxOrders] = useState(0)

  const [ordersPaymentMethod, setOrdersPaymentMethod] = useState([])
  const [modalShow, setModalShow] = useState(false);
  const [modalShowDetail, setModalShowDetail] = useState(false);

  const [detailOrder, setDetailOrder] = useState();
  const [order_type, setOrderType] = useState("temporal");
  const [userBusiness, setUserBusiness] = useState({});
  const [reload, setReLoad] = useState();

  //Variables
  //We get the actual date 
  const actualDate = moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD')
  //We put the actual date in rank of all the day 
  const maxDate = actualDate + 'T23:59:59.000+00:00';

  //Functions to show and hide the modal 
  const handleCloseModal = () => setModalShow(false);
  const handleShowModal = () => setModalShow(true)

  //Functions to show and hide the modal 
  const handleCloseModalDetail = () => setModalShowDetail(false);
  const handleShowModalDetail = () => setModalShowDetail(true)


  //Function to gen the empty card to the transaction history 
  const EmptyCard = () => {
    return (
      <div className="m-3 w-auto ">
        <div className="border-history d-flex justify-content-md-center py-4 px-4 " aria-current="true">
          <div className="d-flex w-100 justify-content-center align-items-center  flex-column">
            <img src={empty} alt="Vacio" width="35px" height="35px" />
            <div className='text-center mt-3'>
              <h5>Vacío</h5>
              <h6 className="mb-0 text-secondary fw-bold">No hay transacciones </h6>
            </div>
          </div>
        </div>
      </div>
    )
  }


  //Function to get order by id (order_id)
  const getOrderById = async (order_id) => {
    try {
      const response = await OrdersFetchAPI.getOrderById(order_id);
      setDetailOrder(response.data.order)
      console.log(detailOrder)
      setOrderType("permanent")
    } catch (err) {
      setDetailOrder({})
      setOrderType("permanent")
      console.log(err)
    }
  }

  //Function to populate the order details (modal)
  const detailOrderList = () => {
    return detailOrder === undefined ? "" : <OrderDetails pos={true} orderData={detailOrder} order_type={order_type} business_data={userBusiness} setLoad={setReLoad} />
  }

  //Function to populate the list orders 
  const listOrders = () => {

    const listOrders = branchOrdersData.map((data) => (
      <>
        <div onClick={async () => {
          await getOrderById(data._id);
          handleShowModalDetail();
        }}>
          <TransactionHistory data={data} />
        </div>

      </>
    ));
    //We check if the list orders has data 
    if (listOrders.length === 0) {
      return EmptyCard()
    } else {
      return (listOrders)
    }
  }

  //We get the transactions by branchoffice 
  const getTransactionsByBranchOffice = async (business_code, branchoffice_code, minDate, maxDate) => {
    let codeParthner = localStorage.getItem('codeParthner');
    let bussinesCode = business_code
    let branchCode = branchoffice_code

    if (codeParthner !== null && codeParthner !== undefined && codeParthner !== '') {
      codeParthner = JSON.parse(localStorage.getItem('codeParthner'))
      bussinesCode = codeParthner.business_code
      branchCode = codeParthner.branchoffice_code
    }

    try {
      //Variables to set the total orders
      let subtotal = 0;
      let tax = 0;
      //We call the orders of the branchoffice 
      const response = await OrdersFetchAPI.getTransactionsByBranchOfficePaymentMethodbyUser(bussinesCode, branchCode, actualDate + 'T00:00:00.000+00:00', maxDate, 0, user.id, token);
      console.log(response);
      //We map the orders to get the orders that not have an inform z
      let orders = response.data.orders.filter(({ inform_z }) => inform_z === false);

      orders.map((order) => {
        subtotal = (subtotal + parseFloat(order.subtotal))
        tax = (tax + parseFloat(order.tax))
      });

      //We set the total orders
      setSubtotalOrders(subtotal)
      setTaxOrders(tax)
      setTotalOrders((subtotal + tax))
      //We set the orders state
      setBranchOrdersData(orders);
      setTemporal(response.data.orders);
      setBranchAnaliticsData([response.data.analitics]);
      //We show the success alert 
      SuccessAlert()
    } catch (err) {
      //We show the error alert 
      ErrorAlert('No tiene información')
      console.log(err);
    }
  }

  //Function to make the settle of the orders
  const makeSettle = async () => {
    //We check the machine info on the general data and verify if the machine have some payment method with evertec
    if (generalData !== null) {
      let generalDataParse = JSON.parse(generalData);

      //We filter the payment methods with evertec
      let evertec = generalDataParse[0].data.paymentMethods.filter(({ integration_id }) => integration_id === 6);
      console.log(evertec);
      //We check if the evertec array have data
      if (evertec.length > 0) {
        //We call the api to make the settle
        try {
          //We call the payment operation to make the settle
          let response = await PaymentOperations({
            operationType: 'settle', integrationType: 'Evertech', params: evertec[0].params,
            sECRStationID: generalDataParse[0].data.machine.code,
            sCashierID: user.id, sRefNumber: 0
          });

          //We check if the response is success
          if (response && response.data && response.data.approval_code === "00") {
            console.log("Cierre de evertech", response);
            //We update the orders inform z
            updateOrdersInformZ()
          } else {
            console.log("Error en el cierre de evertech", response);
            //We show the error alert
            ErrorAlert('', 'Error en el cierre de evertech', 'error')
          }
        } catch (err) {
          console.log(err);
          ErrorAlert('', 'Error en el cierre de evertech', 'error')
        }
      } else {
        //We update the orders inform z
        updateOrdersInformZ()
      }
    }
  }
  //Function to update the orders inform z
  const updateOrdersInformZ = async () => {
    let initialDate, finalDate;

    if (localStorage.getItem('lastDateInformZ') === null) {
      initialDate = moment(new Date()).format('YYYY-MM-DD');
      finalDate = new Date().toJSON();
      console.log("Entre 1 ")
    } else {
      initialDate = localStorage.getItem('lastDateInformZ');
      finalDate = new Date().toJSON();
      console.log("Entre 2 ")
    }

    let codeParthner = localStorage.getItem('codeParthner');
    let bussinesCode = business_code
    let branchCode = selectedBranch

    if (codeParthner !== null && codeParthner !== undefined && codeParthner !== '') {
      codeParthner = JSON.parse(localStorage.getItem('codeParthner'))
      bussinesCode = codeParthner.business_code
      branchCode = codeParthner.branchoffice_code
    }

    try {
      let data = {
        "informz": "true",
        "user_code": user.user_code,
        "business_code": bussinesCode,
        "minDate": initialDate,
        "maxDate": finalDate
      }
      //We call the api to update the orders inform z
      const response = await OrdersFetchAPI.updateOrderStatusinformZ(actualDate, finalDate, false, user.user_code, bussinesCode, branchCode, token, data);
      //We set the last date of the inform z
      let dateArray = finalDate.split("T");
      localStorage.setItem('lastDateInformZ', dateArray[0] + "T00:00:00.000Z");
      ErrorAlert('', 'Se realizo el cierre con exito', 'success')
      getTransactionsByBranchOffice(bussinesCode, branchCode, minDate, maxDate)
    } catch (err) {
      ErrorAlert('', 'Error en el cierre', 'error')
      console.log(err);
    }
  }



  //Function to search any result on the search filter 
  function handleChangeFiltro(event) {
    let resultFilter = [];
    if (event.target.value == "") {
      console.log(event.target.value);
      setBranchOrdersData(temporal);
    } else {
      for (
        let recorrer = 0;
        recorrer < branchOrdersData.length;
        recorrer++
      ) {
        const examinar = branchOrdersData[recorrer];

        const info = JSON.stringify(examinar);
        const resultado = info
          .toLowerCase()
          .includes(event.target.value.toLowerCase());

        if (resultado == true) {
          resultFilter.push(examinar);

          setBranchOrdersData(resultFilter);
          console.log(resultFilter);
        }
      }
    }
  }

  const reportDetail = (general_sales) => {
    return <ReportTable table_name="Tabla de ventas detalladas" table_headers={["Fecha", "Hora", "Orden", "Total", "Itbms", "Descuento"]} table_type="general_report" table_data={general_sales} />

  }



  //Function to populate the users branch 
  const branchList = () => {
    const listBracnh = userBranchoffices.map((branch) => (
      <>
        <option value={branch.code}>{branch.branchoffice_name}</option>
      </>
    ));
    return listBracnh
  }

  //Hook to charge data when the page is loaded 
  useEffect(() => {
    (async () => {
      try {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);
        setUserBusiness(user.business);
        //We search the branch orders 
        getTransactionsByBranchOffice(business_code, selectedBranch, actualDate, maxDate)
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);


  return (
    <>

      <header className="py-1 px-1  border-bottom  bg-white fixed-top">
        <nav className="border-bottom border-3 bg-white px-1 fixed-top ">
          <div className='d-flex  align-items-center justify-content-between'>
            <div>
              <a href="javascript:location.reload()"
                className="d-flex align-items-center 
                            flex-fill bd-highlight 
                            text-dark text-decoration-none">
                <img
                  className="navbar-logo-post"
                  src={logo_sp} alt="Logo Smart Pay" />
                <span className="h5 pt-2 me-3 fw-bold align-self-center text-uppercase">POS</span>
              </a>
            </div>
            <Navbar />
          </div>
        </nav>
      </header>


      <div className="container">
        <div className='smart-pay-main'>
          <div className='d-flex justify-content-center'>
            <h2 className='fs-2 fw-bold'>Analíticas </h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className='col-12 col-md-12 col-xl-8 col-lg-6 ' hidden>
            <div className='row justify-content-md-center'>
              <h4> Filtro </h4>
              <div className="col-12 col-md-12 col-xl-8 col-lg-12 ">
                <div className="border rounded-sp p-3 bg-white card-stats mb-xl-0">
                  {/* <div>
                    <h6>Sucursal</h6>
                   <div className="login-form needs-validation">
                      <div className="row">
                        <div className="col-12">
                          <div className="">
                      
                            <select onChange={e => setSelectedBranch({ code: e.target.value })} className='form-select' name="user_branch" id="user_branch">
                              <option value={0}>Mostrar todos</option>
                              {branchList()}
  </select>
                          </div>
                        </div>
                      </div>
                    </div> 
                  </div>*/}
                  <div className='mt-3'>
                    <h6>Fecha</h6>
                    <div className="login-form needs-validation">
                      <div className="row">
                        <div className="col-8">
                          <div className="">
                            <input
                              onChange={(e) => setMinDate(e.target.value)}
                              type="date" name="date" id="" className="control mt-3" />
                          </div>
                        </div>
                        <div className="col-4">
                          <button className="btn btn-success rounded mt-3" onClick={e => getTransactionsByBranchOffice(business_code, selectedBranch, minDate, `${minDate}T23:59:59.000+00:00`)} id="btn-analitycs">Aceptar</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-10 col-md-12 col-xl-4 col-lg-12 '>
            <h4 className='text-center'> Historial de transacciones  </h4>

            <div className="w-auto bg-light px-1 py-2 border rounded mt-4">
              <div className="border-0" >
                {/* <i className="uil uil-money-stack"></i> */}
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="mb-0 text-secondary fw-bold">Buscador</h6>
                  <div className='border-0 fw-bold fs-3'>
                    <input
                      spellcheck="false"
                      className="form-control"
                      type="text"
                      step="any"
                      onChange={handleChangeFiltro}
                      placeholder="Buscar orden "
                    />

                  </div>
                </div>
              </div>
            </div>

            <div className='transaction-wrapper-3 border border-historyoverflow-hidden '>
              <div className="list-group overflow-hidden">
                {listOrders()}
              </div>
              {/* 3 */}
              <div className='d-flex justify-content-between mt-2'>
                <h5><strong>Total de ventas:</strong></h5>
                <h5><strong>${parseFloat(totalOrders).toFixed(2)}</strong></h5>
              </div>

              <button className='btn btn-danger m-2' onClick={() => makeSettle()}>
                Cierre
              </button>

            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal className="modal-lg" show={modalShow} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Detalles</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {reportDetail(ordersPaymentMethod)}

        </Modal.Body>
      </Modal>


      {/* Modal detail order */}
      <Modal show={modalShowDetail} onHide={handleCloseModalDetail}>
        <Modal.Header closeButton>
          <Modal.Title>Detalles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {detailOrderList()}
        </Modal.Body>
      </Modal>

    </>
  )
}

export default AnaliticsPayment