import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'

import { PreOrderFetchAPI } from '../../api/Paymentpost/PreOrder';
import { DeletedTempOrdersFetchAPI } from '../../api/DeletedTemporalOrders';

import Swal from "sweetalert2";
import imgPreOrder from './img/preOrder.png'
import ErrorAlert from '../Alerts/ErrorAlert';
import LoadingAlert from '../Alerts/LoadingAlert';
import { GiPlayerTime } from 'react-icons/gi';
import Empty from '../utils/Empty';
import refund from '../img/icons/refund.png'
import ModalDivisionequalparts from './ModalDivisionequalparts';
import ModalDivisionByProduct from './ModalDivisionByProduct';
import ModalDetailPreOrder from './ModalDetailPreOrder';
import ModalDivisionByProductPart from './ModalDivisionByProductPart';


function ModalDivisionPreOrder({ order, getAll, codeParthner }) {
    const [showDivision, setShowDivision] = useState(false);

    return (
        <>
            <button className='btn btn-primary bg-info-subtle text-info-emphasis border-0 btn-sm btn-block m-1'
                onClick={() => (setShowDivision(true))}>

                <i class="fa-solid fa-divide"></i> Dividir
            </button>

            <Modal
                show={showDivision}
                size="xl"
                onHide={() => setShowDivision(false)}
                aria-labelledby="example-custom-modal-styling-title"
            >

                <Modal.Body scrollable>

                    <div>
                        <div className='mt-2 text-center p-1'>
                            <h4 className='fw-bold'>  <i class="uil uil-arrows-resize"></i> Dividir precuenta </h4>
                        </div>

                        <div >
                            <ModalDetailPreOrder orderData={order}></ModalDetailPreOrder>
                        </div>
                    </div>

                    <div className='mt-2 text-center p-1 mt-5'>
                        <h4 className='fw-bold'><i class="uil uil-ellipsis-v"></i> Selecciona una accion </h4>
                    </div>

                    <div className='d-flex justify-content-center alight-item-center'>
                        <div className='d-flex justify-content-between alight-item-center'>
                            <ModalDivisionequalparts order={order} setShowDivision={setShowDivision} getAll={getAll} codeParthner={codeParthner} ></ModalDivisionequalparts>
                            <ModalDivisionByProductPart order={order} setShowDivision={setShowDivision} getAll={getAll} codeParthner={codeParthner}></ModalDivisionByProductPart>
                        </div>
                    </div>

                    <div className='d-flex justify-content-between mt-3'>
                        <button className='btn btn-danger btn-lg' onClick={() => setShowDivision(false)}> Cancelar </button>
                    </div>


                </Modal.Body>
            </Modal>



        </>
    )
}

export default ModalDivisionPreOrder