import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import { DonleeFechtAPI } from '../../api/Donlee';
import ErrorAlert from '../Alerts/ErrorAlert';
import { RenderIf } from '../utils/RenderIf';

import Accordion from 'react-bootstrap/Accordion';

function ModuleTips({ invoiceTips, setInvoiceTips, recomendationTips, setEventHandler }) {
    const [showModal, setShowModal] = useState(false)
    let token = localStorage.getItem('x-access-machine-token');

    const handlerInputText = (value) => {
        let input = document.getElementById('inputTips')
        input.value = value
    }

    const handleChange = (e) =>{

        if(e.target.value == ""){
            setInvoiceTips(0.00)
        }else{
            setInvoiceTips(parseFloat(e.target.value))
        }

        setEventHandler(1)
        
    }



    return (
        <>

            <div
                show={showModal}
                size="md"
                onHide={() => setShowModal(true)}
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >

                <Modal.Body>


                    <div className='row'>
                        <div className="col-12 col-lg-6">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Propina: ${invoiceTips} </Accordion.Header>
                                    <Accordion.Body>
                                        <div className='row'>
                                            <div className=' col-sm-8'>
                                                <input id="inputTips" type="number" step='0.01' className="form-control border" placeholder='Ingrese un monto' onChange={(e) => handleChange(e)} />
                                            </div>

                                        </div>



                                        <div className='m-2'>
                                            <span className='text-primary fw-bold'>Propina recomendada:</span>
                                            <div className='d-flex row'>

                                                <div className=" col-md-6 d-flex  p-2 text-center" onClick={() => (handlerInputText(0.00), setInvoiceTips(0.00), setEventHandler(1))}>
                                                    <span className="text-muted border border-2 rounded-3 p-1  w-100 bg-light"> Propina 0%:<br></br> $
                                                        <span translate='no' className='fw-bold'>0.00</span>
                                                    </span>
                                                </div>
                                                {recomendationTips.map((tip) => (<>
                                                    <div className=" col-md-6 d-flex  p-2 text-center" onClick={() => (handlerInputText(tip.value), setInvoiceTips(tip.value), setEventHandler(1))}>
                                                        <span className="text-muted border border-2 rounded-3 p-1  w-100 bg-light"> Propina {tip.rate}:<br></br> $
                                                            <span translate='no' className='fw-bold'>{tip.value}</span>
                                                        </span>
                                                    </div>
                                                </>))}
                                            </div>

                                        </div>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>

                    </div>
                </Modal.Body>
            </div>
        </>
    )
}

export default ModuleTips