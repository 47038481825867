//React Components 
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
//Images
import dollarOne from "./img/dollas-one.jpeg"
import dollarFive from "./img/dollas-five.jpeg"
import dollarTen from "./img/dollas-ten.jpeg"
import dollarTwenty from "./img/dollas-twenty.jpeg"
import dollarFifty from "./img/dollas-fifty.jpeg"
import coin1 from "./img/coin1.png"
import coin5 from "./img/coin5.png"
import coin10 from "./img/coin10.png"
import coin25 from "./img/coin25.png"
import coin1b from "./img/coin1b.png"
import Modal from 'react-bootstrap/Modal';
import { RenderIf } from '../utils/RenderIf';
//Sweet alert
import Swal from "sweetalert2";

//api
import { MachinesFetchAPI } from '../../api/Machines';

const Cashier = ({ setAmountOrder, setOrderNumber, setModalShow, order_data }) => {
   let { business_code, branchoffice_code } = useParams();
   const [counter, setCounter] = useState(0)
   const [loader, setLoader] = useState(0)
   const user = JSON.parse(localStorage.getItem('user'))

   setAmountOrder(order_data.total.toFixed(2))
   setOrderNumber(order_data.code)

   const checkAmount = () => {
      let total = (counter.toFixed(2) - (order_data.total.toFixed(2))).toFixed(2);
      if (total >= 0) {
         setLoader(1)
      } else {
         Swal.fire({
            icon: 'warning',
            title: 'Debe ingresar el monto a pagar o superior.',
            showConfirmButton: false,
            timer: 1500
         })
      }
   }

   console.log(counter);

   //Function to populate the card dollar
   const CardDollar = (props) => {
      return (
         <div className="col-4  mt-1">
            <div className="button-dollar card rounded shadow " onClick={() => setCounter((counter) => counter + props.value)}>
               <img src={props.imgDollar} alt="" className='dollar-img' />
            </div>
            <div className='text-center'>
               <small className='text-secondary fw-bold'>${props.value}</small>
            </div>
         </div>
      )
   }

   //Function to populate the card coin 
   const CardCoin = (props) => {
      return (
         <>
            <div className="col-4 mb-2 mt-2 ">
               <div className='d-flex justify-content-center'>
                  <div className="button-coin" onClick={() => setCounter((counter) => counter + props.value)}>
                     <div className='coin'>
                        <img src={props.imgDollar} alt="" className='dollar-img' />

                     </div>
                  </div>

               </div>

               <div className='text-center'>
                  <small className='text-secondary fw-bold'>${props.value}</small>
               </div>
            </div>
         </>
      )
   }

   //Consultar data inicial 
   const putOrderMachine = async (status, order) => {
      let boton = ""
      let text = ""
      let title = ""
      let respuestaAlert = ""
      let valorCambio = (counter.toFixed(2) - (order_data.total.toFixed(2))).toFixed(2)

      if (valorCambio >= 0 || status == 8) {
         if (status == 7) {
            title = "Aprobar Orden: " + order
            text = "Monto de la transaccion: " + order_data.total.toFixed(2) + ", Cambio: " + valorCambio
            boton = "Aprobar pago"
            respuestaAlert = "Pago exitoso"
         } else if (status == 8) {
            title = "Esta seguro que quiere cancelar la orden"
            text = "Orden: " + order
            boton = "Cancelar pago"
            respuestaAlert = "Pago cancelado con exito"
         }

         Swal.fire({
            title: title,
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: boton
         }).then((result) => {
            if (result.isConfirmed) {
               peticion()

            }

         })
      } else {
         Swal.fire({
            icon: 'warning',
            title: 'Debe ingresar el monto a pagar o superior.',
            showConfirmButton: false,
            timer: 1500
         })

      }

      const peticion = async () => {
         let data = {
            "order_code": order,
            "business_code": business_code,
            "branchoffice_code": branchoffice_code,
            "status": status, 
            "user_id": user && user.id !== undefined ? user.id  : undefined
         }

         try {
            const response = await MachinesFetchAPI.updateStateOrder(data)
            console.log(response);

         } catch (err) {

            if (err.request.response == "{\"error\":\"Orden aun no ha sido eliminada, estado aun no es realizado o cancelado\",\"success\":true}") {
               console.log("Exitoso");

               setModalShow(false)
               Swal.fire({
                  icon: 'success',
                  title: respuestaAlert,
                  showConfirmButton: false,
                  timer: 1500
               })
            }
            console.log(err)
         }
      }
   }

   const renderCahs = () => {
      return (
         <>
            <div className='smart-pay-main  '>
               <div className='container '>
                  <div className="row d-flex justify-content-center">
                     <div className=" row col-12 mb-2  ">
                        <div className=' col bg-success rounded m-2 row'>
                           <div className="col d-flex justify-content-center p-1 ">
                              <h4 className="text-light text-center">Monto recibido:</h4>
                           </div>
                           <div className="col d-flex justify-content-center p-1">
                              <h4 className="text-light text-center">{counter.toFixed(2)}$</h4>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>

            <div className="container border rounded p-2">
               <div className=' calculator-dollars'>
                  <h4>Billetes jijij </h4>
                  <div className="row  justify-content-md-center ">
                     <CardDollar value={1} imgDollar={dollarOne} />
                     <CardDollar value={5} imgDollar={dollarFive} />
                     <CardDollar value={10} imgDollar={dollarTen} />
                     <CardDollar value={20} imgDollar={dollarTwenty} />
                     <CardDollar value={50} imgDollar={dollarFifty} />
                  </div>
               </div>

               <div className=' calculator-coins'>
                  <h4>Monedas</h4>
                  <div className="row justify-content-md-center ">
                     <CardCoin value={0.01} imgDollar={coin1} />
                     <CardCoin value={0.05} imgDollar={coin5} />
                     <CardCoin value={0.10} imgDollar={coin10} />
                     <CardCoin value={0.25} imgDollar={coin25} />
                     <CardCoin value={1.00} imgDollar={coin1b} />
                  </div>
               </div>


               <div className='my-sm-2'>
                  <div className='operator-calculator d-flex justify-content-between '>
                     <div className='m-1'>
                        <button className='btn btn-secondary' onClick={() => setCounter(0)}> Borrar cuenta</button>
                     </div>
                     <div className='m-1'>
                        <button className='btn btn-danger' onClick={() => putOrderMachine(8, order_data.code)}> Cancelar pago </button>
                     </div>
                     <div className='m-1'>
                        <button className='btn btn-success' onClick={() => checkAmount() /*putOrderMachine(7, order_data.code)*/}> Continuar con pago </button>
                     </div>
                  </div>
               </div>
            </div>
         </>
      )
   }



   const nextModal = () => {
      return (
         <>
            <div className="smart-pay-main mt-3">
               <h1 className='calulator-title text-center h4 my-3'>
                  Detalle de la orden
               </h1>
               <div className="container">
                  <div className="row d-flex justify-content-md-center ">

                     <div className="col-12 ">
                        <div className="m-2 p-1 row rounded bg-light">
                           <div className='col d-flex justify-content-center '><h3 className='text-center'>Monto a pagar:</h3></div>
                           <div className='col d-flex justify-content-center '><h3>${order_data.total}</h3></div></div>
                     </div>
                  </div>
               </div>
            </div>

            <div className='smart-pay-main calulator mt-3 '>
               <h1 className='calulator-title text-center h4 my-3'>
                  Detalle del cambio
               </h1>
               <div className='container '>
                  <div className="row d-flex justify-content-center">
                     <div className=" row col-12 mb-4  ">
                        <div className=' col bg-success rounded m-2'>
                           <div className="d-flex justify-content-center">
                              <h3 className="text-light mt-3 text-center">Monto recibido</h3>
                           </div>
                           <div className="text-center p-2">
                              <span className="h3 font-weight-bold text-white" id="total_counter">
                                 {counter.toFixed(2)}
                                 <span>$</span>
                              </span>
                           </div>
                        </div>
                        <div className='col bg-warning rounded m-2'>
                           <div className="d-flex justify-content-center">
                              <h3 className=" mt-3 text-center">Monto a devolver</h3>
                           </div>
                           <div className="text-center p-2">
                              <span className="h3 font-weight-bold " id="total_counter">
                                 {(counter.toFixed(2) - (order_data.total.toFixed(2))).toFixed(2)}
                                 <span>$</span>
                              </span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="container border rounded p-4">

               <div className='my-sm-2'>
                  <div className='operator-calculator d-flex justify-content-between '>
                     <div className='m-1'>
                        <button className='btn btn-danger' onClick={() => putOrderMachine(8, order_data.code)}> Cancelar pago </button>
                     </div>
                     <div className='m-1'>
                        <button className='btn btn-success' onClick={() => putOrderMachine(7, order_data.code)}> Finalizar pago</button>
                     </div>
                  </div>
               </div>
            </div>
         </>
      )
   }



   return (
      <>

         <RenderIf isTrue={loader == 0}>
            {renderCahs()}
         </RenderIf>
         <RenderIf isTrue={loader == 1}>
            {nextModal()}
         </RenderIf>





      </>


   )
}

export default Cashier