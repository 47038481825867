import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ErrorAlert from '../Alerts/ErrorAlert';
import LoadingAlert from '../Alerts/LoadingAlert';
import ConfirmationAlert from '../Alerts/ConfirmationAlert';
import { PrinterFetchAPI } from '../../api/Printer';
import { MachinesFetchAPI } from '../../api/Machines';
import { AuthDgiFetchAPI } from '../../api/Paymentpost/AuthDgi';
import { PreOrderFetchAPI } from '../../api/Paymentpost/PreOrder';
import { CategriesPosFetchAPI } from '../../api/Paymentpost/CategoriesPos';
import { RequestLocalFetchAPI } from '../../api/Paymentpost/RequestLocal';


function ModalCreatePreOrder({ checkDeliveryOrder, dv, name, ruc, setDv, setName, setRuc, dataResponse, optionalData, setDataResponse, setOptionalData, shoppingCart, shoppingCartCombos, total, subtotal, tax, discount, setPageLoader, dataPreOrder, taxesArrayAll, cardGroup, dataDicountApply, paramsMicros, parthner_order }) {
   const [show, setShow] = useState(false);
   const [infoPrinter, setInfoPrinter] = useState({ printer_comanda: "", printer_ticket: "" })
   const [printers, setPrinters] = useState([]);
   const [dataOrder, setDataOrder] = useState({});
   const [tableNumber, setTableNumber] = useState(0);

   let title_modal = "Crear precuenta"
   let machine = JSON.parse(localStorage.getItem('machine'));
   let user = JSON.parse(localStorage.getItem('user'));
   let date = new Date().toJSON().slice(0, 10)

   const getPreOrderByid = async () => {
      let machine_token = localStorage.getItem('x-access-machine-token');
      if (dataPreOrder.pre_Order == true) {
         try {
            const response = await PreOrderFetchAPI.getPreOrderById(dataPreOrder._id, machine_token);
            setDataOrder(response.data.pre_order)
            setTableNumber(response.data.pre_order.table_name);
         } catch (err) {
            console.log(err);
         }
      }
   }


   //function to authentication of ruc
   const authenticationDgi = async () => {
      let message = ""
      let title = ""
      let icon = ""
      if (ruc == "") {
         message = "No se ha ingresado ningun ruc."
         title = "Debes ingresar un ruc."
         icon = "warning"
         ErrorAlert(message, title, icon)
      } else {
         LoadingAlert()
         let token = localStorage.getItem('x-access-machine-token');
         let data = { ruc: ruc }
         try {
            const response = await AuthDgiFetchAPI.authenticationRuc(token, data)
            let dataDgi = response.data
            if (dataDgi.dgi_response.gResProc) {
               if (dataDgi.dgi_response.gResProc.dCodRes == "0680") {
                  let dvDgi = dataDgi.dgi_response.dDV
                  if (dvDgi.length < 2) { dvDgi = "0" + dvDgi }
                  setName(dataDgi.dgi_response.dNomb)
                  setDv(dvDgi)
                  message = "Datos del clientes obtenidos por la DGI"
                  title = "Datos obtenido correctamente"
                  icon = "success"
                  ErrorAlert(message, title, icon)
                  setDataResponse(true)
               } else {
                  setOptionalData(true)
                  message = "Ingrese los datos manualmente."
                  title = "Datos no encontrados"
                  icon = "warning"
                  ErrorAlert(message, title, icon)
               }
            }
         } catch (err) {
            console.log(err);
            setOptionalData(true)
            message = "Ingrese los datos manualmente."
            title = "Datos no encontrados"
            icon = "warning"
            ErrorAlert(message, title, icon)
         }
      }
   }

   const handlercreateValidation = () => {

      if (paramsMicros != 0) {
         if (paramsMicros.pos_integration_id == 2) {
            formatDataMicrosCheck(shoppingCart, shoppingCartCombos, "create")
         } else {
            createPreOrder()
         }
      } else {
         createPreOrder()
      }
   }


   const handlerUpdateValidation = () => {
      if (paramsMicros != 0) {
         if (paramsMicros.pos_integration_id == 2) {
            handlerComparativeNewData()
         } else {
            updatePreOrder()
         }
      } else {
         updatePreOrder()
      }
   }

   //create preOrder
   const createPreOrder = async (micros) => {
      if ((shoppingCart.length + shoppingCartCombos.length) > 0) {
         LoadingAlert()
         let token = localStorage.getItem('x-access-machine-token');
         let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
         let machine = JSON.parse(localStorage.getItem('machine'))

         console.log(shoppingCart);
         let json = {
            "client_name": name,
            "localizador": "00",
            "table_name": tableNumber,
            "client_phone": "0",
            "ruc": ruc,
            "dv": dv,
            "id_Client": 0,
            "dgi_qr": "0",
            "Orden": shoppingCart,
            "combos": shoppingCartCombos,
            "Descuento": discount,
            "Total": total,
            "itbms": tax,
            "subtotal": subtotal,
            "machine_id": machine.id,
            "branchoffice_id": branchoffice.id,
            "tipoOrden": checkDeliveryOrder,
            "paymethod": 0,
            "discounts": dataDicountApply,
            "is_discount_notification": false,
            "is_cupon": false,
            "is_devolution_notification": false,
            "taxes": taxesArrayAll,
            "is_pre_order": true, "user": {
               id: user.id,
               name: user.name,
               code: user.user_code
            }
         }

         if (micros != undefined) { json.micros_reference = micros.micros_reference }


         try {
            const response = await PreOrderFetchAPI.createPreOrder(json, token)

            printOrder(response.data.newTempOrder._id, response.data.newTempOrder.code)
            setShow(false)
            let message = "Precuenta creada con exito."
            let title = "Operacion exitosa."
            let icon = "success"
            ErrorAlert(message, title, icon)
            setPageLoader(1)


         } catch (err) {
            console.log(err);
         }
      } else {
         ErrorAlert("Debes agregar  algo al carrito", "No hay productos en el carrito", "info")
      }
   }



   //format data to send to micros
   const formatDataMicrosCheck = (groups, combos, action) => {
      //Json general format
      let jsonMicrosCheck = {
         RvcRef: paramsMicros.pos_integration_params.RvcRef,
         employeeNum: paramsMicros.pos_integration_params.employeeNum,
         host_url: paramsMicros.pos_integration_params.host_url,
         localMicrosServerIp: paramsMicros.pos_integration_params.localMicrosServerIp,
         products: [],
         automatic_payment: 0,
         delivery: false,
         client: { name: name, phone: "00" },
         paymentmethods: [{
            "name": "Credito",
            "paymenthod_id": 10,
            "total": total
         }],
      }

      if (action == "update") {
         let preOrderOld = JSON.parse(localStorage.getItem('preOrderComparativeOld'))
         jsonMicrosCheck.checkSeq = preOrderOld.micros_reference.checkSeq
         jsonMicrosCheck.checkNum = preOrderOld.micros_reference.checkNum
      }

      let data = []
      //json product format
      groups.forEach(product => {
         let dataDetail = {
            "code": product.group_id,
            "name": product.name,
            "price": product.price,
            "cant": product.amount,
            "tax": product.taxes[0].tax,
            "total_discount": product.discount,
            "exist_product": false,
            "total_product": product.price,
            "external_reference": product.external_reference,
            "type": "product",

         }

         let dataSegments = []
         console.log("DATA SEGMENTS");
         console.log(product);
         product.segments.forEach(segment => {
            let detail = {
               "external_reference": segment.external_reference,
               "name": segment.name,
               "amount": segment.amount,
            }
            dataSegments.push(detail)
         })
         dataDetail.condiments = dataSegments
         data.push(dataDetail)
      });

      //----------------------------------------------
      //format combos
      combos.forEach(combo => {

         console.log(combo);
         let dataDetailCombo = {
            "type": "combo",
            "code": combo.combo_id,
            "tax": combo.taxes[0].tax,
            "total_discount": combo.discount,
            "exist_product": false,
            "total_product": combo.price,
            "external_reference": combo.referenced_group.external_reference,
            "price": combo.price,
            "name": combo.name,
            "cant": combo.amount,
            "mainDetail": {},
            "sideDetails": [],
         }
         let setGroupsData = []
         combo.setGroups.forEach(setGroup => {

            setGroup.groups.forEach(group => {
               //json group format
               let dataDetail = {
                  "name": group.name,
                  "external_reference": group.external_reference,
                  "isMainProduct": false,
               }

               let dataSegments = []
               group.segments.forEach(segment => {
                  let detail = {
                     "external_reference": segment.external_reference,
                     "name": segment.name,
                     "amount": segment.amount,
                  }
                  dataSegments.push(detail)
               })
               //add data segments to group
               dataDetail.condiments = dataSegments

               if (setGroup.isMainSetGroup == 1) {
                  //add data to main detail
                  dataDetail.isMainProduct = true
                  dataDetailCombo.mainDetail = dataDetail
               } else {
                  //add data to side details
                  dataDetail.price = group.price
                  dataDetail.cant = group.amount
                  dataDetail.total = group.price
                  setGroupsData.push(dataDetail)
               }
            })
            //add data side details to combo
            dataDetailCombo.sideDetails = setGroupsData
         })
         //add combo to products
         data.push(dataDetailCombo)
      })
      jsonMicrosCheck.products = data
      createCheckMicrosOrderLocal(jsonMicrosCheck, action)
   }

   //request local to create open check in micros
   const createCheckMicrosOrderLocal = async (jsonMicrosCheck, action) => {
      let token = localStorage.getItem('x-access-machine-token');
      let type = "POST"
      if (action == "update") {
         type = "PUT"
      }
      let data = {
         Ruta: `http://${jsonMicrosCheck.localMicrosServerIp}:9472/api/micros/checks?RvcRef=${jsonMicrosCheck.RvcRef}&employeeNum=${jsonMicrosCheck.employeeNum}&host_url=${jsonMicrosCheck.host_url}`,
         Type: type,
         Header: { "x-access-token": token },
         Body: jsonMicrosCheck
      }


      console.log("DATA MICROSCHECK");
      console.log(data);
      let micros_response;
      try {
         micros_response = await RequestLocalFetchAPI.postMicros(data);
         console.log(micros_response);

         //We validate if the micros response is success
         if (action == "create") {
            if (micros_response.data.micros_response.PostTransactionExResponse.pTotalsResponse.OperationalResult.ErrorCode._text !== undefined && micros_response.data.micros_response.PostTransactionExResponse.pTotalsResponse.OperationalResult.ErrorCode._text === "Success") {

               //We add the data that we want to update 
               let data_to_update = {
                  micros_reference: {
                     checkNum: micros_response.data.micros_response.PostTransactionExResponse.pGuestCheck.CheckNum._text,
                     checkSeq: micros_response.data.micros_response.PostTransactionExResponse.pGuestCheck.CheckSeq._text,
                  }
               }
               //We make the update of the order 
               createPreOrder(data_to_update)
            }
         } else if (action == "update") {
            updatePreOrder()
         }
      } catch (err) {
         console.log(err);
      }
   }

   //function action print order
   let arrayPrintersTemp = []

   const getPrinter = async () => {
      try {
         const response = await PrinterFetchAPI.getPrinter(localStorage.getItem('x-access-machine-token'), machine.id);

         let arrayPrinters = response.data.printers;
         let printer_Comanda = "";
         let printer_ticket = "";
         let printer_zebra = "";
         for (let i = 0; i < arrayPrinters.length; i++) {
            getCategoriesByprinter(arrayPrinters[i])
            if (arrayPrinters[i].printer_type == 'Tickets') {
               printer_ticket = arrayPrinters[i].printer_name
            } else if (arrayPrinters[i].printer_type == 'Comanda') {
               printer_Comanda = arrayPrinters[i].printer_name
            } else if (arrayPrinters[i].printer_type == 'Zebra') {
               printer_zebra = arrayPrinters[i].printer_name;
            }
         }

         setPrinters(arrayPrintersTemp)
         setInfoPrinter({ printer_comanda: printer_Comanda, printer_ticket: printer_ticket, printer_zebra: printer_zebra });

      } catch (err) {
         console.log(err);
      }
   }



   const getCategoriesByprinter = async (printer) => {

      try {
         const response = await CategriesPosFetchAPI.getCategoriesByPrinters(printer.printer_id, localStorage.getItem('x-access-machine-token'))
         printer.categories = response.data.categories

      } catch (err) {
         printer.categories = []
         console.log(err);
      }
      arrayPrintersTemp.push(printer)

   }

   //print order
   const printOrder = async (id_Orden_Imprimir, transaction_code) => {
      let JsonImprimir = [{
         "type_ticket": "precuenta",
         "Printers": printers,
         "Nombre_maquina_impresora": infoPrinter.printer_ticket,
         "Nombre_cocina_impresora": infoPrinter.printer_comanda,
         "Nombre_zebra_impresora": infoPrinter.printer_zebra,
         "Id_Orden_Imprimir": id_Orden_Imprimir,
         "token": localStorage.getItem('x-access-machine-token'),
         "transaction_code": transaction_code,
         "amount_item": shoppingCart.length,
         "imprimir_comanda": "si",
      }]

      try {
         const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, localStorage.getItem('x-access-machine-token'));
         const response = await PrinterFetchAPI.printPreOrder(JsonImprimir, response_machine.data.data.windows_ip)
         //console.log(response);
      } catch (err) {
         console.log(err);
      }
   }

   // view get order to return
   const cardGetOrder = () => {
      const cardOrder = shoppingCart.map((product) => (
         <>
            <div >
               <div className="d-flex rounded m-1 bg-white mt-1 card-pos">
                  <div className="flex-shrink-0">
                     <img className='m-1 rounded  bg-light border ' src={product.img} width="42" height="42" />
                  </div>
                  <div className="flex-grow-1 m-1">
                     <div className="d-flex bd-highlight">
                        <div className="bd-highlight title-product-cart mt-1 text-dark fw-bold">
                           {product.name}
                        </div>
                        <div className="ms-auto px-2 bd-highlight text-price-cart" translate='no'>
                           $ {product.price.toFixed(2)}
                        </div>
                     </div>

                     <div className={`d-flex bd-highlight text-muted opacity-75 position-relative d-flex justify-content-between align-items-center`}>
                        <details open >
                           <summary className=''>
                              <span className='badge bg-light text-dark summary-text-pos  fw-bold border rounded-4  text-wrap '>
                                 <i className="uil uil-info-circle  px-1 "></i>  Detalles
                              </span>
                           </summary>
                           {product.segments.map(item => (
                              <span className="badge bg-light text-dark  summary-text-pos m-2 border rounded-4 my-1 text-wrap ">
                                 {/* <span className='kiosk-subtitle-s' >   </span> */}
                                 <span className='text-muted m-0 p-0'>{item.segment_name}:</span>
                                 <br />
                                 <strong>
                                    <span className='me-2'>{item.name} </span>
                                    {parseFloat(item.price).toFixed(2)}
                                 </strong>
                              </span>
                           ))}
                        </details>


                     </div>



                  </div>


               </div>
            </div>
         </>
      ));
      return (cardOrder)
   }

   // view get order to return
   const cardGetOrderCombo = () => {
      const cardOrder = shoppingCartCombos.map((product) => (
         <>

            <div >
               <div className="d-flex rounded m-1 bg-white mt-1 card-pos">
                  <div className="flex-shrink-0">
                     <img className='m-1 rounded  bg-light border ' src={product.img} width="42" height="42" />
                  </div>
                  <div className="flex-grow-1 m-1">
                     <div className="d-flex bd-highlight">
                        <div className="bd-highlight title-product-cart mt-1 text-dark fw-bold">
                           {product.name}
                        </div>
                        <div className="ms-auto px-2 bd-highlight text-price-cart" translate='no'>
                           $ {product.price.toFixed(2)}
                        </div>
                     </div>

                     <div className={``}>
                        {product.setGroups.map((setGroup) => (
                           <div className="d-flex justify-content-between" key={setGroup.set_id}>
                              <details close >
                                 <summary className=''>
                                    <span className='badge bg-light text-dark summary-text-pos  fw-bold border rounded-4  text-wrap m-1 '>
                                       <i className="uil uil-info-circle  px-1 "> {setGroup.name}</i>
                                    </span>
                                 </summary>
                                 {setGroup.groups.map((group) => (<>
                                    {cardGroup(group)}
                                 </>))}
                              </details>
                           </div>))}
                     </div>



                  </div>


               </div>
            </div>
         </>
      ));
      return (cardOrder)
   }


   // update pre order
   const updatePreOrder = async (micros) => {
      let token = localStorage.getItem('x-access-machine-token');
      console.log(shoppingCart);
      let json = {
         "client_name": name,
         "localizador": "00",
         "table_name": tableNumber,
         "ruc": ruc,
         "dv": dv,
         "is_discount_notification": false,
         "_id": dataPreOrder._id,
         "order_type": checkDeliveryOrder,
         "groups": shoppingCart,
         "combos": shoppingCartCombos,
         "discounts": dataDicountApply,
         "taxes": taxesArrayAll,
         "discount": discount,
         "total": total,
         "tax": tax,
         "subtotal": subtotal,
      }

      if (micros != undefined) { json.micros_reference = micros.micros_reference }

      try {
         const response = await PreOrderFetchAPI.updateOrderTempPreOrder(json, token)
         //console.log(response);
         if (micros != undefined) {

         } else {
            printOrder(response.data._id, response.data.code)
            setShow(false)
            let message = "Precuenta actualizada con exito."
            let title = "Operacion exitosa"
            let icon = "success"
            setPageLoader(1)
            ErrorAlert(message, title, icon)
         }


      } catch (err) {
         let message = ""
         let title = "Ocurrio un error"
         let icon = "error"
         ErrorAlert(message, title, icon)
      }
   }

   const handlerChangeDataOrder = () => {


      if (dataPreOrder.pre_Order == true) {
         getPreOrderByid()
      }
      ErrorAlert("", "Nombre guardado", "success")
   }


   //function to compare data micros and update
   const handlerComparativeNewData = () => {
      let preOrderComparativeOld = JSON.parse(localStorage.getItem('preOrderComparativeOld'))
      let OrderGroupsOld = preOrderComparativeOld.groups
      let OrderCombosOld = preOrderComparativeOld.combos
      let newGroups = []
      let newCombos = []

      //comparative groups
      shoppingCart.forEach(group => {
         let groupOld = OrderGroupsOld.find(item => item.group_id == group.group_id && item.segments_string == group.segments_string)

         if (groupOld != undefined) {
            if (groupOld.amount != group.amount) {
               let newGroup = groupOld
               newGroup.amount = (group.amount - groupOld.amount)
               newGroup.cantidadItem = (group.amount - groupOld.amount)
               newGroups.push(newGroup)
            }
         } else {
            newGroups.push(group)
         }
      });

      //Add string data to compare
      shoppingCartCombos.forEach(combo => {
         let stringdata = combo.combo_id + "";
         combo.setGroups.forEach(setGroup => {
            stringdata += setGroup.set_id;
            setGroup.groups.forEach(group => {
               stringdata += group.group_id;
               group.segments.forEach(segment => {
                  stringdata += segment.segment_id + segment.item.item_id;
               });
            });
         });
         combo.combo_string = stringdata;
      });

      //Add string data to compare
      OrderCombosOld.forEach(comboOld => {
         let stringdataOld = comboOld.combo_id + "";
         comboOld.setGroups.forEach(setGroup => {
            stringdataOld += setGroup.set_id;
            setGroup.groups.forEach(group => {
               stringdataOld += group.group_id;
               group.segments.forEach(segment => {
                  stringdataOld += segment.segment_id + segment.item.item_id;
               });
            });
         });
         comboOld.combo_string = stringdataOld;
      });

      //==========================================
      //Comparative combos
      shoppingCartCombos.forEach(combo => {
         let comboOld = OrderCombosOld.find(comboOld => comboOld.combo_id == combo.combo_id && comboOld.combo_string == combo.combo_string)

         if (comboOld != undefined) {
            if (comboOld.amount != combo.amount) {
               let newCombo = comboOld
               newCombo.amount = (combo.amount - comboOld.amount)
               newCombo.cantidadItem = (combo.amount - comboOld.amount)
               newCombos.push(newCombo)
            }
         } else {
            newCombos.push(combo)
         }
      });

      formatDataMicrosCheck(newGroups, newCombos, "update")
   }

   useEffect(() => {

   }, [dataOrder])

   return (
      <>
         <button className='btn btn-primary bg-primary-subtle text-primary border-0   rounded-pill'
            onClick={() => (setShow(true), getPrinter(), getPreOrderByid())}>
            <small className='fw-bold buttons-label ' >
               <span className='fw-bold  mx-1 fs-label-2'>{dataPreOrder.pre_Order && dataPreOrder.pre_Order == true ? <>Actualizar precuenta</> : <>Crear Precuenta</>}</span>
            </small>
         </button>

         <Modal
            show={show}
            size="xl"
            centered
            onHide={() => setShow(false)}
         >

            <Modal.Body className='p-2' >
               <section className='flex-container'>
                  <div className="d-flex justify-content-center border-bottom-0">
                     <h2 className="modal-title fw-bold fs-5">
                        <i className="uil uil-receipt"></i> {dataPreOrder.pre_Order && dataPreOrder.pre_Order == true ? <>Actualizar precuenta</> : <>Crear Precuenta</>}
                     </h2>
                  </div>
                  <div className=' d-block d-lg-flex justify-content-lg-around'>

                     <div >
                        <span className='p-0  text-primary fw-bold'>Datos de Cliente </span>
                        <div className='d-flex  justify-content-between position-relative'>
                           <div className=' border m-1 p-2 rounded-3  shadow-sm'>
                              <small className="  fw-bold">Nombre</small>
                              <small className="d-block text-center ">
                                 {dataOrder.client_name != name ? name : dataOrder.client_name}
                              </small>
                           </div>
                           <div className=' border p-2 m-1 rounded-3 bg-white shadow-sm' >
                              <small className=" text-center  text-dark  fw-bold">Mesa</small>
                              <small className="d-block text-center">{tableNumber} </small>
                           </div>
                           <div className='d-block d-lg-inline border  p-2 m-1 rounded-3 bg-white shadow-sm' >
                              <small className=" text-center  text-dark  fw-bold">Fecha</small>
                              <small className="d-block text-center">{date} </small>
                           </div>
                        </div>
                        <div className='d-flex justify-content-start mt-1'>
                           <details className=''>
                              <summary className='sumamary-text bg-primary-subtle primary-success
                         p-1 rounded-3'><i class="uil uil-edit"></i>
                                 Editar
                              </summary>

                              {/*<div>{dataResponse == false ?
      <Form>
         {optionalData == true ? <>
            <div className='d-flex align-items-center justify-content-center'>
               <p className='' > <b>Ruc:</b>  <span>{ruc}</span></p>
            </div>

            <div>
               <Form.Group className="mb-3 " controlId="formBasicText">
                  <Form.Label >Ingresa un nombre</Form.Label>
                  <Form.Control placeholder="Nombre" onChange={(e) => (setName(e.target.value))} />
               </Form.Group>
               <div className='col-xs-2'>
                  <Form.Group className="mb-3 " controlId="formBasicText">
                     <Form.Label >Ingresa un dv</Form.Label>
                     <Form.Control placeholder="00" onChange={(e) => (setDv(e.target.value))} />
                  </Form.Group></div>

               <div className='d-flex align-items-center justify-content-center'><Button className='m-3' variant="danger" type="button" onClick={() => (setOptionalData(false))}>
                  Cancelar
               </Button>
                  <Button className='m-3' variant="primary" type="button" onClick={() => (setDataResponse(true))} >
                     Cargar datos
                  </Button></div>
            </div></> :
            <><div className='d-flex align-items-center justify-content-center'>
               <Form.Group className="mb-3 " controlId="formBasicEmail">
                  <Form.Label >Ingresa un ruc</Form.Label>
                  <Form.Control placeholder="0-000-000-0" onChange={(e) => (setRuc(e.target.value))} />
               </Form.Group>
               <Button className='m-3' variant="primary" type="button" onClick={() => (authenticationDgi())}>
                  Validar
               </Button>
            </div></>}
      </Form> : <>
         <p className=' m-2 text-center' ><span><strong>{name}</strong></span> </p>
         <div className="order-total m-1">
            <p className='' ><b>Ruc:</b> <span>{ruc}</span> </p><p className='' ><b>Dv:</b> <span>{dv}</span></p>
         </div>
         <Button variant="primary" type="button" onClick={() => (setDataResponse(false))}>Cambiar datos </Button></>}
   </div>*/}

                              <div>
                                 <Form>
                                    <div className='row d-flex justify-content-center align-item-center'>
                                       <Form.Group className="mb-3 " controlId="formBasicText">
                                          <Form.Label >Ingresa un nombre</Form.Label>
                                          <Form.Control placeholder={name} onChange={(e) => (setName(e.target.value))} />
                                       </Form.Group>

                                       <Form.Group className="mb-3 " controlId="formBasicText">
                                          <Form.Label >Ingresa el numero de la mesa</Form.Label>
                                          <Form.Control placeholder={tableNumber} onChange={(e) => (setTableNumber(e.target.value))} />
                                       </Form.Group>
                                    </div>
                                 </Form>
                              </div>


                           </details>
                        </div>
                        <div className=''>
                           <span className='p-0  text-primary fw-bold'>Totales </span>
                           <div className="list-total-pre-order-modal row mx-1 justify-content-md-center
                           ">

                              <small className='col-4 col-md-5 col-lg-12  border rounded text-center shadow-sm ' >
                                 <strong>Descuento</strong>
                                 <br />
                                 <span translate='no'>$ <span>{discount.toFixed(2)}</span></span> </small>
                              <small className=' col-4 col-md-5 col-lg-12    border rounded text-center shadow-sm' >
                                 <strong>Impuestos</strong>
                                 <br />
                                 <span translate='no'>$ <span>{tax.toFixed(2)}</span></span> </small>
                              <small className=' col-4  col-md-5 col-lg-12  border rounded text-center shadow-sm'>
                                 <strong> Subtotal</strong>
                                 <br />
                                 <span translate='no'>$ <span>{subtotal.toFixed(2)}</span></span></small>
                              <div className='col-12 col-md-5 col-lg-12 shadow-sm    border rounded text-center' >
                                 <strong>Total</strong> <small className='text-muted'>(+Impuestos)</small>
                                 <div translate='no'>
                                    $ <span>{total.toFixed(2)}
                                    </span>
                                 </div>
                              </div>
                           </div>
                        </div>

                     </div>
                     <div className=''>
                        <div>
                           <span className='p-0  text-primary fw-bold'>Lista de Productos de Orden </span>
                           <div className='item2 list-pre-order-framer  border-inset bg-light-subtle border border-2 border-1 border-light-subtle rounded-3  align-self-center  '>
                              {cardGetOrder()}
                              {cardGetOrderCombo()}
                           </div>

                        </div>

                     </div>

                  </div>








               </section>

               <div className='d-block mt-lg-1 mt-0'>
                  <div className='d-flex justify-content-end mt-2'>
                     <button className='btn bg-danger-subtle text-danger  border-0 fw-bold '
                        onClick={() => (setShow(false))}> Cancelar </button>

                     {dataPreOrder.pre_Order && dataPreOrder.pre_Order == true ? <>
                        <button className='btn bg-info-subtle text-info fw-bold border-0  ms-2'
                           onClick={async () => (handlerUpdateValidation())}>  Actualizar precuenta </button>
                     </>

                        :
                        <>
                           <button className='btn bg-success-subtle text-success fw-bold border-0  ms-2'
                              onClick={() => (handlercreateValidation())} >  Crear precuenta  </button>
                        </>
                     }

                  </div>
               </div>





            </Modal.Body>
         </Modal>


      </>
   )
}

export default ModalCreatePreOrder