import { Button } from 'react-bootstrap';
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import LoadingAlert from '../Alerts/LoadingAlert';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import { WhatsappFetchAPI } from '../../api/Whatsapp';
import { DiscountPosFetchAPI } from '../../api/Paymentpost/Discount';
import { DiscountFetchAPI } from '../../api/Discounts';
import { TaxesFetchAPI } from '../../api/Taxes';
import { RenderIf } from '../utils/RenderIf';

//Img
import empty_img from './img/empty_cart.png';
//Icons 
import Empty from '../utils/Empty';
import { FaPercentage } from 'react-icons/fa';

function ModalDiscounts({ actvPlus, actvLite, productChecked, shoppingCart, shoppingCartCombos, total, subtotal, itbms, discount, setLoad, setShoppingCart, setShoppingCartCombos, setProductChecked, taxesArrayAll, setCombosChecked, combosChecked, setdataDiscountApply, dataDicountApply, discount_validation, codeParthner }) {

   const [show, setShow] = useState(false);
   let token = localStorage.getItem('x-access-machine-token')

   //Taxes
   const [businessDiscounts, setBusinessDiscounts] = useState([]);
   const [showModalTaxes, setShowModalTaxes] = useState(false)
   const [taxSelect, setTaxSelect] = useState({})
   const [stateRequest, setStateRequest] = useState(false)



   let instervalDicount = 0
   let index = 0;

   //filter checkbox in state true and save in state array
   const getChecked = (event, combo) => {
      let dataProduct = JSON.parse(event.target.value)
      dataProduct.index = event.target.dataset.index;
      if (event.target.checked == true) {
         if (dataProduct.combo_id) {
            setCombosChecked([...combosChecked, dataProduct])
         } else {
            setProductChecked([...productChecked, dataProduct])
         }

      } else if (event.target.checked == false) {
         if (dataProduct.combo_id) {
            for (let i = 0; i < combosChecked.length; i++) {
               if (combosChecked[i].index == event.target.dataset.index) {
                  combosChecked.splice(i, 1)
               }
            }
         } else {
            for (let i = 0; i < productChecked.length; i++) {
               if (productChecked[i].index == event.target.dataset.index) {
                  productChecked.splice(i, 1)
               }
            }
         }
      }
   }


   //create orders temporal of discount
   let type = 0
   const createTempDiscount = async () => {

      type = 0
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let machine = JSON.parse(localStorage.getItem('machine'))
      let user = JSON.parse(localStorage.getItem('user'));

      
      let jsonDiscountTemp = {
         "client_name": "0",
         "cupon_code": "0",
         "is_cupon": false,
         "localizador": "0",
         "client_phone": "0",
         "dgi_qr": "0",
         "Orden": productChecked,
         "combos": combosChecked,
         "amount_order_product": productChecked.length,
         "amount_order_combo": combosChecked.length,
         "Descuento": 0,
         "Total": total,
         "itbms": itbms,
         "taxes": taxesArrayAll,
         "subtotal": subtotal,
         "machine_id": machine.id,
         "branchoffice_id": branchoffice.id,
         "tipoOrden": "comer aqui",
         "paymethod": 0,
         "discounts": dataDicountApply,
         "is_discount_notification": true,
         "discounts": dataDicountApply,
         "user": {
            id: user.id,
            name: user.name,
            code: user.user_code
         }
      }


      try {
         const response = await WhatsappFetchAPI.sentDiscountNotification(jsonDiscountTemp, token)
         console.log(response)
      } catch (err) {
         console.log(err)
      }

      //create temporal discount
      try {
         const response = await DiscountPosFetchAPI.createTempDiscount(jsonDiscountTemp, token)

         if (response.data.message == 'Tempral order created') {
            getOrdersTempDiscount()
         }
      } catch (err) {
         setStateRequest(false);
         console.log(err);
         console.log("ya hay una temporal");
         type = 1
         getOrdersTempDiscount()

      }
   }

   // get order temporal discount
   let countGetDiscount = 0 // count to limit get orders
   const getOrdersTempDiscount = async () => {
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let machine = JSON.parse(localStorage.getItem('machine'))
      let business = JSON.parse(localStorage.getItem('business'))

      let business_code = business.code
      let branch_code = branchoffice.code 

      if (codeParthner != 0) {
          business_code = codeParthner.business_code
          branch_code = codeParthner.branchoffice_code
      }
   
      clearInterval(instervalDicount)

      try {
         const response = await DiscountPosFetchAPI.getTempDiscount(machine.code, business_code, branch_code, token)

        
         let code = response.data.order.code
         let message = ""
         let title = ""
         let icon = ""

         if (type == 0) {


            if (response.data.order.status_id == 1) {
               if (countGetDiscount <= 13) {
                  console.log(countGetDiscount);
                  instervalDicount = setInterval(getOrdersTempDiscount, 5000)
                  countGetDiscount++
               } else {
                  message = "Tiempo de espera agotado"
                  title = "Advertencia"
                  icon = "warning"
                  updateOrderDiscount(code, 5, message, title, icon) //state 5 delete order dicount
                  setProductChecked([])
                  setCombosChecked([])
                  setShow(false)
                  countGetDiscount = 0
               }
            } else if (response.data.order.status_id == 8) {//order temporal is cancel
               message = "Orden temporal cancelada"
               title = "Operacion denegada"
               icon = "error"
               updateOrderDiscount(code, 5, message, title, icon, type)
               setProductChecked([])
               setCombosChecked([])
               setShow(false)
            } else if (response.data.order.status_id == 9) {//order temporal is success
               message = "Descuento aplicado con exito."
               title = "Operacion exitosa"
               icon = "success"

               setdataDiscountApply(response.data.order.discounts)
               let tempOrderDiscount = response.data.order.groups
               let tempOrderComboDiscount = response.data.order.combos
               //format json 
               for (let i = 0; i < tempOrderDiscount.length; i++) {

                  for (let s = 0; s < tempOrderDiscount[i].segments.length; s++) {
                     tempOrderDiscount[i].segments[s].item_id = tempOrderDiscount[i].segments[s].item.item_id
                     tempOrderDiscount[i].segments[s].segment_name = tempOrderDiscount[i].segments[s].name
                     tempOrderDiscount[i].segments[s].name = tempOrderDiscount[i].segments[s].item.name
                     tempOrderDiscount[i].segments[s].cantidadItem = tempOrderDiscount[i].segments[s].item.amount
                     tempOrderDiscount[i].segments[s].description = tempOrderDiscount[i].segments[s].item.description
                     tempOrderDiscount[i].segments[s].img = tempOrderDiscount[i].segments[s].item.img

                  }
                  tempOrderDiscount[i].DetalleSegmentos = tempOrderDiscount[i].segments
                  tempOrderDiscount[i].cantidadItem = tempOrderDiscount[i].amount
                  tempOrderDiscount[i].descuento = tempOrderDiscount[i].discount

               }


               //filter product only with discount
               let arrayTempProduct = new Array
               for (let s = 0; s < shoppingCart.length; s++) {
                  for (let t = 0; t < tempOrderDiscount.length; t++) {
                     if (tempOrderDiscount[t].group_id == shoppingCart[s].group_id) {
                        console.log(" entre identificando id de grupo");
                        if (tempOrderDiscount[t].segments_string == shoppingCart[s].segments_string) {
                           console.log("Es igual");
                           if (shoppingCart[s].descuento > 0) {
                              arrayTempProduct.push(shoppingCart[s])
                           } else {
                              arrayTempProduct.push(tempOrderDiscount[t])
                           }
                        }
                     }
                  }
                  //------------------------
               };

               //filter product only with discount
               let arrayTempCombos = new Array
               for (let s = 0; s < shoppingCartCombos.length; s++) {
                  for (let t = 0; t < tempOrderComboDiscount.length; t++) {

                     let combo = tempOrderComboDiscount[t]
                     combo.setGroupString = ""

                     combo.setGroups.forEach((setGroup) => {
                        combo.setGroupString = combo.setGroupString + setGroup.name
                        setGroup.groups.forEach((group) => {
                           combo.setGroupString = combo.setGroupString + group.segments_string
                        })
                     })

                     console.log(combo.setGroupString);
                     if (tempOrderComboDiscount[t].combo_id == shoppingCartCombos[s].combo_id) {
                        if (tempOrderComboDiscount[t].setGroupString == shoppingCartCombos[s].setGroupString) {
                           if (shoppingCartCombos[s].descuento > 0) {
                              arrayTempCombos.push(shoppingCartCombos[s])
                           } else {
                              arrayTempCombos.push(tempOrderComboDiscount[t])
                           }
                        }
                     }
                  }
                  //------------------------
               };

               //filter array  not discount
               let responseArray = obtenerValoresDiferentes(shoppingCart, productChecked)
               setShoppingCart(arrayTempProduct.concat(responseArray));

               let responseArrayCombos = obtenerValoresDiferentesCombos(shoppingCartCombos, combosChecked)
               setShoppingCartCombos(arrayTempCombos.concat(responseArrayCombos))

               /*let arrayProducts = new Array
               if (tempOrderDiscount.length != shoppingCart.length) {
                  for (let sh = 0; sh < shoppingCart.length; sh++) {
                     for (let tem = 0; tem < tempOrderDiscount.length; tem++) {
                        console.log(tempOrderDiscount[tem].segments_string);
                        console.log(shoppingCart[sh].segment_string);
                        if (shoppingCart[sh].group_id == tempOrderDiscount[tem].group_id && shoppingCart[sh].segment_string == tempOrderDiscount[tem].segments_string) {
                           arrayProducts.push(tempOrderDiscount[tem])
                        } else { arrayProducts.push(shoppingCart[sh]) }
                     }
                  }
                  setShoppingCart(arrayProducts)
               } else {
   
                  setShoppingCart(tempOrderDiscount)
               }*/

               setLoad(1)
               updateOrderDiscount(code, 3, message, title, icon);
               //We clean the state of the products checked 
               setProductChecked([])
               setCombosChecked([])
               setShow(false)
            }
         } else if (type == 1) {
            console.log("consulte la temporal remanente");
            updateOrderDiscount(code, 5, message, title, icon)
         }
      } catch (err) {
         console.log(err);
      }
   }

   //Attay filter shopingcart
   function obtenerValoresDiferentes(arr1, arr2) {
      var ArrayFilter = arr1.filter(function (elemento) {
         return !arr2.some(function (e) {
            return e.group_id == elemento.group_id && e.segments_string === elemento.segments_string;
         });
      });

      return ArrayFilter;
   }

   function obtenerValoresDiferentesCombos(arr1, arr2) {
      var ArrayFilter = arr1.filter(function (elemento) {
         return !arr2.some(function (e) {
            return e.combo_id == elemento.combo_id;
         });
      });

      return ArrayFilter;
   }



   //update orders temporal discount
   const updateOrderDiscount = async (code, status, message, title, icon) => {

      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let business = JSON.parse(localStorage.getItem('business'))

      let business_code = business.code
      let branch_code = branchoffice.code 

      if (codeParthner != 0) {
          business_code = codeParthner.business_code
          branch_code = codeParthner.branchoffice_code
      }

      let jsonUpdate = {
         "order_code": code,
         "business_code": business_code,
         "branchoffice_code": branch_code,
         "status": status
      }
      try {
         const response = await DiscountPosFetchAPI.updateOrderTemp(jsonUpdate, token)
         setStateRequest(false);
         if (type == 0) {
            ErrorAlert(message, title, icon)
         } else if (type == 1) {
            console.log("llame crear nuevamente despues de borrar la ya existente");
            createTempDiscount()
         }

      } catch (err) {
         console.log(err);
      }

   }

   const cardItem = (product, index) => {
      return (<>
         <article className='col-12 col-xl-3 col-lg-4 col-md-6 my-1 list-pre-orders-inputs'>


            <input
               className="list-group-item-check "
               type="checkbox" value={JSON.stringify(product)}
               data-index={index} defaultChecked={false}
               id={product.name + "discount" + index}
               name="checked1" onChange={(e) => (getChecked(e))}
               disabled={product.descuento > 0 ? true : false} />

            <label className="list-group-item rounded-3 mb-0 fw-bold text-truncate bg-white rounded-2 p-1"
               htmlFor={product.name + "discount" + index}
            >
               <div className="d-flex align-items-center gap-3 py-1 rounded" >
                  <img src={product.img}
                     alt="Product" width="50" height="50" className="rounded border flex-shrink-0" />
                  <div className="d-flex gap-2 justify-content-around">
                     <div>
                        <span className="mb-0 text-wrap">
                           <i class="uil uil-pricetag-alt"></i>
                           {product.name}
                        </span>
                        <span className=" d-block mb-0 fw-normal ">
                           Precio: ${product.price.toFixed(2)}
                        </span>
                     </div>
                  </div>
               </div>
            </label>
         </article>
      </>)
   }

   //event to notification discount
   const aplyDiscount = async () => {
      setStateRequest(true)
      let message = ""
      let title = ""
      let icon = ""

      /*if (discount > 0) {
         message = "No se pueden aplicar dos descuentos"
         title = "Ya existe un descuento aplicado."
         icon = "warning"
         ErrorAlert(message, title, icon)

      } else {
         if (productChecked.length > 0) {
            createTempDiscount()
            let title = 'Solicitud de decuento enviada'
            let message = 'Espere un momento el supervisor esta aprobando el descuento....<br/> <br/>'
            LoadingAlert(title, message);
         } else {

            message = "Debe seleccionar un producto."
            title = "No hay productos seleccionados."
            icon = "error"
            ErrorAlert(message, title, icon)
         }
      }*/


      if ((productChecked.length + combosChecked.length) > 0) {
         createTempDiscount()
         let title = 'Solicitud de decuento enviada'
         let message = 'Espere un momento el supervisor esta aprobando el descuento....<br/> <br/>'
         LoadingAlert(title, message);
      } else {
         setStateRequest(false)
         message = "Debe seleccionar un producto."
         title = "No hay productos seleccionados."
         icon = "error"
         ErrorAlert(message, title, icon)

      }
   }

   const discountDirect = (discount) => {

      console.log(productChecked);
      console.log(combosChecked);

      //We get the discount rate 
      let discount_rate = discount.discountRate / 100;
      let totaldiscounts = 0;
      let arrayGroupId = []
      shoppingCart.map(group => {

         let data = productChecked.find((product) => product.group_id === group.group_id);

         if (data != undefined) {
            arrayGroupId.push({ group_id: group.group_id })
            console.log("entre en grupo");
            calculateDiscount(group)
         }
      });

      let arrayCombosId = []
      shoppingCartCombos.map(combo => {
         let data = combosChecked.find(product => product.combo_id === combo.combo_id);
         if (data != undefined) {
            arrayCombosId.push({ combo_id: combo.combo_id })
            console.log("entre en combo");
            calculateDiscount(combo)
         }
      });


      function calculateDiscount(item) {
         let group_info = item


         let unit_discount = decimalReduction(group_info.price * discount_rate);
         let total_product_discount = decimalReduction((group_info.price * discount_rate) * group_info.amount)
         group_info.unit_discount = parseFloat(unit_discount)  //rounding(unit_discount);
         totaldiscounts = totaldiscounts + parseFloat(total_product_discount) //rounding(total_product_discount);
         group_info.discount = parseFloat(group_info.unit_discount * group_info.amount);
         group_info.descuento = group_info.discount;
         console.log(totaldiscounts);
      }


      let dataUser = JSON.parse(localStorage.getItem('user'))
      let dataDiscount = {
         "name": discount.discount_name,
         "discount_id": discount.discount_id,
         "total": totaldiscounts,
         "percentage": discount.discountRate + "%",
         "groups": arrayGroupId,
         "combos": arrayCombosId,
         "beneficiary": {
            "client_id": '',
            "client_name": '',
            "user_id": '',
            "user_name": ''
         },
         "aprrovedby": {
            "user_id": dataUser.id,
            "user_name": dataUser.name
         }
      }

      if (dataDicountApply.length > 0) {

         let discountfind = dataDicountApply.find((discountArray) => discountArray.discount_id === discount.discount_id)

         if (discountfind != undefined) {
            dataDicountApply.forEach((discountAply) => {
               let newGroups = arrayGroupId.concat(discountAply.groups)
               let newCombos = arrayCombosId.concat(discountAply.combos)
               discountAply.total = (discountAply.total + totaldiscounts)

               discountAply.groups = newGroups
               discountAply.combos = newCombos

            })

            console.log("data detalle descuento");
            console.log(dataDicountApply);
            setdataDiscountApply(dataDicountApply)

         } else {
            setdataDiscountApply([...dataDicountApply, dataDiscount])
         }
      } else {
         setdataDiscountApply([dataDiscount])
      }

      setShoppingCart(shoppingCart)
      setShoppingCartCombos(shoppingCartCombos)

      setShowModalTaxes(false)
      setShow(false)
      setProductChecked([])
      setCombosChecked([])
      setLoad(1)


   }



   const getDiscounts = async () => {
      
      let infoBusiness = JSON.parse(localStorage.getItem("business"))
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let business_id= infoBusiness.id

      if(branchoffice.parthner_business){
         business_id= branchoffice.parthner_business
      }
      try {
         //We get the discounts 
         const discounts = await DiscountFetchAPI.getDiscountsByBusiness(business_id, token);
         setShowModalTaxes(true)
         console.log(discounts.data.data);
         setBusinessDiscounts(discounts.data.data);
      } catch (err) {
         setBusinessDiscounts([])
         console.log(err);
      }
   }


   function mathRound(num) {
      //Respuesta de Rubén
      return Math.round(num * 100) / 100;
   }
   //decimal reduction
   const decimalReduction = (value) => {

      console.log(mathRound(1.223))
      console.log(mathRound(1.255))
      console.log(mathRound(1.288))

      let stringTax = mathRound(value).toString()
      let arraySteingTax = stringTax.split(".");
      let newDecimalTaxString = []
      let sampleTax = ''
      if (arraySteingTax[1] != undefined) {
         newDecimalTaxString = arraySteingTax[1].slice(0, 2);
         sampleTax = arraySteingTax[0] + "." + newDecimalTaxString
         console.log("Hice la reduccion");
      } else {
         console.log("Array: " + arraySteingTax);
         console.log("Decimal: " + newDecimalTaxString);
         console.log("Nuevo valor: " + sampleTax);
         sampleTax = stringTax;
      }

      let endValue = parseFloat(sampleTax).toFixed(2)
      return endValue
   }


   return (
      <>
         {/* <button className='btn btn-outline-primary btn-sm border-0' onClick={() => setShow(true)}>
         <i className="uil uil-refresh "></i>
            <small className='fw-bold'>             
                            
            </small>
         </button> */}

         {actvPlus ? <button className='
         btn btn-outline-dark border-0' onClick={() => setShow(true)}>
            <i className="uil uil-percentage icon-mobile"></i>
            <small className='fw-bold label-responsive  hidden-label-sm'>Descuentos </small>
         </button> : <></>}


         {actvLite ? <div className=" m-1 bg-white border rounded ">
            <button className="btn btn-outline-primary  py-2 border-0" type="button" onClick={() => setShow(true)}>
               <i className="uil uil-percentage buttons-label icon-mobile h3 "></i>
               <span className='h5 fw-bold mx-1 d-block text-center'> Descuentos </span>
            </button>
         </div> : <></>}


         <Modal
            show={show}
            size="xl"
            centered
            onHide={() => (setShow(false), setProductChecked([]), setCombosChecked([]))}
            aria-labelledby="example-custom-modal-styling-title"
         >
            {/* <div className='d-flex justify-content-between align-items-center'>
               <h4 className='fw-bold'>
                  <i className="uil uil-percentage icon-mobile"></i>
                  Descuentos
               </h4>
               <button className=' p-3 btn-close bg-secondary bg-opacity-10 rounded-pill'
                  onClick={() => (setShow(false), setProductChecked([]))}>
            
               </button>

            </div> */}
            <Modal.Body>
               <div className="d-flex justify-content-center border-bottom-0">
                  <h2 className="modal-title fw-bold fs-5">
                     <i className="uil uil-percentage "></i>
                     Descuentos
                  </h2>
                  {/* <button type="button" className="btn-close rounded"
                     onClick={() => (setShow(false), setProductChecked([]))}></button> */}
               </div>
               <div className='flex-container'>
                  <p className='p-0  text-primary fw-bold my-1'>Lista de Productos  </p>

                  <div className={
                     (shoppingCart.length + shoppingCartCombos.length) == 0
                        ? "d-block item2 border-inset bg-light-subtle border border-2 border-1 border-light-subtle rounded-3 list-discount-framer align-self-center overflow-auto w-100 d-flex align-items-center justify-content-center"
                        : "row item2 border-inset bg-light-subtle border border-2 border-1 border-light-subtle rounded-3 list-discount-framer-2 align-self-center  w-100"

                  }
                  >
                     {/* Validatopn Shoppint Cart Empty  */}
                     {
                        (shoppingCart.length + shoppingCartCombos.length) == 0
                           ? <Empty title="Vacío"
                              msg="No hay productos disponibles para descuento en este momento"
                              img={empty_img} />
                           : <>{shoppingCart.map((product, index) => (
                              <>{cardItem(product, index)}</>
                           ))}

                              {shoppingCartCombos.map((combo, index) => (
                                 <>{cardItem(combo, index)}</>
                              ))}
                           </>

                     }
                  </div>
                  <small className='text-body-tertiary text-center'>
                     <i className="uil uil-info-circle me-1"></i>
                     Por favor, seleccione uno o varios productos para solicitar el descuento.
                  </small>
                  <div className='d-block mt-lg-1 mt-0'>
                     <div className='d-flex justify-content-between justify-content-lg-end mt-2'>
                        <button className='btn bg-danger-subtle text-danger  border-0 fw-bold ' onClick={() => (setShow(false), setProductChecked([]), setCombosChecked([]))}> Cancelar </button>
                        <button className='btn bg-success-subtle text-success fw-bold border-0  ms-1' onClick={() => (aplyDiscount())} disabled={stateRequest}> {discount_validation == 0?<>Solicitar descuento personalizado</>:<>Solicitar Descuento</>} </button>

                        <RenderIf isTrue={discount_validation == 0}>
                           <button className='btn bg-success-subtle text-success fw-bold border-0  ms-1' onClick={() => (getDiscounts())}> Aplicar descuento </button>
                        </RenderIf>

                     </div>
                  </div>
               </div>
            </Modal.Body>


            {/* <Button variant="danger" size="lg" onClick={() => (setShow(false))}>Cancelar</Button>
               <Button variant="success" size="lg" onClick={() => (aplyDiscount())}></Button> */}

         </Modal>



         <Modal
            size='lg'
            show={showModalTaxes}
            onHide={() => setShowModalTaxes(false)}
            backdrop="static"
            centered>
            <Modal.Body>
               <div className="header-pos mb-3">
                  <div className='d-flex justify-content-center  align-items-center'>
                     <div className='fw-bold px-1 fs-4 text-center text-primary'>
                        <i className="uil uil-bill me-1"></i>
                        SELECCIONA UN IMPUESTO
                     </div>
                  </div>
               </div>
               <div className='row d-flex justify-content-center align-item-center m-3'>
                  <div className='col-4 d-flex justify-content-center align-item-center m-2'>

                     <div className="button-container">
                        <input type="radio" className="btn-check"
                           id={"no"}
                           name='taxes'
                           defaultChecked
                           onChange={() => setTaxSelect(0)} />
                        <label className="btn btn-outline-primary rounded-3 border"
                           htmlFor={"no"}>
                           Sin impuesto
                        </label>
                     </div>
                  </div>{businessDiscounts.map((discount) => (
                     <div className='col-4 d-flex justify-content-center align-item-center m-2'>

                        <div className="button-container">
                           <input type="radio" className="btn-check"
                              id={discount.discount_id}
                              name='taxes'
                              value={JSON.stringify(discount)}
                              onChange={(e) => discountDirect(discount)} />
                           <label className="btn btn-outline-primary rounded-3 border"
                              htmlFor={discount.discount_id}>
                              {discount.discount_name}: {discount.discountRate}%
                           </label>
                        </div>
                     </div>
                  ))}
               </div>


               <div className='d-block mt-lg-1 mt-0'>
                  <div className='d-flex justify-content-end mt-2'>
                     <button className='btn  btn-lg btn-danger  border-0 fw-bold '
                        onClick={() => (setShowModalTaxes(false))}> Cancelar </button>


                  </div>
               </div>

            </Modal.Body>

         </Modal>


      </>
   )
}

export default ModalDiscounts