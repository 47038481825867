import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

//Context
import UserContext from "../../context/UserContext";

//Api 
import { AuthFetchAPI } from '../../api/Auth';
import { UsersFetchAPI } from '../../api/Users';
import { BusinessFetchAPI } from '../../api/Business';
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { MachinesFetchAPI } from '../../api/Machines';
//Components 
import Swal from "sweetalert2";
//Img
import logo_sp from '../img/logos/icon-black.png';

//Utils 
import { RenderIf } from '../utils/RenderIf'


const Login = () => {
    //States 
    const [businessData, setBusinessData] = useState({
        name: '',
        id: 0
    });
    const [users, setUsers] = useState([]);

    //Context 
    const { setUserData } = useContext(UserContext);
    //Params
    const { business_code, branchoffice_code, machine_code } = useParams();
    //Navigation
    const navigate = useNavigate();
    //Location page 
    const url_path = useLocation();

    //Function to get the initial data 
    const getInitialData = async (business_code) => {
        //Variables
        let response_business = '';
        try {
            //We get the business
            response_business = await BusinessFetchAPI.getBusinessByCode(business_code)
            //We set the business data 
            setBusinessData(response_business.data.business)
        } catch (err) {
            //If we get some error we redirect to a no mactch page 
            navigate('/nomatch')
        }
    }

    //Function to get the users of a branchoffice or a business depending the role 
    const getUsers = async (branchoffice_id, branch_code) => {
        try {
            //Variables 
            let user_role_id;
            let response;
            //We check what type of login
            if (url_path.pathname.includes('cashier')) {
                user_role_id = 4 //Cashier role 
            } else if (url_path.pathname.includes('dispatcher')) {
                user_role_id = 5 //Dispatcher role
            } else {
                user_role_id = 3; //Admin role 
            }

            //We check if the role is tha admin role to make the consult 
            if (user_role_id === 3) {
                //If the role is admin we just get the admin users by business 
                response = await UsersFetchAPI.getUsesByBusinessAndRole(businessData.id, [3,6])

            } else {
                //We get the users by branch and role 
                response = await UsersFetchAPI.getUsersByRoleAndBranchoffice(branchoffice_id, user_role_id);
            }
            setUsers(response.data.users);

        } catch (err) {
            //If we get some error we set the users data empty 
            setUsers([])
        }
    }

    //Event to login
    const submit = async (e) => {
        e.preventDefault();
        //We check if the user check one branch office 
        if ((url_path.pathname.includes('cashier') && branchoffice_code !== null) || (url_path.pathname.includes('dispatcher') && branchoffice_code !== null) || (!url_path.pathname.includes('dispatcher') || !url_path.pathname.includes('cashier') && branchoffice_code === undefined || branchoffice_code === null)) {
            const form = new FormData(e.target);
            let user_logged = Object.fromEntries(form.entries());
            try {
                const response = await AuthFetchAPI.login(user_logged);
                //We get the token and user data 
                let token = response.data.token;
                let user = response.data.user;
                //We set the user info and the token access 
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('user_original', JSON.stringify(user));
                localStorage.setItem('x-access-token', token);
                //We set the context of the user 
                setUserData({ token, user: user });

                //We check the role of the user and depending of it we redirect 
                if (user.role === 4) {
                    navigate(`/payments/${business_code}/${branchoffice_code}`)
                }

            } catch (err) {
                Swal.fire({
                    icon: "error",
                    title: err.response.data.error,
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } else {
            //If the user dont select any branch we throw an error
            Swal.fire({
                icon: "error",
                title: 'Seleccione una sucursal',
                showConfirmButton: false,
                timer: 1500,
            });
        }

    }


    //Event to login a machine 
    const loginMachine = async () => {
        //We set the form data object 
        const form = new FormData();
        form.append('branchoffice_code', branchoffice_code);
        form.append('business_code', business_code);
        form.append('machine_code', machine_code);
        let machine = Object.fromEntries(form.entries());
        try {
            const response = await AuthFetchAPI.loginSmartPay(machine);
            //We get the token and user data 
            let token = response.data.token;
            let business = response.data.business;
            let branchoffice = response.data.branchoffice;
            let machine_info = response.data.machine
            //We set the token info and the token access 
            localStorage.setItem('x-access-machine-token', token);
            localStorage.setItem('business', JSON.stringify(business));
            localStorage.setItem('branchoffice', JSON.stringify(branchoffice));
            localStorage.setItem('branchoffice_original', JSON.stringify(branchoffice));
            localStorage.setItem('machine', JSON.stringify(machine_info));
            localStorage.setItem('machine_original', JSON.stringify(machine_info));

            //We get the users of the branch 
            getUsers(branchoffice.id, branchoffice.code);
        } catch (err) {
            console.log(err)
            //If the machine login send any error we check if the data already exist on the browser 
            let business = localStorage.getItem('business');
            let branchoffice = localStorage.getItem('branchoffice');
            let machine = localStorage.getItem('machine');
            //We check if the data is empty 
            if ((machine === '' && branchoffice === '' && business === '') || (machine=== null && branchoffice === null && business === null) || (err.response.data.error && err.response.data.error=== 'Machine code not found, try again') ) {
                navigate(`/machines/login/${business_code}`)
            } else {
                let branch = JSON.parse(branchoffice)
                getUsers(branch.id, branch.code);
                console.log('Ya se logeo una maquina aqui, en esta computadora')
            }
        }

    }

    //Hook to get data when the page is loaded
    useEffect(() => {
        if (machine_code === undefined) {
            //We get the business by code 
            getInitialData(business_code);
            //We get the user data 
            let branchoffice = localStorage.getItem('branchoffice')
            let branch = JSON.parse(branchoffice)
            getUsers(branch.id, branch.code);
        } else {
            loginMachine()
        }

    }, []);

    useEffect(() => {
      
        let urlNow = window.location.href + ""
        let arrayParams = urlNow.split('/');
        console.log(arrayParams[3] + " " +arrayParams[4] + " " +arrayParams[5]);

        if(arrayParams[3] == "machine" && arrayParams[4] == "cashier" && arrayParams[5] == "login"){
            if (localStorage.getItem("x-access-token") == "" || localStorage.getItem("x-access-token") == undefined  || localStorage.getItem("x-access-machine-token") == "" || localStorage.getItem("x-access-machine-token") == undefined || localStorage.getItem("branchoffice") == "" || localStorage.getItem("branchoffice") == undefined || localStorage.getItem("machine") == "" || localStorage.getItem("machine") == undefined || localStorage.getItem("user") == "" || localStorage.getItem("user") == undefined) {

            } else {
               let branchoffice = JSON.parse(localStorage.getItem('branchoffice'));
               let machine = JSON.parse(localStorage.getItem('machine'));
               let business = JSON.parse(localStorage.getItem("business"));
      
               //console.log(`/kiosk/${business.code}/${branchoffice.code}`);
               navigate(`/payments/${business.code}/${branchoffice.code}`)
            }
        }

    }, []);



    //Function to list the users of branchoffice
    const listUsers = () => {
        const users_list = users.map((data) => (
            <>
                <input type="radio" className="btn-check" name="email" id={data.user_id} value={data.email} />
                <label className="btn btn-outline-primary rounded-pill" for={data.user_id}>{data.user_name}</label>
            </>
        ))
        //We check the users list
        return (<>{users_list} </>)
    }


    return (
        <>
            <div className="login-smart">
                <main className="login-card border shadow-sm">
                    <img src={logo_sp} alt="" width="100" />
                    <h1 className="title-main-login">Iniciar Sesión</h1>

                    <h3 className="title-main-login">{businessData.name}</h3>

                    {/**List and form of users depending the branchoffice */}
                    <RenderIf isTrue={url_path.pathname.includes('cashier') || url_path.pathname.includes('dispatcher')}>
                        {/*Form */}
                        <form onSubmit={submit} className="py-3" noValidate>
                            <label className="form-label d-flex justify-content-start fw-bold">Usuarios</label>
                            <div className='login-wrapper  '>
                                <div className='d-flex gap-1 p-2 flex-column'>
                                    {listUsers()}
                                </div>
                            </div>
                            <div className='email mt-3'>
                                <label className="form-label d-flex justify-content-start fw-bold">Contraseña</label>
                                <input
                                    spellcheck="false"
                                    className="control"
                                    id="password"
                                    type="password"
                                    name='pass'
                                    placeholder="Ingresar contraseña"
                                    required
                                />

                            </div>
                            <button className="control" type="submit"> Ingresar </button>
                        </form>
                    </RenderIf>
                </main>
            </div>
        </>
    )
}

export default Login