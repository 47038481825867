//React components
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';

//Components 
import TransactionHistory from "../../transactions/TransactionHistory";
import Modal from 'react-bootstrap/Modal';
//Internal components 
import ReportTable from '../../reports/table/ReportTable';
import OrderDetails from '../../Orders/OrderDetails';

//External modules 
import moment from 'moment-timezone';
//API
import { OrdersFetchAPI } from '../../../api/Orders';
import { InformZFetchAPI } from '../../../api/InformZ';

//img
import empty from '../../img/icons/empty.png'
//Utils 
import { RenderIf } from '../../utils/RenderIf';

function ModalInformZOrders({ inform }) {
    //Params
    let { business_code, branchoffice_code } = useParams();
    let selectedBranch = branchoffice_code
    //We get the user data 
    let user = localStorage.getItem('user');
    let token = localStorage.getItem('x-access-token');
    let branchoffice = localStorage.getItem('branchoffice')

    //We validate if the user is logged in
    if (user === null || user === undefined || user === '' || user === 'null' || branchoffice === null || branchoffice === undefined || branchoffice === '' || branchoffice === 'null') {
        user = {
            name: 'No registrado',
            img: 'https://www.pngitem.com/pimgs/m/30-307416_profile-icon-png-image-free-download-searchpng-employee.png'
        }
    } else {
        branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
        user = JSON.parse(localStorage.getItem('user'))
    }

    //States
    const [branchOrdersData, setBranchOrdersData] = useState([]);
    const [branchAnaliticsData, setBranchAnaliticsData] = useState([]);
    const [temporal, setTemporal] = useState([]);
    const [minDate, setMinDate] = useState(new Date().toJSON().slice(0, 10));
    const [userBranchoffices, setUserBranchs] = useState([]);
    //const [selectedBranch, setSelectedBranch] = useState({code: 0});

    const [ordersPaymentMethod, setOrdersPaymentMethod] = useState([])
    const [modalShow, setModalShow] = useState(false);
    const [modalShowDetail, setModalShowDetail] = useState(false);
    const [totalOrders, setTotalOrders] = useState(0)
    const [subtotalOrders, setSubtotalOrders] = useState(0)
    const [taxOrders, setTaxOrders] = useState(0)
    const [modalShowInformzDetail, setmodalShowInformzDetail] = useState(false)

    const [detailOrder, setDetailOrder] = useState();
    const [order_type, setOrderType] = useState("temporal");
    const [userBusiness, setUserBusiness] = useState({});
    const [reload, setReLoad] = useState();

    //Variables
    //We get the actual date 
    const actualDate = moment(new Date()).format('YYYY-MM-DD')
    //We put the actual date in rank of all the day 
    const maxDate = new Date().toJSON();

    //Functions to show and hide the modal 
    const handleCloseModal = () => setModalShow(false);
    const handleShowModal = () => setModalShow(true)

    //Functions to show and hide the modal 
    const handleCloseModalDetail = () => setModalShowDetail(false);
    const handleShowModalDetail = () => setModalShowDetail(true)


    //Function to gen the empty card to the transaction history 
    const EmptyCard = () => {
        return (
            <div className="m-3 w-auto ">
                <div className="border-history d-flex justify-content-md-center py-4 px-4 " aria-current="true">
                    <div className="d-flex w-100 justify-content-center align-items-center  flex-column">
                        <img src={empty} alt="Vacio" width="35px" height="35px" />
                        <div className='text-center mt-3'>
                            <h5>Vacío</h5>
                            <h6 className="mb-0 text-secondary fw-bold">No hay transacciones </h6>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    //Function to get order by id (order_id)
    const getOrderById = async (order_id) => {
        try {
            const response = await OrdersFetchAPI.getOrderById(order_id);
            setDetailOrder(response.data.order)
            console.log(detailOrder)
            setOrderType("permanent")
        } catch (err) {
            setDetailOrder({})
            setOrderType("permanent")
            console.log(err)
        }
    }

    //Function to populate the order details (modal)
    const detailOrderList = () => {
        return detailOrder === undefined ? "" : <OrderDetails pos={true} orderData={detailOrder} order_type={order_type} business_data={userBusiness} setLoad={setReLoad} />
    }

    //Function to populate the list orders 
    const listOrders = () => {

        const listOrders = branchOrdersData.map((data) => (
            <>
                <div onClick={async () => {
                    await getOrderById(data._id);
                    handleShowModalDetail();
                }}>
                    <TransactionHistory data={data} />
                </div>

            </>
        ));
        //We check if the list orders has data 
        if (listOrders.length === 0) {
            return EmptyCard()
        } else {
            return (listOrders)
        }
    }








    const getInformZById = async (inform_z_id) => {
        try {
            const response = await InformZFetchAPI.getinformzById(inform_z_id, token)
            console.log(response.data.orders)



            let ordersFilter = response.data.orders
            let total = 0
            let subtotal = 0
            let tax = 0
            ordersFilter.forEach(order => {
                subtotal = (subtotal + parseFloat(order.subtotal))
                tax = (tax + parseFloat(order.tax))
            });

            setSubtotalOrders(subtotal)
            setTaxOrders(tax)
            setTotalOrders((subtotal + tax))
            setBranchOrdersData(ordersFilter);
            setTemporal(ordersFilter);
            setmodalShowInformzDetail(true)
        } catch (err) {
            console.log(err)
        }
    }

    //Function to search any result on the search filter 
    function handleChangeFiltro(event) {
        let resultFilter = [];
        if (event.target.value == "") {
            console.log(event.target.value);
            setBranchOrdersData(temporal);
        } else {
            for (
                let recorrer = 0;
                recorrer < branchOrdersData.length;
                recorrer++
            ) {
                const examinar = branchOrdersData[recorrer];

                const info = JSON.stringify(examinar);
                const resultado = info
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase());

                if (resultado == true) {
                    resultFilter.push(examinar);

                    setBranchOrdersData(resultFilter);
                    console.log(resultFilter);
                }
            }
        }
    }

    //Function to get the payment method orders 
    const getPaymentMethodOrders = async (paymentmethod_id) => {
        try {
            //We call the orders of the branchoffice 
            const response = await OrdersFetchAPI.getTransactionsByBranchOfficePaymentMethodbyUser(business_code, selectedBranch, minDate, minDate + 'T23:59:59.000+00:00', paymentmethod_id, user.id, token);
            console.log(response)
            setOrdersPaymentMethod(response.data.orders)
        } catch (err) {
            console.log(err)
        }
    }

    const reportDetail = (general_sales) => {
        return <ReportTable table_name="Tabla de ventas detalladas" table_headers={["Fecha", "Hora", "Orden", "Total", "Itbms", "Descuento"]} table_type="general_report" table_data={general_sales} />

    }




    //Hook to charge data when the page is loaded 
    useEffect(() => {
        (async () => {
            try {
                //We set the branchoffices of the user 
                setUserBranchs(user.branchoffices);
                setUserBusiness(user.business);
                //We search the branch orders 
            } catch (err) {
                console.log(err);
            }
        })();
    }, []);


    return (
        <>
            <div className="border rounded-1 my-2 bg-white p-2" key={inform._id} onClick={() => getInformZById(inform._id)}>
                {/* 0*/}
                <div className='d-flex justify-content-between'>
                    <h6 className="mb-0 text-muted">
                        <i className={"uil uil-bill text-white bg-primary rounded-pill me-1 px-1"} ></i>
                        Cierre # {inform.code}</h6>
                    <div className='text-sp text-bold '> <i className="fa-solid fa-check"></i> Realizada</div>
                </div>


                {/* 1 */}
                <div className="d-flex justify-content-between align-items-center">
                    <div className='d-inline-flex align-items-center'>
                        <span className='text-muted me-1'> User: </span> <b> {inform.user.name} </b>

                    </div>
                    <div className='d-inline-flex align-items-center'>
                        <b className='text-primary'> {inform.branchoffice.name} </b>
                    </div>
                </div>
                {/* 2 */}
                <div className="d-flex justify-content-between align-items-center">
                    <div className='d-inline-flex align-items-center'>
                        <span className='text-muted me-1'>
                            <i className="uil uil-calendar-alt"></i>
                            {(inform.createdAt.split('T'))[0]}
                        </span>
                    </div>

                    <div className='d-inline-flex align-items-center'>
                        <span className='text-muted me-1'>
                            <i className="uil uil-clock"></i>
                            {(inform.createdAt.split('T'))[1]}
                        </span>
                    </div>
                </div>
            </div>


            {/* Modal detail order */}
            <Modal show={modalShowInformzDetail} onHide={() => setmodalShowInformzDetail(false)}>
                <Modal.Header closeButton>
                    <h4 className='text-center mt-2 fw-bold text-dark'> Transacciones cierre # {inform.code}  </h4>
                </Modal.Header>
                <Modal.Body >
                    {/* 1 */}
                    <div className="w-auto bg-light px-1 py-2 border rounded my-2">
                        <div className="border-0" >
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="mb-0 text-secondary fw-bold">Buscador:</h6>
                                <div className='border-0 fw-bold fs-3'>
                                    <input
                                        spellcheck="false"
                                        className="form-control"
                                        type="text"
                                        step="any"
                                        onChange={handleChangeFiltro}
                                        placeholder="Buscar orden "
                                    />
                                </div>
                            </div>
                        </div>

                        {/* 2*/}
                        <div className="item2 p-2 border">
                            {listOrders()}

                        </div>
                        {/* 3 */}
                        <div className='d-flex justify-content-between mt-1'>
                            <h5><strong>Total de ventas:</strong></h5>
                            <h5><strong>${parseFloat(totalOrders).toFixed(2)}</strong></h5>
                        </div>
                    </div>


                    {/* Modal */}
                    <Modal show={modalShow} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Detalles</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {reportDetail(ordersPaymentMethod)}
                        </Modal.Body>
                    </Modal>


                    {/* Modal detail order */}
                    <Modal show={modalShowDetail} onHide={handleCloseModalDetail}>
                        <Modal.Header closeButton>
                            <Modal.Title>Detalles</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {detailOrderList()}
                        </Modal.Body>
                    </Modal>


                </Modal.Body>
            </Modal>

        </>
    )
}

export default ModalInformZOrders