//API 
import { EvertechFetchAPI } from "../api/Evertech";

// We create a function to make a sale with Evertech
const evertechSale = async (data) => {
    try {
        // We make the sale
        const res = await EvertechFetchAPI.makeSale(data);

        // We check the response of the server in base of the response of the pin pad
        if (res && res.data !== undefined) {
            // If the response exists, we check the approval code 
            if (res.data.approval_code !== "00") {
                console.log("Approval code error");
                return ({
                    status: 400,
                    data: res.data
                });
            } else {
                return res;
            }
        }

        // We return the response
        return res;
    } catch (error) {
        let parsedError;
        console.log("Error caught in evertechSale:", error);

        // If the error is already an object, no need to parse
        if (typeof error === 'object' && error !== null) {
            parsedError = error;
        } else {
            // We try to convert the error into an object
            try {
                parsedError = JSON.parse(error);
            } catch (e) {
                // If parsing fails, return a generic error object
                parsedError = { status: 500, message: error };
            }
        }

        // Throw the formatted error object
        if (parsedError.status && parsedError.data) {
            return parsedError;
        } else if (error.response) {
            throw {
                status: 400,
                data: error.response.data
            };
        } else {
            throw error;
        }
    }
};

//We create a function to make a cash sale with evertech
const evertechCashSale = async (data) => {
    try {
        console.log("Data en cash", data);
        // We make the sale
        const res = await EvertechFetchAPI.makeCashSale(data);

        // We check the response of the server in base of the response of the pin pad
        if (res && res.data !== undefined) {
            // If the response exists, we check the approval code 
            if (res.data.approval_code !== "00") {
                console.log("Approval code error");
                return ({
                    status: 400,
                    data: res.data
                });
            } else {
                return res;
            }
        }

        // We return the response
        return res;
    } catch (error) {
        let parsedError;
        console.log("Error caught in evertechSale:", error);

        // If the error is already an object, no need to parse
        if (typeof error === 'object' && error !== null) {
            parsedError = error;
        } else {
            // We try to convert the error into an object
            try {
                parsedError = JSON.parse(error);
            } catch (e) {
                // If parsing fails, return a generic error object
                parsedError = { status: 500, message: error };
            }
        }

        // Throw the formatted error object
        if (parsedError.status && parsedError.data) {
            return parsedError;
        } else if (error.response) {
            throw {
                status: 400,
                data: error.response.data
            };
        } else {
            throw error;
        }
    }
}

//We create a function to make a ebt sale with evertech
const evertechEBTSale = async (data) => {
    {
        try {
            console.log("Data en ebt", data);
            // We make the sale
            const res = await EvertechFetchAPI.makeEBTOperation(data);

            // We check the response of the server in base of the response of the pin pad
            if (res && res.data !== undefined) {
                // If the response exists, we check the approval code 
                if (res.data.approval_code !== "00") {
                    console.log("Approval code error");
                    return ({
                        status: 400,
                        data: res.data
                    });
                } else {
                    return res;
                }
            }

            // We return the response
            return res;
        } catch (error) {
            let parsedError;
            console.log("Error caught in evertechSale:", error);

            // If the error is already an object, no need to parse
            if (typeof error === 'object' && error !== null) {
                parsedError = error;
            } else {
                // We try to convert the error into an object
                try {
                    parsedError = JSON.parse(error);
                } catch (e) {
                    // If parsing fails, return a generic error object
                    parsedError = { status: 500, message: error };
                }
            }

            // Throw the formatted error object
            if (parsedError.status && parsedError.data) {
                return parsedError;
            } else if (error.response) {
                throw {
                    status: 400,
                    data: error.response.data
                };
            } else {
                throw error;
            }
        }
    }
}


// Function to process payments
export const ProcessPayment = async (props) => {
    let iDefaultHostPort, DefaultHostIP, sDefaultTID, iscashback, tips, force_duplicate, special_account, manual_entry_indicator, payment_host, basereducedtax, statetax, reducedtax, citytax;

    // Set parameters from props
    if (props.integration.name === "Evertech") {
        props.params.forEach((param) => {
            if (param.name === "iDefaultHostport") iDefaultHostPort = param.value;
            if (param.name === "DefaultHostIP") DefaultHostIP = param.value;
            if (param.name === "sDefaultTID") sDefaultTID = param.value;
            if (param.name === "iscashback") iscashback = param.value;
            if (param.name === "force_duplicate") force_duplicate = param.value;
            if (param.name === "payment_host") payment_host = param.value;
            if (param.name === "manual_entry_indicator") manual_entry_indicator = param.value;
        });
        console.log("Props taxes", props.taxes);
        props.taxes.forEach((tax) => {
            if (tax.tax === "state_tax") statetax = Math.round(tax.total * 100) || 0;
            if (tax.tax === "reduced_tax") {
                reducedtax = Math.round(tax.total * 100) || 0
                basereducedtax = Math.round((props.subtotal - tax.total) * 100) || 0
            };
            if (tax.tax === "city_tax") citytax = Math.round(tax.total * 100) || 0;
        });

        special_account = {
            "Visa": "VC",
            "Mastercard": "MC",
            "Amex": "AX",
            "Cash": "IC",
            "EBT": "EB",
        }[props.payment] || "NONE";
        console.log("Special account", props.payment);
        let data = {
            sECRStationID: props.sECRStationID,
            sCashierID: props.sCashierID,
            sDefaultHostIP: DefaultHostIP,
            iDefaultHostPort: iDefaultHostPort,
            sDefaultTID: sDefaultTID,
            sRefNumber: props.sRefNumber,
            tips: props.tips * 100,
            iscashback: iscashback,
            cashback: 0,
            total: props.total * 100,
            basereducedtax: basereducedtax,
            statetax: statetax,
            reducedtax: reducedtax,
            citytax: citytax,
            manual_entry_indicator: manual_entry_indicator,
            force_duplicate: force_duplicate,
            receipt_output: "both",
            special_account: special_account,
            payment_host: payment_host
        };

        //We check if the payment is ebt to add the EBTTransType
        if (props.payment === "EBT") {
            data.EBTTransType = "FOODPURCHASE"
            data.sTransType = "EBT"
        }

        const logonres = await EvertechFetchAPI.makeLogon({
            sECRStationID: props.sECRStationID,
            sCashierID: props.sCashierID,
            sDefaultHostIP: DefaultHostIP,
            iDefaultHostPort: iDefaultHostPort,
            sDefaultTID: sDefaultTID,
            sRefNumber: props.sRefNumber
        });
        //We check the response of the logon request
        if (logonres.data && logonres.data.approval_code === "ZY" && logonres.data.response_message === "Connection Error - LOGON") {
            console.log("si entre porq logon fallo")
            return logonres
        } else {
            //We check if the payment is cash
            if (props.payment !== "Cash" && props.payment !== "EBT") {
                return evertechSale(data)
                    .then(async (res) => {
                        const logoff = await EvertechFetchAPI.makeLogoff({
                            sECRStationID: props.sECRStationID,
                            sCashierID: props.sCashierID,
                            sDefaultHostIP: DefaultHostIP,
                            iDefaultHostPort: iDefaultHostPort,
                            sDefaultTID: sDefaultTID
                        });
                        console.log(logoff);
                        // We return the response
                        return res;
                    })
                    .catch(async (error) => {
                        console.log("Error in catch", error);

                        const logoff = await EvertechFetchAPI.makeLogoff({
                            sECRStationID: props.sECRStationID,
                            sCashierID: props.sCashierID,
                            sDefaultHostIP: DefaultHostIP,
                            iDefaultHostPort: iDefaultHostPort,
                            sDefaultTID: sDefaultTID
                        });
                        console.log(logoff);


                        let parsedError;
                        // If the error is already an object, no need to parse
                        if (typeof error === 'object' && error !== null) {
                            console.log("Error is object", error);
                            parsedError = error;
                        } else {
                            console.log("Error is not object", error);

                            try {
                                parsedError = JSON.parse(error);
                            } catch (e) {
                                parsedError = { status: 500, data: error.message };
                            }
                        }
                        console.log("Parsed error1111", parsedError);

                        // Throw the formatted error object
                        if (parsedError.status && parsedError.data) {
                            console.log("Parsed error", parsedError);
                            return parsedError;
                        } else if (error.response) {
                            console.log("Error response", error.response);
                            return {
                                status: 400,
                                data: error.response.data
                            };
                        } else {
                            console.log("Error", error);
                            return error;
                        }
                    });
            } else if (props.payment === "EBT") {
                return evertechEBTSale(data)
                    .then(async (res) => {
                        const logoff = await EvertechFetchAPI.makeLogoff({
                            sECRStationID: props.sECRStationID,
                            sCashierID: props.sCashierID,
                            sDefaultHostIP: DefaultHostIP,
                            iDefaultHostPort: iDefaultHostPort,
                            sDefaultTID: sDefaultTID
                        });
                        console.log(logoff);
                        // We return the response
                        return res;
                    })
                    .catch(async (error) => {
                        console.log("Error in catch", error);

                        const logoff = await EvertechFetchAPI.makeLogoff({
                            sECRStationID: props.sECRStationID,
                            sCashierID: props.sCashierID,
                            sDefaultHostIP: DefaultHostIP,
                            iDefaultHostPort: iDefaultHostPort,
                            sDefaultTID: sDefaultTID
                        });
                        console.log(logoff);
                    });
            } else {
                return evertechCashSale(data)
                    .then(async (res) => {
                        const logoff = await EvertechFetchAPI.makeLogoff({
                            sECRStationID: props.sECRStationID,
                            sCashierID: props.sCashierID,
                            sDefaultHostIP: DefaultHostIP,
                            iDefaultHostPort: iDefaultHostPort,
                            sDefaultTID: sDefaultTID
                        });
                        console.log(logoff);
                        // We return the response
                        return res;
                    })
                    .catch(async (error) => {
                        console.log("Error in catch", error);

                        const logoff = await EvertechFetchAPI.makeLogoff({
                            sECRStationID: props.sECRStationID,
                            sCashierID: props.sCashierID,
                            sDefaultHostIP: DefaultHostIP,
                            iDefaultHostPort: iDefaultHostPort,
                            sDefaultTID: sDefaultTID
                        });
                        console.log(logoff);


                        let parsedError;
                        // If the error is already an object, no need to parse
                        if (typeof error === 'object' && error !== null) {
                            console.log("Error is object", error);
                            parsedError = error;
                        } else {
                            console.log("Error is not object", error);

                            try {
                                parsedError = JSON.parse(error);
                            } catch (e) {
                                parsedError = { status: 500, data: error.message };
                            }
                        }
                        console.log("Parsed error1111", parsedError);

                        // Throw the formatted error object
                        if (parsedError.status && parsedError.data) {
                            console.log("Parsed error", parsedError);
                            return parsedError;
                        } else if (error.response) {
                            console.log("Error response", error.response);
                            return {
                                status: 400,
                                data: error.response.data
                            };
                        } else {
                            console.log("Error", error);
                            return error;
                        }
                    });
            }
        }

    }
};