import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'

import { PreOrderFetchAPI } from '../../api/Paymentpost/PreOrder';
import { DeletedTempOrdersFetchAPI } from '../../api/DeletedTemporalOrders';
import ModalDivisionPreOrder from './ModalDivisionPreOrder';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import Swal from "sweetalert2";
import imgPreOrder from './img/preOrder.png'
import ErrorAlert from '../Alerts/ErrorAlert';
import LoadingAlert from '../Alerts/LoadingAlert';
import { GiPlayerTime } from 'react-icons/gi';
import Empty from '../utils/Empty';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import DocPdf from '../Pdf/DocPdf';
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';


function ModalPendingOrders({ actvLite, actvPlus, preOrderInvoice, setPreOrderInvoice, setCheckDeliveryOrder, setProductChecked, setCombosChecked, setPageLoader, setEventHandler, shoppingCart, shoppingCartCombos, setDv, setName, setRuc, setDataResponse, setOptionalData, setShoppingCart, setShoppingCartCombos, setInvoiceSubtotal, setInvoiceTotal, setInvoiceTotalDiscount, setInvoiceTotalItbms, setDataPreOrder, setTableInfo, setTaxesArrayAll, setdataDiscountApply, setDataReferenceOrderMicros, codeParthner }) {
   let title_modal = "Precuentas"
   const [show, setShow] = useState(false);
   const [preOrders, setPreOrders] = useState([]);
   const [selectPreOrder, setSelectPreOrder] = useState({ order: "" })
   const [branchOfficeData, setBranchofficeData] = useState({
      "ruc": "",
      "dv": "",
      "name": "",
      "ruc_type": "",
      "direction": "",
      "dgi_name": "",
   });
   let machine_token = localStorage.getItem('x-access-machine-token');
   //action generated 
   const getAll = async () => {

      let token = localStorage.getItem('x-access-machine-token');
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let business = JSON.parse(localStorage.getItem('business'))

      let business_code = business.code
      let branch_code = branchoffice.code

      if (codeParthner != 0) {
         console.log("Entre aquiiiiiiii");
         console.log(codeParthner);
         business_code = codeParthner.business_code
         branch_code = codeParthner.branchoffice_code
      }

      getPreOrder(business_code, branch_code, token)
      setShow(true)
   }

   //get pre orders
   const getPreOrder = async (business_code, branch_code, token) => {
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      try {
         const response = await PreOrderFetchAPI.getPreOrder(business_code, branch_code, token)
         console.log(response.data.pre_orders);

         let dataPreOrder = response.data.pre_orders
         let newArrayPreorder = []
         dataPreOrder.forEach(order => {
            if (branchoffice.code == order.branchoffice.code) {
               newArrayPreorder.push(order)
            }
         });
         setPreOrders(newArrayPreorder)
      } catch (err) {
         console.log(err);
         setPreOrders([])
      }
   }

   //update temporal autorization
   const updatePreOrder = async (data) => {
      let token = localStorage.getItem('x-access-machine-token');
      data.is_authorized = true
      try {
         //We update the temporal order
         await PreOrderFetchAPI.updateOrderTempPreOrder(
            {
               "_id": data._id,
               "status_id": data.status_id,
               "groups": data.groups,
               "is_authorized": true,
               "is_discount_notification": false,
               "is_pre_order": true,
               "combos": data.combos
            },
            token
         );

         getAll()
         ErrorAlert('', 'Orden autorizada', 'success')
      } catch (err) {
         ErrorAlert(err.error)
      }
   }

   //delete preorder
   const deletePreOrder = async (code) => {
      LoadingAlert()
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let business = JSON.parse(localStorage.getItem('business'))
      let user = JSON.parse(localStorage.getItem('user'));

      let business_code = business.code
      let branch_code = branchoffice.code


      if (codeParthner != 0) {
         business_code = codeParthner.business_code
         branch_code = codeParthner.branchoffice_code
      }

      let jsonUpdate = {
         "order_code": code,
         "business_code": business_code,
         "branchoffice_code": branch_code,
         "deleted": true,
         "status": 5,
         "user_id": user.id
      }
      try {
         //We save the preorder
         await DeletedTempOrdersFetchAPI.newDeletedTempOrder(jsonUpdate, machine_token);
         //We delete the preorder
         await PreOrderFetchAPI.deletePreOrder(jsonUpdate, machine_token)
         let message = "precuenta eliminada con exito."
         let title = "Precuenta eliminada"
         let icon = "success"
         ErrorAlert(message, title, icon)
         getAll()
      } catch (err) {
         console.log(err);
         ErrorAlert("Esta precuenta pertenece a otra sucursal", "Permiso denegado", "info")
      }
   }

   let intervalAply = 0
   const questionConfirmation = () => {
      if ((shoppingCart.length + shoppingCartCombos.length) > 0) {
         const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
               confirmButton: 'btn btn-success',
               cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
         })

         swalWithBootstrapButtons.fire({
            title: 'Aun hay productos en la cartilla',
            text: "¿Quieres aplicar la precuenta?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si aplicar!',
            cancelButtonText: 'No, cancelar!',
            reverseButtons: true
         }).then((result) => {
            if (result.isConfirmed) {
               swalWithBootstrapButtons.fire(
                  'Precuenta aplicada!',
                  'success'
               )
               setShoppingCart([]);
               setShoppingCartCombos([])
               setProductChecked([])
               setCombosChecked([])
               LoadingAlert()
               intervalAply = setInterval(aplyPreOrder, 1000);

            } else if (
               /* Read more about handling dismissals below */
               result.dismiss === Swal.DismissReason.cancel
            ) {
               swalWithBootstrapButtons.fire(
                  'Precuenta no aplicada!',
                  '',
                  'info'
               )
            }
         })
      } else {
         setShoppingCart([]);
         setShoppingCartCombos([])
         setProductChecked([])
         setCombosChecked([])
         LoadingAlert()
         intervalAply = setInterval(aplyPreOrder, 1000);

      }
   }

   const aplyPreOrder = () => {

      localStorage.setItem('preOrderComparativeOld', JSON.stringify(selectPreOrder))

      clearInterval(intervalAply)
      let message = ""
      let title = ""
      let icon = ""
      ErrorAlert(message, title, icon)
      if (selectPreOrder.order == "") {
         console.log("no hay pre cuenta seleccionada");
         message = "No ha selecciona ninguna precuenta."
         title = "Bebe seleccionar una precuenta."
         icon = "info"
         ErrorAlert(message, title, icon)
      } else {

         setDataPreOrder({ pre_Order: true, _id: selectPreOrder._id, code: selectPreOrder.code })
         setDataReferenceOrderMicros(selectPreOrder.micros_reference)
         setName(selectPreOrder.client_name)

         if (selectPreOrder.client_ruc && selectPreOrder.client_dv) {
            setRuc(selectPreOrder.client_ruc)
            setDv(selectPreOrder.client_dv)
         } else {
            setRuc("0")
            setDv("0")
         }

         if (selectPreOrder.table_id && selectPreOrder.table_code) {
            setTableInfo({ table_id: selectPreOrder.table_id, table_code: selectPreOrder.table_code, table_name: selectPreOrder.table_name })
         } else {
            setTableInfo({ table_id: 0, table_code: "", table_name: "" })
         }

         setDataResponse(true)
         setOptionalData(true)
         setShow(false)


         //format json 
         for (let i = 0; i < selectPreOrder.groups.length; i++) {

            for (let s = 0; s < selectPreOrder.groups[i].segments.length; s++) {

               selectPreOrder.groups[i].segments[s].img = selectPreOrder.groups[i].segments[s].item.img
               selectPreOrder.groups[i].segments[s].item_id = selectPreOrder.groups[i].segments[s].item.item_id
               selectPreOrder.groups[i].segments[s].name = selectPreOrder.groups[i].segments[s].item.name
               selectPreOrder.groups[i].segments[s].segment_name = selectPreOrder.groups[i].segments[s].name
               selectPreOrder.groups[i].segments[s].cantidadItem = selectPreOrder.groups[i].segments[s].item.amount
               selectPreOrder.groups[i].segments[s].description = selectPreOrder.groups[i].segments[s].item.description
               selectPreOrder.groups[i].segments[s].tax = selectPreOrder.groups[i].segments[s].item.tax
            }
            selectPreOrder.groups[i].DetalleSegmentos = selectPreOrder.groups[i].segments
            selectPreOrder.groups[i].cantidadItem = selectPreOrder.groups[i].amount
            selectPreOrder.groups[i].amount_Original = selectPreOrder.groups[i].amount
            selectPreOrder.groups[i].descuento = selectPreOrder.groups[i].discount
         }


         message = "Orden consultada."
         title = "Operacion exitosa"
         icon = "success"
         setdataDiscountApply(selectPreOrder.discounts)
         setPreOrderInvoice(true);
         setTaxesArrayAll(selectPreOrder.taxes)
         ErrorAlert(message, title, icon)
         setCheckDeliveryOrder(selectPreOrder.order_type)
         setShoppingCart(selectPreOrder.groups)
         setShoppingCartCombos(selectPreOrder.combos)
         setInvoiceTotal(selectPreOrder.total)
         setInvoiceSubtotal(selectPreOrder.subtotal)
         setInvoiceTotalItbms(selectPreOrder.tax)

         setInvoiceTotalDiscount(selectPreOrder.discount)
         setEventHandler(1)
      }
   }

   useEffect(() => {
      (async () => {


         try {
            let business = ''
            let token = ''
            let branchoffice = ''
            if (localStorage.getItem("branchoffice") !== "" || localStorage.getItem("branchoffice") !== null || localStorage.getItem("branchoffice") !== undefined) {

               business = JSON.parse(localStorage.getItem("business"))
               branchoffice = JSON.parse(localStorage.getItem("branchoffice"))

            }

            //We get the branchoffice info 
            const response_branch = await BranchOfficeFetchAPI.getBranchOfficeByCode(business.code, branchoffice.code);
            //We set the business data 
            setBranchofficeData(response_branch.data.branchoffice);

         } catch (err) {
            setBranchofficeData({
               "ruc": "",
               "dv": "",
               "name": "",
               "ruc_type": "",
               "direction": "",
               "dgi_name": "",
            })
         }
      })();
   }, []);
   return (
      <>

         {actvPlus ?
            <button className='btn btn-outline-dark border-0' onClick={() => getAll()}>
               <i className="uil uil-book-reader icon-mobile"></i>
               <small className='fw-bold label-responsive hidden-label-sm'>
                  {title_modal}
               </small>
            </button> : <></>}


         {actvLite ? <div className=" m-1 bg-white border rounded ">
            <button className="btn btn-outline-primary  py-2 border-0" type="button" onClick={() => getAll()}>
               <i className="uil uil-book-reader buttons-label icon-mobile h3"></i>
               <span className='h5 fw-bold mx-1 d-block text-center'>   {title_modal} </span>
            </button>
         </div> : <></>}

         <Modal
            show={show}
            size="xl"
            centered
            onHide={() => setShow(false)}
         >
            <Modal.Body >
               <div className="d-flex justify-content-center border-bottom-0">
                  <h1 className="modal-title fw-bold fs-5">
                     <i className="uil uil-book-reader icon-mobile"></i>
                     {title_modal}
                  </h1>
                  {/* <button type="button" className="btn-close rounded"
                     onClick={() => (setShow(false), setProductChecked([]))}></button> */}
               </div>
               <div className='flex-container'>
                  {/*<div>
                     <p className='p-0  text-primary fw-bold my-1'>Ordenes Pendientes </p>

                  </div>*/}


                  <Tab.Container defaultActiveKey="pending" >

                     <Nav variant="underline" className="d-flex gap-3 justify-content-center horizontal-scroll border-bottom  pe-3 bg-white rounded-3 mx-2">
                        <li className="nav-sp-item">
                           <Nav.Link eventKey="pending" title="Preparacion" ><i class="uil uil-process"></i>Ordenes pendientes</Nav.Link>
                        </li>
                        <li className="nav-sp-item"  >
                           <Nav.Link eventKey="autorized" ><i class="uil uil-check-circle"></i>Ordenes por autorizar</Nav.Link>
                        </li>

                     </Nav>



                     <Tab.Content>
                        <Tab.Pane eventKey="pending">
                           <div className={
                              !preOrders.length
                                 ? "row d-block item2 border-inset bg-light-subtle border border-2 border-1 border-light-subtle rounded-3 list-discount-framer align-self-center overflow-auto w-100 d-flex align-items-center justify-content-center"
                                 : "row  item2 border-inset bg-light-subtle border border-2 border-1 border-light-subtle rounded-3 list-discount-framer align-self-center  w-100"
                           }
                           >

                              {/* Init Component */}
                              {!preOrders.length
                                 ? <Empty title="Vacío" msg="No hay ordenes pendientes" />
                                 : preOrders.map((order) => (
                                    <>
                                       {order.is_authorized == true ? <>
                                          <article className='col-12 col-xl-3 col-lg-4 col-md-6 my-1 list-pre-orders-inputs'>
                                             <input
                                                className="list-group-item-check " type="radio"
                                                autocomplete="off" value={JSON.stringify(order)} name="options-outlined" id={order.code + "preOrder"}
                                                onChange={(e) => (setSelectPreOrder(JSON.parse(e.target.value)))} />

                                             <label className="list-group-item rounded-3 mb-0 fw-bold text-truncate bg-body  rounded-2 p-1 "
                                                htmlFor={order.code + "preOrder"}
                                             >
                                                <i class="uil uil-invoice"></i>
                                                Order: {order.code}


                                                <div>
                                                   <ul class="list-group mt-0 bg-primary">
                                                      <li class="list-group-item fw-normal text-truncate border-opacity-10">Cliente: {order.client_name}</li>
                                                      <li class="list-group-item fw-normal border-opacity-10">Sucursal: {order.branchoffice.name}</li>
                                                      <li class="list-group-item fw-normal border-opacity-10">Máquina: {order.machine_code}</li>
                                                      {order.table_name != 0 && order.table_name != null && order.table_name != undefined ? <><li class="list-group-item fw-normal border-opacity-10">Origen: {order.table_name}</li></> : <></>}
                                                      <li class="list-group-item fw-normal border-opacity-10">{!order.machine_name ? 'No es caja' : order.machine_name} / {!order.user ? 'No es cajera' : order.user.name}</li>
                                                      <li class="list-group-item fw-normal border-opacity-10">Cantidad de productos: {(order.groups.length + order.combos.length)}</li>
                                                      <li class="list-group-item fw-normal border-opacity-10">Fecha {order.createdAt.slice(0, 10)}</li>
                                                      <li class="list-group-item fw-normal border-opacity-10">
                                                         <PDFDownloadLink className='pdf-viewer-sp' document={<DocPdf
                                                            doc_type={"price"} invoiceQR={"0"} invoiceDate={order.createdAt} cufe={"0"} auth_protocol={order.auth_protocol ? order.auth_protocol : "00000000"}
                                                            receptor={{
                                                               name: order.client_name,
                                                               ruc: order.client_ruc,
                                                               direction: "Panama",
                                                               dv: order.client_dv,
                                                            }}
                                                            emitter={branchOfficeData}
                                                            products={order.groups} invoiceSubtotal={order.subtotal} totalTaxes={[]}
                                                            totalCashReceived={order.total} invoiceTotal={order.total} invoiceTotalItbms={order.tax} paymentMethod={order.paymenthod}
                                                         />} >
                                                            <button className='btn btn-secondary-sp border border-sp' > Descargar precuenta </button>
                                                         </PDFDownloadLink></li>
                                                   </ul>

                                                   <div className='d-flex justify-content-end'>
                                                      <ModalDivisionPreOrder order={order} getAll={getAll} codeParthner={codeParthner} ></ModalDivisionPreOrder>
                                                      <button className='btn btn-danger bg-danger-subtle text-danger border-0 btn-sm btn-block m-1'
                                                         data-order={order.code}
                                                         onClick={async (e) => deletePreOrder(e.currentTarget.dataset.order)}>
                                                         <i className="uil uil-trash-alt"> Eliminar</i>
                                                      </button>
                                                   </div>
                                                </div>
                                             </label>

                                          </article></> : <></>}
                                    </>


                                 ))
                              }
                              {/* End Component */}
                           </div>


                           <small className='text-body-tertiary text-center'>
                              <i className="uil uil-info-circle me-1"></i>
                              Por favor, seleccione una orden para enviarla al carrito.
                           </small>

                           <div className='d-block mt-lg-1 mt-0'>
                              <div className='d-flex justify-content-between justify-content-lg-endn  mt-2'>

                                 <button className='btn bg-danger-subtle text-danger  border-0 fw-bold '
                                    onClick={() => setShow(false)}> Cancelar </button>
                                 <button className='btn bg-success-subtle text-success fw-bold border-0 ms-1 '
                                    onClick={() => (questionConfirmation())}>  Enviar al carrito </button>
                              </div>
                           </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="autorized">
                           <div className={
                              !preOrders.length
                                 ? "row d-block item2 border-inset bg-light-subtle border border-2 border-1 border-light-subtle rounded-3 list-discount-framer align-self-center overflow-auto w-100 d-flex align-items-center justify-content-center"
                                 : "row  item2 border-inset bg-light-subtle border border-2 border-1 border-light-subtle rounded-3 list-discount-framer align-self-center  w-100"
                           }
                           >

                              {/* Init Component */}
                              {!preOrders.length
                                 ? <Empty title="Vacío" msg="No hay ordenes pendientes" />
                                 : preOrders.map((order) => (
                                    <>
                                       {order.is_authorized == false ? <>
                                          <article className='col-12 col-xl-3 col-lg-4 col-md-6 my-1 list-pre-orders-inputs'>


                                             <label className="list-group-item rounded-3 mb-0 fw-bold text-truncate bg-body  rounded-2 p-1 "
                                                htmlFor={order.code + "preOrder"}
                                             >
                                                <i class="uil uil-invoice"></i>
                                                Order: {order.code}


                                                <div>
                                                   <ul class="list-group mt-0 bg-primary">
                                                      <li class="list-group-item fw-normal text-truncate border-opacity-10">Cliente: {order.client_name}</li>
                                                      <li class="list-group-item fw-normal border-opacity-10">Sucursal: {order.branchoffice.name}</li>
                                                      <li class="list-group-item fw-normal border-opacity-10">Máquina: {order.machine_code}</li>
                                                      {order.table_name != 0 && order.table_name != null && order.table_name != undefined ? <><li class="list-group-item fw-normal border-opacity-10">Origen: {order.table_name}</li></> : <></>}
                                                      <li class="list-group-item fw-normal border-opacity-10">{!order.machine_name ? 'No es caja' : order.machine_name} / {!order.user ? 'No es cajera' : order.user.name}</li>
                                                      <li class="list-group-item fw-normal border-opacity-10">Cantidad de productos: {(order.groups.length + order.combos.length)}</li>
                                                      <li class="list-group-item fw-normal border-opacity-10">Fecha {order.createdAt.slice(0, 10)}</li>
                                                   </ul>

                                                   <div className='d-flex justify-content-end'>

                                                      <button className='btn btn-danger bg-danger-subtle text-danger border-0 btn-sm btn-block m-1'
                                                         data-order={order.code}
                                                         onClick={async (e) => deletePreOrder(e.currentTarget.dataset.order)}>
                                                         <i className="uil uil-trash-alt"> Eliminar</i>
                                                      </button>
                                                      <button className='btn btn-success bg-success-subtle text-success border-0 btn-sm btn-block m-1'
                                                         data-order={order.code}
                                                         onClick={async () => updatePreOrder(order)}>
                                                         <i class="uil uil-check-circle">Autorizar</i>
                                                      </button>

                                                   </div>
                                                </div>
                                             </label>

                                          </article></> : <></>}
                                    </>


                                 ))
                              }
                              {/* End Component */}
                           </div>
                        </Tab.Pane>
                     </Tab.Content>


                  </Tab.Container>




               </div>


               {/* <div className='d-flex justify-content-between mt-3'>
                  <button className='btn btn-danger btn-lg' onClick={() => setShow(false)}> Cancelar </button>
                  <button className='btn btn-success btn-lg' onClick={() => (questionConfirmation())}> Enviar al carrito  </button>
               </div> */}

            </Modal.Body>
         </Modal>
      </>
   )
}

export default ModalPendingOrders