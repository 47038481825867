import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Tab, Button, Modal } from 'react-bootstrap';
//Components 
import Navbar from './Nav/NavBar';
import TransactionHistory from "../transactions/TransactionHistory";
import OrderDetails from '../Orders/OrderDetails';

//API
import { OrdersFetchAPI } from '../../api/Orders';
import { InformZFetchAPI } from '../../api/InformZ';
//Alerts
import ErrorAlert from '../Alerts/ErrorAlert';
import SuccessAlert from '../Alerts/SuccessAlert';
//Modals 
import ModalInformZOrders from './Modals/ModalInformZOrders';
//IMG
import empty from '../img/icons/empty.png'
import logo_sp from '../../assets/img/logos/icon-black.png';
//External libraries
import moment from 'moment-timezone';

// function to show the closures of the payments
const ClosuresPayment = () => {
    //Params 
    let { business_code, branchoffice_code } = useParams();
    let selectedBranch = branchoffice_code;
    //We get the user data 
    let user = localStorage.getItem('user');
    let token = localStorage.getItem('x-access-token');
    let branchoffice = localStorage.getItem('branchoffice')
    //States 
    const [userBusiness, setUserBusiness] = useState({});
    const [reload, setReLoad] = useState();

    const [modalShowFiler, setModalShowFilter] = useState(false)
    const [modalShowDetail, setModalShowDetail] = useState(false);
    const [typeRequest, setTypeRequest] = useState('transaction')
    const [order_type, setOrderType] = useState("temporal");
    const [minDate, setMinDate] = useState(new Date().toJSON().slice(0, 10));
    const [maxfilterDate, setmaxFilterDate] = useState('')
    const [branchOrdersData, setBranchOrdersData] = useState([]);
    const [temporal, setTemporal] = useState([]);
    const [branchAnaliticsData, setBranchAnaliticsData] = useState([]);
    const [listinformz, setListinformz] = useState([])
    const [detailOrder, setDetailOrder] = useState();

    //Totals states 
    const [subtotalOrders, setSubtotalOrders] = useState(0)
    const [taxOrders, setTaxOrders] = useState(0)
    const [totalOrders, setTotalOrders] = useState(0)

    //We get the actual date 
    const actualDate = moment(new Date()).format('YYYY-MM-DD')
    //We put the actual date in rank of all the day 
    const maxDate = actualDate + 'T23:59:59.000+00:00';

    //We validate if the user is logged in
    if (user === null || user === undefined || user === '' || user === 'null' || branchoffice === null || branchoffice === undefined || branchoffice === '' || branchoffice === 'null') {
        user = {
            name: 'No registrado',
            img: 'https://www.pngitem.com/pimgs/m/30-307416_profile-icon-png-image-free-download-searchpng-employee.png'
        }
    } else {
        branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
        user = JSON.parse(localStorage.getItem('user'))
    }


    //Functions to show and hide the modal 
    const handleCloseModalDetail = () => setModalShowDetail(false);
    const handleShowModalDetail = () => setModalShowDetail(true)

    //Function to handler the request
    const handlerActionRequest = (business_code, branchoffice_code, minDate, maxfilterDate) => {
        if (typeRequest === "transaction") {
            getTransactionsByBranchOffice(business_code, branchoffice_code, minDate, maxfilterDate)
        } else {
            getInformZ(minDate, maxfilterDate);
        }
    }
    //We get the transactions by branchoffice 
    const getTransactionsByBranchOffice = async (business_code, branchoffice_code, minDate, maxDate) => {
        let codeParthner = localStorage.getItem('codeParthner');
        let bussinesCode = business_code
        let branchCode = branchoffice_code

        if (codeParthner !== null && codeParthner !== undefined && codeParthner !== '') {
            codeParthner = JSON.parse(localStorage.getItem('codeParthner'))
            bussinesCode = codeParthner.business_code
            branchCode = codeParthner.branchoffice_code
        }

        console.log("desde: " + minDate)
        let dataMax = ""
        if (maxDate == "") {
            dataMax = new Date().toISOString()
            console.log(dataMax)
        } else {
            dataMax = (maxDate.split('T'))[0]
        }
        console.log("hasta: " + dataMax)
        try {
            //We show the loading data alert 
            //LoadingAlert()
            //We call the orders of the branchoffice 
            const response = await OrdersFetchAPI.getTransactionsByBranchOfficePaymentMethodbyUser(bussinesCode, branchCode, minDate + "T00:00:00.000", dataMax + "T23:59:59.000", 0, user.id, token);
            console.log(response);


            let ordersFilter = response.data.orders.filter(({ inform_z }) => inform_z == true)
            let total = 0
            let subtotal = 0
            let tax = 0
            ordersFilter.forEach(order => {
                subtotal = (subtotal + parseFloat(order.subtotal))
                tax = (tax + parseFloat(order.tax))
            });

            setSubtotalOrders(subtotal)
            setTaxOrders(tax)
            setTotalOrders((subtotal + tax))
            setBranchOrdersData(ordersFilter);
            setTemporal(ordersFilter);
            setBranchAnaliticsData([response.data.analitics]);
            //We show the success alert 
            SuccessAlert()

        } catch (err) {
            //We show the error alert 
            ErrorAlert('No tiene información')
            console.log(err);
        }
    }


    //Function to search any result on the search filter 
    function handleChangeFiltro(event) {
        let resultFilter = [];
        if (event.target.value == "") {
            console.log(event.target.value);
            setBranchOrdersData(temporal);
        } else {
            for (
                let recorrer = 0;
                recorrer < branchOrdersData.length;
                recorrer++
            ) {
                const examinar = branchOrdersData[recorrer];

                const info = JSON.stringify(examinar);
                const resultado = info
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase());

                if (resultado == true) {
                    resultFilter.push(examinar);

                    setBranchOrdersData(resultFilter);
                    console.log(resultFilter);
                }
            }
        }
    }


    //Function to get inform  z by date
    const getInformZ = async (dateinit, maxdate) => {
        let dateMax = ""
        if (maxDate == "") {
            let date = new Date().toISOString().slice(0, 10)
            dateMax = date
        } else {
            let date = maxdate.split('+');
            dateMax = date[0]
        }
        

        let codeParthner = localStorage.getItem('codeParthner');
        let bussinesCode = business_code
        let branchCode = branchoffice_code

        if (codeParthner !== null && codeParthner !== undefined && codeParthner !== '') {
            codeParthner = JSON.parse(localStorage.getItem('codeParthner'))
            bussinesCode = codeParthner.business_code
            branchCode = codeParthner.branchoffice_code
        }
        try {
            const response = await InformZFetchAPI.getinformZ(branchCode, bussinesCode, token, dateinit, dateMax, user.user_code)
            setListinformz(response.data.informsz)
            console.log("informz" + response.data.informsz)
        } catch (err) {
            console.log(err)
        }
    }


    //Function to populate the list orders 
    const listOrders = () => {
        const listOrders = branchOrdersData.map((data) => (
            <>
                <div onClick={async () => {
                    await getOrderById(data._id);
                    handleShowModalDetail();
                }}>
                    <TransactionHistory data={data} />
                </div>

            </>
        ));
        //We check if the list orders has data 
        if (listOrders.length === 0) {
            return EmptyCard()
        } else {
            return (listOrders)
        }
    }

    //Function to gen the empty card to the transaction history 
    const EmptyCard = () => {
        return (
            <div className="m-3 w-auto ">
                <div className="border-history d-flex justify-content-md-center py-4 px-4 " aria-current="true">
                    <div className="d-flex w-100 justify-content-center align-items-center  flex-column">
                        <img src={empty} alt="Vacio" width="35px" height="35px" />
                        <div className='text-center mt-3'>
                            <h5>Vacío</h5>
                            <h6 className="mb-0 text-secondary fw-bold">No hay transacciones </h6>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //Function to get order by id (order_id)
    const getOrderById = async (order_id) => {
        try {
            const response = await OrdersFetchAPI.getOrderById(order_id);
            setDetailOrder(response.data.order)
            console.log(detailOrder)
            setOrderType("permanent")
        } catch (err) {
            setDetailOrder({})
            setOrderType("permanent")
            console.log(err)
        }
    }

    //Function to populate the order details (modal)
    const detailOrderList = () => {
        return detailOrder === undefined ? "" : <OrderDetails pos={true} orderData={detailOrder} order_type={order_type} business_data={userBusiness} setLoad={setReLoad} />
    }


    //Hook to charge data when the page is loaded 
    useEffect(() => {
        (async () => {
            try {
                //We search the branch orders 
                getTransactionsByBranchOffice(business_code, selectedBranch, actualDate, maxDate)
                getInformZ(actualDate, maxDate);
            } catch (err) {
                console.log(err);
            }
        })();
    }, []);


    return (
        <>
            <header className="py-1 px-1  border-bottom  bg-white fixed-top">
                <nav className="border-bottom border-3 bg-white px-1 fixed-top ">
                    <div className='d-flex  align-items-center justify-content-between'>
                        <div>
                            <a href="javascript:location.reload()"
                                className="d-flex align-items-center 
                            flex-fill bd-highlight 
                            text-dark text-decoration-none">
                                <img
                                    className="navbar-logo-post"
                                    src={logo_sp} alt="Logo Smart Pay" />
                                <span className="h5 pt-2 me-3 fw-bold align-self-center text-uppercase">POS</span>
                            </a>
                        </div>
                        <Navbar />
                    </div>
                </nav>
            </header>

            <div className='container'>
                <div className='smart-pay-main'>
                    <div className='d-flex justify-content-center'>
                        <h2 className='fs-2 fw-bold'>Cierres </h2>
                    </div>
                </div>

                {/* Tabs */}
                <Tabs defaultActiveKey="Cierre" variant="pills" className='rounded d-flex justify-content-center align-item-center' >
                    {/* Tab of closed history */}
                    <Tab eventKey="Cierre" title="Historico cierre" >
                        <div className="row justify-content-center">
                            <div className='col-10 col-md-12 col-xl-4 col-lg-12 ' >
                                <h4 className='text-center mt-2 fw-bold text-dark'> Historico de cierre </h4>

                                <div className='d-flex justify-content-between'>
                                    <Button className='btn btn-primary m-2 w-100' onClick={() => (setModalShowFilter(true), setTypeRequest('inform'))}>Filtrar fecha</Button>
                                </div>
                                {/** The filter */}
                                <div className="w-auto bg-light px-1 py-2 border rounded my-2">
                                    <div className="border-0" >
                                        {/* <i className="uil uil-money-stack"></i> */}
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="mb-0 text-secondary fw-bold">Buscador:</h6>
                                            <div className='border-0 fw-bold fs-3'>
                                                <input
                                                    spellcheck="false"
                                                    className="form-control"
                                                    type="text"
                                                    step="any"
                                                    onChange={handleChangeFiltro}
                                                    placeholder="Buscar orden "
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* The list of the closes */}
                                <div className="item2 p-2 border">
                                    {listinformz.map((inform) => (
                                        <>

                                            <ModalInformZOrders inform={inform}></ModalInformZOrders>

                                        </>
                                    ))}

                                </div>
                            </div>
                        </div>

                    </Tab>
                    {/* Tab of transactions */}
                    <Tab eventKey="Transacciones" title="Historial transacciones">
                        <div className="row justify-content-center">
                            <div className='col-10 col-md-12 col-xl-4 col-lg-12 ' >
                                <h4 className='text-center mt-2 fw-bold text-dark'> Historial de transacciones </h4>
                                <div className='d-flex justify-content-between'>
                                    <Button className='btn btn-primary m-2 w-100' onClick={() => (setModalShowFilter(true), setTypeRequest('transaction'))}>Filtrar fecha</Button>
                                </div>
                                {/** The filter */}
                                <div className="w-auto bg-light px-1 py-2 border rounded my-2">
                                    <div className="border-0" >
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="mb-0 text-secondary fw-bold">Buscador:</h6>
                                            <div className='border-0 fw-bold fs-3'>
                                                <input
                                                    spellcheck="false"
                                                    className="form-control"
                                                    type="text"
                                                    step="any"
                                                    onChange={handleChangeFiltro}
                                                    placeholder="Buscar orden "
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/** The list of the transactions */}
                                <div className="item2 p-2 border">
                                    {listOrders()}
                                </div>
                            </div>
                        </div>
                    </Tab>

                </Tabs>
            </div>


            {/* Modal of filters order */}
            <Modal show={modalShowFiler} onHide={() => setModalShowFilter(false)
            }>
                <Modal.Header closeButton>
                    <Modal.Title>Filtro de cierre</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mt-3'>
                        <div className="login-form needs-validation">
                            <div className="row">

                                <div className="col-12">
                                    <div className="m-3">
                                        <h6><strong>Fecha desde:</strong></h6>
                                        <input
                                            type="date" name="date" id="" className="control mt-3 w-100" onChange={(e) => setMinDate(e.target.value)} />
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="m-3">
                                        <h6><strong>Fecha Hasta:</strong></h6>
                                        <input
                                            type="date" name="date" id="" className="control mt-3 w-100" onChange={(e) => setmaxFilterDate(e.target.value)} />
                                    </div>
                                </div>

                            </div>
                            <div>
                                <button className="btn btn-success rounded mt-3 w-100" id="btn-analitycs" onClick={() => (setModalShowFilter(false), handlerActionRequest(business_code, branchoffice_code, minDate, maxfilterDate))}>Aceptar</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Modal detail order */}
            <Modal show={modalShowDetail} onHide={handleCloseModalDetail} >
                <Modal.Header closeButton>
                    <Modal.Title>Detalles</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {detailOrderList()}
                </Modal.Body>
            </Modal>

        </>
    );
}

export default ClosuresPayment;
