import React from 'react'
//img
import empty from '../img/icons/empty.png'

function Empty(props){
   return (
      <>
         <div className="d-flex justify-content-center align-items-center opacity-50">
            <div className="border-history d-flex justify-content-md-center px-4 " aria-current="true">
               <div className="d-flex w-100 justify-content-center align-items-center  flex-column">
                   <img src={empty} className='' alt="Vacio" width="40px" height="40px" /> 
                  <div className='text-center text-body-secondary mt-3'>
                     <h5 className='text-body-secondary fw-bold fs-label-1 '>{props.title}</h5>
                     <p className="mb-0 text-body-secondary fw-bold fs-label-1  ">
                        ¡{props.msg}!
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default Empty