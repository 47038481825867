import axios from 'axios'; 



const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {'Content-Type': "application/json"}, 
});


export class PrinterFetchAPI{ 

    static async getPrinter(token, machine_id){ 
        const res = await server(`/printer_per_machine/${machine_id}`, {
            method: 'get', 
            headers: {
                "x-access-token": token
            }
        }); 
        return res
    }

    static async updateIpPrinter(token, machine_id, printer_id, ip_Update){ 
        const res = await server(`/printer_per_machine/${machine_id}/${printer_id}`, {
            method: 'put',
            data: {ip: ip_Update},
            headers: {
                "x-access-token": token
            }
        }); 
        return res
    }



    //function local
    /*static async printOrder(data, endpoint) {
        console.log(endpoint);
        const res = await axios.post(`http://${endpoint}:4000/smartpay`, { ruta: "localhost:8080/imprimir2", body: data }, {
            headers: { 'Content-Type': "application/json" },
        });
        return res
    }*/


    // request print order android local
    /*static async printOrder(data, endpoint) {

        const printInfo = JSON.stringify({ruta:`http://${endpoint}:4000/smartpay`,  body: { ruta: "http://localhost:9090/imprimir", body: data }});
        let response = await axios.post("http://localhost:9000/tarjetas", printInfo);
        return response;
    }*/

    // request print preorder android local
    /*static async printPreOrder(data, endpoint) {

        const printInfo = JSON.stringify({ruta:`http://${endpoint}:4000/smartpay`,  body: { ruta: "http://localhost:9090/impresionprecuenta", body: data }});
        let response = await axios.post("http://localhost:9000/tarjetas", printInfo);
        return response;
    }*/


    //---------------------------------------------------------------------------------------------------------------------------------------------
 
    //request print preorder windows local node
    /*static async printPreOrder(data, endpoint) {
        const payment = {ruta: "http://localhost:9090/impresionprecuenta", body: data };
        const res = await server(`http://localhost:4000/smartpay`, {
            method: "post",
            data: payment,

        });
        return res
    }*/

    //request print order windows local node
    /*static async printOrder(data, endpoint) {
        const payment = {ruta: "http://localhost:9090/imprimir", body: data };
        const res = await server(`http://localhost:4000/smartpay`, {
            method: "post",
            data: payment,

        });
        return res
    }*/


       //request print order windows local c#
       static async printOrder(data, endpoint) {
        const payment = JSON.stringify(data);
        let response = await axios.post("http://localhost:9090/impresion", payment);

        let jsonResponse = {data: response}
        console.log(jsonResponse);
        console.log(response);
        return jsonResponse;
    }

    static async printOrderMicrosKiosk(data) {
        const dataAll = JSON.stringify(data);
        let response = await axios.post("http://localhost:9472/api/localrequest", dataAll);

        let jsonResponse = {data: response}
        return jsonResponse;
    }

    static async printPreOrder(data, endpoint) {
        const payment = JSON.stringify(data);
        let response = await axios.post("http://localhost:9090/impresion", payment);

        let jsonResponse = {data: response}
        console.log(jsonResponse);
        console.log(response);
        return jsonResponse;
    }


    //===================================================================================printer old

    //request print order windows local c#
    /*static async printOrder(data, endpoint) {
        const payment = JSON.stringify(data);
        let response = await axios.post("http://localhost:9090/imprimir", payment);

        let jsonResponse = {data: response}
        console.log(jsonResponse);
        console.log(response);
        return jsonResponse;
    }*/

     //request print preorder windows local c#
    /* static async printPreOrder(data, endpoint) {
        const payment = JSON.stringify(data);
        let response = await axios.post("http://localhost:9090/impresionprecuenta", payment);

        let jsonResponse = {data: response}
        console.log(jsonResponse);
        console.log(response);
        return jsonResponse;
    }*/
}