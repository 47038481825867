
//API 
import { EvertechFetchAPI } from "../api/Evertech";

// Funcion to settle with evertech
const evertechSettle = async (data) => {

    try {
        //We make the settle operation
        const res = await EvertechFetchAPI.makeSettle(data);
        //We check the response of the server in base of the response of the pin pad
        if (res && res.data !== undefined) {
            //If the response exists, we check the approval code 
            if (res.data.approval_code !== "00") {
                console.log("Approval code error on settle");
                return ({
                    status: 400,
                    data: res.data
                });
            } else {
                return res;
            }
        }
        return res;
    } catch (error) {
        let parsedError;
        console.log("Error caught in evertechSale:", error);

        // If the error is already an object, no need to parse
        if (typeof error === 'object' && error !== null) {
            parsedError = error;
        } else {
            // We try to convert the error into an object
            try {
                parsedError = JSON.parse(error);
            } catch (e) {
                // If parsing fails, return a generic error object
                parsedError = { status: 500, message: error };
            }
        }

        // Throw the formatted error object
        if (parsedError.status && parsedError.data) {
            return parsedError;
        } else if (error.response) {
            throw {
                status: 400,
                data: error.response.data
            };
        } else {
            throw error;
        }
    }
}

// Funcion to refund with evertech
const evertechRefund = async (data) => {
    try {
        //We make the refund operation
        const res = await EvertechFetchAPI.makeRefund(data);
        //We check the response of the server in base of the response of the pin pad
        if (res && res.data !== undefined) {
            //If the response exists, we check the approval code 
            if (res.data.approval_code !== "00") {
                console.log("Approval code error on refund");
                return ({
                    status: 400,
                    data: res.data
                });
            } else {
                return res;
            }
        }
    } catch (error) {
        let parsedError;
        console.log("Error caught in evertechRefund:", error);

        // If the error is already an object, no need to parse
        if (typeof error === 'object' && error !== null) {
            parsedError = error;
        } else {
            // We try to convert the error into an object
            try {
                parsedError = JSON.parse(error);
            } catch (e) {
                // If parsing fails, return a generic error object
                parsedError = { status: 500, message: error };
            }
        }
        // Throw the formatted error object
        if (parsedError.status && parsedError.data) {
            return parsedError;
        } else if (error.response) {
            throw {
                status: 400,
                data: error.response.data
            };
        } else {
            throw error;
        }
    }
}

// Funcion to void with evertech
const evertechVoid = async (data) => {
    try {
        //We make the void operation
        const res = await EvertechFetchAPI.makeVoid(data);
        //We check the response of the server in base of the response of the pin pad
        if (res && res.data !== undefined) {
            //If the response exists, we check the approval code 
            if (res.data.approval_code !== "00") {
                console.log("Approval code error on void");
                return ({
                    status: 400,
                    data: res.data
                });
            } else {
                return res;
            }
        }
    } catch (error) {
        let parsedError;
        console.log("Error caught in evertechVoid:", error);

        // If the error is already an object, no need to parse
        if (typeof error === 'object' && error !== null) {
            parsedError = error;
        } else {
            // We try to convert the error into an object
            try {
                parsedError = JSON.parse(error);
            } catch (e) {
                // If parsing fails, return a generic error object
                parsedError = { status: 500, message: error };
            }
        }
        // Throw the formatted error object
        if (parsedError.status && parsedError.data) {
            return parsedError;
        } else if (error.response) {
            throw {
                status: 400,
                data: error.response.data
            };
        } else {
            throw error;
        }
    }
}

//Function to refund a sale with evertech cash sale 
const evertechCashRefund = async (data) => {
    try {
        //We make the refund operation
        const res = await EvertechFetchAPI.makeRefundCashSale(data);
        //We check the response of the server in base of the response of the pin pad
        if (res && res.data !== undefined) {
            //If the response exists, we check the approval code 
            if (res.data.approval_code !== "00") {
                console.log("Approval code error on refund");
                return ({
                    status: 400,
                    data: res.data
                });
            } else {
                return res;
            }
        }
    } catch (error) {
        let parsedError;
        console.log("Error caught in evertechRefund:", error);

        // If the error is already an object, no need to parse
        if (typeof error === 'object' && error !== null) {
            parsedError = error;
        } else {
            // We try to convert the error into an object
            try {
                parsedError = JSON.parse(error);
            } catch (e) {
                // If parsing fails, return a generic error object
                parsedError = { status: 500, message: error };
            }
        }
        // Throw the formatted error object
        if (parsedError.status && parsedError.data) {
            return parsedError;
        } else if (error.response) {
            throw {
                status: 400,
                data: error.response.data
            };
        } else {
            throw error;
        }
    }
}

//Function to refund a sale with evertech ebt sale 
const evertechEBTRefund = async (data) => {
    try {
        //We make the refund operation
        const res = await EvertechFetchAPI.makeEBTOperation(data);
        //We check the response of the server in base of the response of the pin pad
        if (res && res.data !== undefined) {
            //If the response exists, we check the approval code 
            if (res.data.approval_code !== "00") {
                console.log("Approval code error on refund");
                return ({
                    status: 400,
                    data: res.data
                });
            } else {
                return res;
            }
        }
    } catch (error) {
        let parsedError;
        console.log("Error caught in evertechRefund:", error);

        // If the error is already an object, no need to parse
        if (typeof error === 'object' && error !== null) {
            parsedError = error;
        } else {
            // We try to convert the error into an object
            try {
                parsedError = JSON.parse(error);
            } catch (e) {
                // If parsing fails, return a generic error object
                parsedError = { status: 500, message: error };
            }
        }
        // Throw the formatted error object
        if (parsedError.status && parsedError.data) {
            return parsedError;
        } else if (error.response) {
            throw {
                status: 400,
                data: error.response.data
            };
        } else {
            throw error;
        }
    }
}
// Function to handle payment operations
export const PaymentOperations = async (props) => {

    //We do the payment operation depending of the integration type
    switch (props.integrationType) {
        case "Evertech":
            let iDefaultHostPort, DefaultHostIP, sDefaultTID, iscashback, tips, force_duplicate, special_account, manual_entry_indicator, payment_host, basereducedtax, statetax, reducedtax, citytax;
            // We get the values of the parameters
            props.params.forEach((param) => {
                if (param.name === "iDefaultHostport") iDefaultHostPort = param.value;
                if (param.name === "DefaultHostIP") DefaultHostIP = param.value;
                if (param.name === "sDefaultTID") sDefaultTID = param.value;
                if (param.name === "iscashback") iscashback = param.value;
                if (param.name === "force_duplicate") force_duplicate = param.value;
                if (param.name === "payment_host") payment_host = param.value;
                if (param.name === "manual_entry_indicator") manual_entry_indicator = param.value;
            });

            //If we get the taxes we map them to the variables
            if (props.taxes) {
                props.taxes.forEach((tax) => {
                    if (tax.tax === "basereducedtax") basereducedtax = Math.round(tax.value * 100) || 0;
                    if (tax.tax === "statetax") statetax = Math.round(tax.value * 100) || 0;
                    if (tax.tax === "reducedtax") reducedtax = Math.round(tax.value * 100) || 0;
                    if (tax.tax === "citytax") citytax = Math.round(tax.value * 100) || 0;
                });
            } else {
                //If we don't get the taxes we set them to 0
                basereducedtax = 0;
                statetax = 0;
                reducedtax = 0;
                citytax = 0;
            }
            //We set the special account depending of the payment type
            special_account = {
                "Visa": "VC",
                "Mastercard": "MC",
                "Amex": "AX",
                "Cash": "IC", 
                "EBT": "EB",
            }[props.payment] || "NONE";

            // We get the values data for the payment operation
            let data = {
                sECRStationID: props.sECRStationID,
                sCashierID: props.sCashierID,
                sDefaultHostIP: DefaultHostIP,
                iDefaultHostPort: iDefaultHostPort,
                sDefaultTID: sDefaultTID,
                sRefNumber: props.sRefNumber
            };
            // We do the payment operation depending of the operation type
            switch (props.operationType) {
                case "settle":
                    return evertechSettle(data);
                case "refund":

                    //We add some parameters to the data
                    data.iscashback = iscashback;
                    data.cashback = 0;
                    data.tips = Math.round(props.tips *100)|| 0;
                    data.force_duplicate = force_duplicate;
                    data.special_account = special_account;
                    data.manual_entry_indicator = manual_entry_indicator;
                    data.payment_host = payment_host;
                    data.basereducedtax = basereducedtax;
                    data.statetax = statetax;
                    data.reducedtax = reducedtax;
                    data.citytax = citytax;
                    data.receipt_output = "both";
                    data.total = props.total;

                    //If the payment is cash we do a cash refund
                    if (props.payment === "Cash") {
                        return evertechCashRefund(data);
                    }  else if (props.payment === "EBT") {
                        data.sTransType = "EBT";
                        data.EBTTransType = "FOODREFUND";
                        //If the payment is EBT we do an EBT refund
                        return evertechEBTRefund(data);
                    }else {
                        //We do the refund operation
                        return evertechRefund(data);
                    }

                case "void":
                    //We add some parameters to the data
                    data.referencedVoid = props.referencedVoid;
                    delete data.sRefNumber;
                    console.log("data to void", data);
                    return evertechVoid(data);
                default:
                    return { status: 400, data: "Invalid operation type" };
            }

        default:
            return { status: 400, data: "Invalid integration type" };

    }
}