import React from 'react'
import Swal from 'sweetalert2'; 

function AlertSmallInfo() {


      const Toast = Swal.mixin({
          toast: true,
          position: 'top-start',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
      
    
    return (<></>
        //We show a loading alert 
        // Toast.fire({
        //     icon: 'info',
        //     title: 'Escanea el UPC del producto.ioolkl'
        //   })
    )
}

export default AlertSmallInfo