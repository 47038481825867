import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import ErrorAlert from '../Alerts/ErrorAlert';
import LoadingAlert from '../Alerts/LoadingAlert';
import Swal from 'sweetalert2';


function ModalParthners({ parthners, logo, selectRestaurant, setResetData, calculateInvoiceTotals, shoppingCart, shoppingCartCombos, codeParthner, setShoppingCart, setShoppingCartCombos }) {
    const [show, setShow] = useState(false);

    const selectAction = (parthner) => {
        selectRestaurant(parthner)
        setShow(false)
        setResetData(1)
        dataStorageSet()
    }

    //save shoppingcart in localstorage
    const dataStorageSave = (parthner) => {
        if ((shoppingCart.length + shoppingCartCombos.length) > 0) {
            let shopingcartBackup = localStorage.getItem('shopingcartBackup')
            let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
            if (shopingcartBackup !== null && shopingcartBackup != undefined && shopingcartBackup != '') {
                let arrayShopingCart = JSON.parse(shopingcartBackup)

                let statusFind = false
                arrayShopingCart.find((data) => {
                    if (data.branchoffice_id == branchoffice.id) {
                        data.shoppingCart = shoppingCart
                        data.shoppingCartCombos = shoppingCartCombos
                        statusFind = true
                    }
                })

                if (statusFind == false) {
                    arrayShopingCart.push({ branchoffice_id: branchoffice.id, shoppingCart: shoppingCart, shoppingCartCombos: shoppingCartCombos })
                }

                localStorage.setItem('shopingcartBackup', JSON.stringify(arrayShopingCart))
            } else {
                let dataShopingCart = { branchoffice_id: branchoffice.id, shoppingCart: shoppingCart, shoppingCartCombos: shoppingCartCombos }
                let arrayshopingcart = [dataShopingCart]
                localStorage.setItem('shopingcartBackup', JSON.stringify(arrayshopingcart))
            }

            selectAction(parthner)
        } else {
            selectAction(parthner)
        }
    }


    const dataStorageSet = () => {//save data shopingcart parthners

        let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
        let shopingcartBackup = localStorage.getItem('shopingcartBackup')

        if (shopingcartBackup !== null && shopingcartBackup != undefined && shopingcartBackup != '') {
            let arrayShopingCart = JSON.parse(shopingcartBackup)
            arrayShopingCart.find((data) => {
                if (data.branchoffice_id == branchoffice.id) {

                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: "btn btn-success",
                            cancelButton: "btn btn-danger"
                        },
                        buttonsStyling: false
                    });
                    swalWithBootstrapButtons.fire({
                        title: "Hay productos en el carrito",
                        text: "Desea recuperarlos",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Si, recuperar",
                        cancelButtonText: "No, eliminar",
                        reverseButtons: true
                    }).then((result) => {
                        if (result.isConfirmed) {
                            swalWithBootstrapButtons.fire({
                                title: "Productos recuperados",
                                text: "",
                                icon: "success"
                            });

                            setShoppingCart(data.shoppingCart)
                            setShoppingCartCombos(data.shoppingCartCombos)
                            calculateInvoiceTotals(data.shoppingCart, data.shoppingCartCombos)

                            data.shoppingCart = []
                            data.shoppingCartCombos = []
                        } else if (
                            /* Read more about handling dismissals below */
                            result.dismiss === Swal.DismissReason.cancel
                        ) {
                            swalWithBootstrapButtons.fire({
                                title: "Productos eliminados",
                                text: "",
                                icon: "warning"
                            });
                            data.shoppingCart = []
                            data.shoppingCartCombos = []
                        }
                    });
                }
            })
            let Datafilter = arrayShopingCart.filter((data) => data.branchoffice_id != branchoffice.id)
            localStorage.setItem('shopingcartBackup', JSON.stringify(Datafilter))
        }
    }








    return (
        <>

            <button className='btn btn-outline-dark border-0' onClick={() => setShow(true)}>
                <i class="uil uil-restaurant"></i>
                <small className='fw-bold label-responsive hidden-label-sm'>
                    Restaurantes
                </small>
            </button>

            <Modal
                show={show}
                size="xl"
                centered
                onHide={() => setShow(false)}
            >
                <Modal.Body >
                    <div className="d-flex justify-content-center border-bottom-0">
                        <h1 className="modal-title fw-bold fs-5">
                            <i class="uil uil-restaurant"></i>
                            Restaurantes
                        </h1>
                    </div>
                    <div className='flex-container'>
                        <div className='mt-5 row justify-content-center align-items-center'>

                            {parthners.map((parthner) => (<>
                                <div className="col-4 col-xs-3 col-sm-3 col-md-3 col-lg-2 border-product" onClick={() => (dataStorageSave(parthner))} >
                                    <div className="card border-0 shadow-sm rounded-sp ">
                                        <img src={parthner.logo} className="card-img-pre-acoount" alt="Product" loading='lazy' />

                                        <div className="card-body">
                                            <h5 className="card-title text-center fw-bold text-truncate text-dark"> {parthner.parthner_name}</h5>
                                        </div>
                                    </div>
                                </div></>))}
                        </div>

                        <small className='text-body-tertiary text-center mt-3'>
                            <i className="uil uil-info-circle me-1"></i>
                            Por favor, selccione un restaurante para continuar
                        </small>

                        <div className='d-block mt-lg-1 mt-0'>
                            <div className='d-flex justify-content-between justify-content-lg-endn  mt-2'>
                                <button className='btn bg-primary-subtle text-primary  border-0 fw-bold '
                                    onClick={() => setShow(false)}><i class="uil uil-arrow-left"></i>  Volver </button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalParthners