import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

//Components
import LandingPage from './components/_index';

import NoMatch from "./components/layout/NoMatch";

import Login from './components/login/Login';
import LoginCashier from './components/login/LoginCashier';


import Transactions from './components/transactions/Transactions';
import ListOrders from "./components/lists_orders/ListOrders";

//API 
import { AuthFetchAPI } from "./api/Auth";

//context   
import UserContext from "./context/UserContext";
import MachineList from './components/cahsier/MachineList';
import CashierReport from "./components/cahsier/CashierReport";
import OrderCustomers from "./components/order_customers/CustomersOrders";
import PaymentPost from "./components/PaymentPost/PaymentPost";
import TableOrder from "./components/table_order/TableOrder";
import PageConfirmation from "./components/table_order/PageConfirmation";
import OrderList from "./components/table_order/OrdersList";
import AnaliticsPayment from "./components/PaymentPost/AnaliticsPayment";
import ListOrdersStatus from "./components/Status_orders/ListOrdersStatus";
import ClosuresPayment from "./components/PaymentPost/ClosuresPayment";


//CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/js/bootstrap.esm'
import './App.css';
import './index.css';


//CSS Main BETA 
import './assets/css/main.css';

//Icons
import 'bootstrap-icons/font/bootstrap-icons.css'

///auth/token
function App() {
  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined
  });
  const checkLogin = async () => {
    let token = localStorage.getItem('x-access-token');

    //esta variable sme ayuda a saber la url actual y separarlas por / para saber su posicion actual
    let urlActual = window.location.href.split('/')
    let posicionUrl = urlActual[(urlActual.length - 2)]




    //We check if the user has the token 
    if (token == null) {
      localStorage.setItem('x-access-token', '');
      token = ''
    }/*else if(posicionUrl == "login" && token != ''){
      window.location.href = "/request_pay";
    }else if(token == '' && posicionUrl != "login"){
      window.location.href = "/login";
    }*/

    try {
      const tokenres = await AuthFetchAPI.validtoken(token);
      // console.log(tokenres)
      if (tokenres.data === true) {

        const user = await AuthFetchAPI.me(token);
        setUserData({ token: token, user: user.data })
      }
    } catch (error) {
      window.location.href = "/login";
    }
  }

  useEffect(() => {
    (async () => {
      try {
        // await checkLogin()
      } catch (err) {
        console.log(err)
      }
    })()
  }, [])

  return (
    <>
      <Router>
        <UserContext.Provider value={{ userData, setUserData }}>
          <Routes>
            {/* Index Main Menu  */}
            <Route exact path="/" element={<LandingPage />} />
            {/* Logins */}
            <Route path="/machines/login/:business_code" element={<Login />} />
            {/**Sub login para post */}
            <Route path="/machine/cashier/login/:business_code/:branchoffice_code" element={<LoginCashier />} />
            {/**Sub login para post */} 
            
            <Route path="/machine/cashier/login/:business_code/:branchoffice_code/:machine_code" element={<LoginCashier />} />
            <Route path="/list_orders/:business_code/:branchoffice_code" element={<ListOrders />} />
            <Route path="/orders_customers/:business_code/:branchoffice_code" element={<OrderCustomers />} />

            {/**Transaction of admins */}
            <Route path="/transactions/:business_code" element={<Transactions />} />

            {/**Transaction of cashiers  */}
            <Route path="/cashier/:business_code/:branchoffice_code" element={<MachineList />} />
            <Route path="/cashier/report/:business_code/:branchoffice_code" element={<CashierReport />} />

            {/* Payment Closures */}
            <Route path="/payment/closures/:business_code/:branchoffice_code" element={<ClosuresPayment />} />

            {/* Payment Post */}
            <Route path="/payments/:business_code/:branchoffice_code" element={<PaymentPost />} />



            {/*Register Table orders*/}
            <Route path="/business/:business_code/branchoffice/:branch_code/table/:table_num" element={<TableOrder />}></Route>
            <Route path="/confirmation/business/:business_code/branchoffice/:branch_code/table/:table_num/transaction/:transaction_code" element={<PageConfirmation />}></Route>
            <Route path="/orders/business/:business_code/branchoffice/:branch_code/list" element={<OrderList />}></Route>
            <Route path="/payment/report/:business_code/:branchoffice_code" element={<AnaliticsPayment />} />

            {/*List order by status and update orders status*/}
            <Route path="/list/status/:business_code/:branchoffice_code" element={<ListOrdersStatus />}></Route>
            {/* No Match link */}
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </UserContext.Provider>
      </Router>
    </>
  );
}

export default App;
