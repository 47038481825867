import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class RequestAlohaFetchAPI {

    static async requestLocalIp(data, token) {
        const res = await server(`http://localhost:9472/api/localrequest`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }
  
}