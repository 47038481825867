import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';

import { ListGroup } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

function Modalclient({ getClientsByBusiness, setRuc, setName, setDv, setDataResponse }) {
    const [showModal, setShowModal] = useState(false)
    const [clients, setClients] = useState([])
    const [searchedClients, setSearchedClients] = useState("")
    const [searchTerm, setSearchTerm] = useState('');
    const [deleteMode, setDeleteMode] = useState(false)
    const [orderSort, setOrderSort] = useState('asc')
    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');


    const getClients = async () => {
        let clients_data = await getClientsByBusiness()
        let dataFilter = clients_data.filter(({ is_validated }) => is_validated == 1)

        sortJSON(dataFilter, "name")
        setClients(dataFilter)
        setSearchedClients(dataFilter)
    }


    function sortJSON(data, key) {
        return data.sort(function (a, b) {
            var x = a[key],
                y = b[key];

            if (orderSort === 'asc') {
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }

            if (orderSort === 'desc') {
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            }
        });
    }


    const handleFilter = () => {
        if (orderSort === 'asc') {
            setOrderSort('desc')

        } else {
            setOrderSort('asc')
        }


        if (searchTerm === '') {
            sortJSON(clients, 'name')
        } else {
            sortJSON(filteredProducts, 'name')
        }
    }

    const handleSaveClient = (data) => {
        setOrderSort('asc')
        setRuc(data.ruc)
        setName(data.name)
        setDv(data.dv)
        setDataResponse(true)
        setShowModal(false)
        setSearchTerm('')
    }

    //Filter the products by the term searched 
    const filteredProducts = useMemo(() => {
        if (searchTerm === '') {
            return clients
        }
        return searchedClients.filter(data => {
            const nameMatch = data.name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
            return nameMatch;
        })
    }, [searchTerm, clients]);







    return (
        <>
            <button className='w-100 py-2 mb-2 btn btn-outline-primary rounded-3' type="button" onClick={() => (getClients(), setShowModal(true))}>
                <i class="uil uil-search"></i> Buscar cliente por nombre
            </button>

            <Modal
                className='bg-blur-sp'
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='lg'
                centered>

                <Modal.Body className='p-2'>
                    <section className='flex-container'>
                        <div className="d-flex justify-content-center border-bottom-0">
                            <h2 className="modal-title fw-bold fs-5">
                                <i class="uil uil-users-alt"></i> Clientes
                            </h2>
                        </div>

                        <span className='p-0  text-primary fw-bold'>Seleccion de clientes </span>

                        <div className="d-flex justify-content-center align-items-center ">
                            <form className="row g-3 align-content-center align-items-center">
                                <div className="col-auto ">
                                    <span className='mb-0 text-secondary fw-bold' ><i class="uil uil-search"></i> Buscar</span>
                                </div>
                                <div className="col-auto">
                                    <input
                                        spellcheck="false"
                                        className="form-control"
                                        type="text"
                                        step="any"
                                        placeholder="Ingrese el nombre del cliente"
                                        onChange={e => setSearchTerm(e.target.value)}

                                    />

                                </div>
                                <div className="col-auto">
                                    <Button variant="outline-secondary" onClick={() => handleFilter()}> {orderSort === 'asc' ? <><i class="uil uil-sort-amount-down"></i> A-Z</> : <><i class="uil uil-sort-amount-up"></i> Z-A</>}</Button>
                                </div>
                            </form>
                        </div>
                        {/* List  */}

                        <div className={
                            !filteredProducts.length
                                ? "d-block item2 border-inset bg-light-subtle border border-2 border-1 border-light-subtle rounded-3 list-discount-framer align-self-center overflow-auto w-100 d-flex align-items-center justify-content-center"
                                : "row item2 border-inset bg-light-subtle border border-2 border-1 border-light-subtle rounded-3 list-discount-framer-2 align-self-center  w-100"

                        }>

                            {filteredProducts.map(data => (
                                <div className='col-12 col-lg-6'>
                                    <ListGroup as="ol" className='m-2' >
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start  shadow-sm"
                                            onClick={() => handleSaveClient(data)}
                                        >
                                            <div className="ms-2 me-auto d-inline-block text-truncate">
                                                <div>Nombre: {data.name}</div>
                                                <div>RUC: {data.ruc}</div>
                                                <div>Dv: {data.dv}</div>
                                            </div>

                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                            ))}

                        </div>
                        <small className='text-body-tertiary text-center'>
                            <i className="uil uil-info-circle me-1"></i>
                            Por favor, seleccione un cliente para personalizar la factura.
                        </small>
                        {/* List end  */}
                    </section>
                    <div className='d-block mt-lg-1 mt-0'>
                        <div className='d-flex justify-content-end mt-2'>
                            <button className='btn bg-danger-subtle text-danger  border-0 fw-bold '
                                onClick={() => (setShowModal(false), setSearchTerm(''), setOrderSort('asc'))}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >

        </>
    )
}

export default Modalclient