import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'

import { PreOrderFetchAPI } from '../../api/Paymentpost/PreOrder';
import { DeletedTempOrdersFetchAPI } from '../../api/DeletedTemporalOrders';

import Swal from "sweetalert2";
import imgPreOrder from './img/preOrder.png'
import ErrorAlert from '../Alerts/ErrorAlert';
import { RenderIf } from '../utils/RenderIf';
import LoadingAlert from '../Alerts/LoadingAlert';
import { GiPlayerTime } from 'react-icons/gi';
import Empty from '../utils/Empty';
import refund from '../img/icons/refund.png'
import ModalDetailPreOrder from './ModalDetailPreOrder';


function ModalDivisionByProduct({ order, setShowDivision, getAll, setShowPartsByProducts, showByProduct, setShowByProduct, division, codeParthner }) {
    //const [showByProduct, setShowByProduct] = useState(false);
    const [count, setCount] = useState(1)
    let index = 0;
    const [dataOrder, setDataOrder] = useState({});
    const [productChecked, setProductChecked] = useState([]);
    const [comboChecked, setComboChecked] = useState([]);
    const [productDivision, setProductDivision] = useState([])
    const [groups, setGroups] = useState([])
    const [combos, setCombos] = useState([])

    //create preOrder
    const createPreOrder = async (products, combosnow, discount, subtotal, tax, total, productList, comboList, taxesArray
    ) => {
        let token = localStorage.getItem('x-access-machine-token');
        let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
        let machine = JSON.parse(localStorage.getItem('machine'))
        let user = JSON.parse(localStorage.getItem('user'));

        console.log("datos de combos");
        console.log(combosnow);
        console.log(comboList);


        let json = {
            "client_name": order.client_name + " " + (count + 1),
            "localizador": order.locator,
            "client_phone": order.client_phone,
            "ruc": order.client_ruc,
            "dv": order.client_dv,
            "id_Client": 0,
            "dgi_qr": "0",
            "Orden": products,
            "combos": combosnow,
            "Descuento": parseFloat(discount).toFixed(2),
            "Total": parseFloat(total).toFixed(2),
            "itbms": parseFloat(tax).toFixed(2),
            "taxes": taxesArray,
            "subtotal": parseFloat(subtotal).toFixed(2),
            "machine_id": machine.id,
            "branchoffice_id": branchoffice.id,
            "tipoOrden": order.order_type,
            "paymethod": 0,
            "is_discount_notification": false,
            "is_cupon": false,
            "is_devolution_notification": false,
            "is_pre_order": true, "user": {
                id: user.id,
                name: user.name,
                code: user.user_code
            }
        }

        if ((combosnow.length + products.length) > 0) {
            try {
                const response = await PreOrderFetchAPI.createPreOrder(json, token);
                //count = (count + 1)
                setCount(prev => prev + 1)
                let message = "Precuenta " + count + " creada con exito."
                let title = "Operacion exitosa."
                let icon = "success";

                groups.forEach((product, index) => {
                    document.getElementById(product.name + "group" + index).checked = false

                })

                combos.forEach((combo, index) => {
                    document.getElementById(combo.name + "combo" + index).checked = false
                })



                console.log(count);
                if (division > count) {

                    if ((productList.length + comboList.length) > 0) {
                        setGroups(productList);
                        setCombos(comboList)
                    } else {
                        message = "Precuenta " + count + " asignaste los todos los productos restantes, creada con exito."
                        setShowByProduct(false);
                        setShowDivision(false);
                        deletePreOrder(dataOrder.code);
                        setCount(0)
                    }

                    setProductChecked([]);
                    setComboChecked([])
                    //calculateInvoiceTotals(productChecked);
                } else {

                    setShowByProduct(false);
                    setShowDivision(false);
                    deletePreOrder(dataOrder.code);
                    setCount(0)

                }

                ErrorAlert(message, title, icon);


            } catch (err) {
                console.log(err);
                ErrorAlert("", "Ocurrio un error ", "error")
            }
        } else {
            ErrorAlert("", "No hay productos seleccionados para esta precuenta ", "error")
        }

    }

    //delete preorder
    const deletePreOrder = async (code) => {
        LoadingAlert()
        let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
        let business = JSON.parse(localStorage.getItem('business'))
        let user = JSON.parse(localStorage.getItem('user'));
        let machine_token = localStorage.getItem('x-access-machine-token');

        let business_code = business.code 
        let branch_code = branchoffice.code

        if (codeParthner != 0) {
            business_code = codeParthner.business_code
            branch_code = codeParthner.branchoffice_code
        }

        let jsonUpdate = {
            "order_code": code,
            "business_code":business_code,
            "branchoffice_code": branch_code,
            "status": 5,
            "user_id": user.id
        }
        try {
            //We save the preorder
            await DeletedTempOrdersFetchAPI.newDeletedTempOrder(jsonUpdate, machine_token);
            //We delete the preorder
            await PreOrderFetchAPI.deletePreOrder(jsonUpdate, machine_token)
            let message = "precuenta eliminada con exito."
            let title = "Precuenta eliminada"
            let icon = "success"

            console.log("precuenta original eliminada");
            //ErrorAlert(message, title, icon)
            getAll();
        } catch (err) {
            console.log(err);
            ErrorAlert("Esta precuenta pertenece a otra sucursal", "Permiso denegado", "info")
        }
    }

    //filter checkbox in state true and save in state array
    const getChecked = (event) => {
        let dataProduct = JSON.parse(event.target.value)
        dataProduct.index = event.target.dataset.index;
        if (event.target.checked == true) {

            if (dataProduct.combo_id) {
                setComboChecked([...comboChecked, dataProduct])
            } else {
                setProductChecked([...productChecked, dataProduct]);
            }

        } else if (event.target.checked == false) {
            for (let i = 0; i < productChecked.length; i++) {
                if (productChecked[i].index == event.target.dataset.index) {
                    productChecked.splice(i, 1);

                }
            }

            for (let c = 0; c < comboChecked.length; c++) {
                if (comboChecked[c].index == event.target.dataset.index) {
                    comboChecked.splice(c, 1);
                }
            }
        }


    }

    const getPreOrderByid = async () => {
        let machine_token = localStorage.getItem('x-access-machine-token');
        try {
            const response = await PreOrderFetchAPI.getPreOrderById(order._id, machine_token);
            setDataOrder(response.data.pre_order)
            setGroups(order.groups)
            setCombos(order.combos)
        } catch (err) {
            console.log(err);
        }
    }



    const separateOrder = (products, combos, discount, subtotal, tax, total, productList, comboList, taxesArray) => {
        products.forEach(group => {
            group.DetalleSegmentos = group.segments
        });
        createPreOrder(products, combos, discount, subtotal, tax, total, productList, comboList, taxesArray)
    }

    const divisionProduct = () => {
        let productList = []
        let comboList = []

        groups.forEach((group) => {
            const result = productChecked.filter((e) => e._id === group._id);
            if (result.length == 0) {
                productList.push(group)
            }
        });

        combos.forEach((combo) => {
            const result = comboChecked.filter((e) => e._id === combo._id);
            if (result.length == 0) {
                comboList.push(combo)
            }
        });




        if ((productChecked.length + comboChecked.length) > 0) {
            if (count == division) {
                if ((productList.length + comboList.length) > 0) {
                    ErrorAlert('', 'Esta es la ultima cuenta debe seleccionar los productos restantes', 'warning');
                } else {
                    calculateInvoiceTotals(productChecked, productList, comboChecked, comboList);
                }
            } else {
                calculateInvoiceTotals(productChecked, productList, comboChecked, comboList);
            }

        } else {
            if (count > 1) {
                calculateInvoiceTotals(productChecked, productList, comboChecked, comboList);
            } else {
                ErrorAlert('', 'No es posible separar esta precuenta', 'warning');
            }
        }


    }


    const calculateInvoiceTotals = (products, productList, combos, comboList) => {

        //For every new calculate we set the totals
        let taxesArray = [];
        let invoiceTotalDiscount = 0
        let invoiceSubtotal = 0
        let invoiceTotalItbms = 0
        let invoiceTotal = 0

        //if we dont we products we put the totals in 0 for default
        if ((products.length + combos.length) === 0) {
            //We calculate the totals of the invoice
        } else {
            //For every product we calculate

            combos.forEach((combo) => {
                byProductandCombo(combo)
            })

            products.forEach((group) => {
                byProductandCombo(group)
            })

            function byProductandCombo(item) {
                let product_info = item
                //Variables
                let total_product_price = 0;
                let total_product_itbms = 0;
                let product_total_discount = 0;
                //We set the total discount of the product
                product_total_discount = (product_info.unit_discount * product_info.amount);
                //We set the total of the product
                total_product_price = (product_info.amount * (product_info.price - product_info.unit_discount));


                //Calculate tax by group--------------------------------
                let taxesGroup = product_info.taxes;
                taxesGroup.forEach(tax => {

                    //calculate tax  value and add it to array
                    let valueTax = (product_info.amount * ((product_info.price - product_info.unit_discount) * tax.taxtValue));
                    tax.total = valueTax;
                    //Identifier tax of the group position
                    var index = taxesArray.findIndex(function (info, i) {
                        return info.tax_id === tax.tax_id
                    });

                    if (index < 0) {
                        let taxInfoNow = {
                            "dTasaITBMSValue": tax.dTasaITBMSValue,
                            "percentage": tax.percentage,
                            "tax": tax.tax,
                            "tax_id": tax.tax_id,
                            "taxtValue": tax.taxtValue,
                            "total": valueTax
                        }
                        taxesArray.push(taxInfoNow);
                    } else {
                        taxesArray[index].total = (taxesArray[index].total + valueTax)
                    }
                    total_product_itbms = (total_product_itbms + valueTax);

                });
                //-------------------------------------------------------


                //We set the itbms of the product
                //total_product_itbms = (product_info.amount * ((product_info.price - product_info.unit_discount) * product_info.taxtValue));

                //We calculate the totals of the invoice
                invoiceTotalDiscount = invoiceTotalDiscount + product_total_discount;
                invoiceSubtotal = invoiceSubtotal + total_product_price;
                invoiceTotalItbms = invoiceTotalItbms + total_product_itbms;
                invoiceTotal = invoiceTotal + total_product_price + total_product_itbms;
            }



            console.log("Decuento:" + invoiceTotalDiscount);
            console.log("SubTotal:" + invoiceSubtotal);
            console.log("Itbms:" + invoiceTotalItbms);
            console.log("Total:" + invoiceTotal);
            console.log(comboList);

        }

        separateOrder(products, combos, invoiceTotalDiscount, invoiceSubtotal, invoiceTotalItbms, invoiceTotal, productList, comboList, taxesArray);
    }

    const cardProduct = (product, index) => {
        return (<>
            <div className="col-12 col-md-12 col-lg-4 mt-lg-1">

                <input
                    className="list-group-item-check rounded"
                    type="checkbox" value={JSON.stringify(product)}
                    data-index={index}
                    id={product.combo_id ? product.name + "combo" + index : product.name + "group" + index}
                    name="checked1" onChange={(e) => (getChecked(e))} />

                <label className="list-group-item py-1 px-1 h-100"
                    htmlFor={product.combo_id ? product.name + "combo" + index : product.name + "group" + index}
                >
                    <div className="d-flex gap-3 py-3 rounded" >
                        <img src={product.img}
                            alt="Product" width="50" height="50" className="rounded flex-shrink-0" />
                        <div className="d-flex gap-2 w-75 justify-content-around">
                            <div>
                                <h6 className="mb-0 fw-bold">Producto : {product.name}</h6>
                                <p className="mb-0 opacity-75">Precio: {product.price.toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                </label>

            </div>
        </>)
    }


    useEffect(() => {
        (async () => {
            getPreOrderByid()
        })();
    }, []);

    useEffect(() => {

    }, [groups]);


    return (
        <>

            <Modal
                show={showByProduct}
                fullscreen
                onHide={() => setShowByProduct(false)}
                aria-labelledby="example-custom-modal-styling-title"
            >

                <Modal.Body scrollable>

                    <div>
                        <div className='mt-2 text-center p-1'>
                            <h4 className='fw-bold'>  <i class="uil uil-apps"></i> Dividir por productos: Cuenta: {count} de {division} </h4>
                        </div>

                        <div >

                            <ModalDetailPreOrder orderData={order}></ModalDetailPreOrder>
                        </div>
                    </div>



                    <div className='mt-2 text-center p-1 mt-5'>
                        <h4 className='fw-bold'><i class="uil uil-ellipsis-v"></i> Selecciona los productos de la cuenta {count} </h4>
                    </div>

                    <div className='container'>


                        <div className='d-flex justify-content-center alight-item-center row'>

                            {/* Validatopn Shoppint Cart Empty  */}
                            {groups.map((product, index) => (
                                cardProduct(product, index)
                            ))
                            }

                            {combos.map((combo, index) => (
                                cardProduct(combo, index)
                            ))
                            }

                        </div>
                    </div>




                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-content-between mt-3'>
                        <RenderIf isTrue={count == 1}>
                            <button className='btn btn-danger btn-lg m-1' onClick={() => (setProductChecked([]), setShowByProduct(false))}> Cancelar </button>
                        </RenderIf>
                        <button className='btn btn-primary btn-lg m-1' onClick={() => divisionProduct()} > Dividir </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalDivisionByProduct