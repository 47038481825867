//React components 
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
//Components 
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ErrorAlert from '../Alerts/ErrorAlert';
import { Container } from 'react-bootstrap';
import { RenderIf } from '../utils/RenderIf';
import Modal from 'react-bootstrap/Modal'

import refund from '../img/icons/refund.png'
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';

//PDF
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import DocPdf from '../Pdf/DocPdf';



const ModalDetailPreOrder = ({ orderData }) => {
    //Function to populate the users branch 


    const [show, setShow] = useState(false);
    //Total taxes 
    const [totalTaxes, setTotalTaxes] = useState([
        { total_base: 0, tax: "0%", total_tax: 0 },
        { total_base: 0, tax: "7%", total_tax: 0 },
        { total_base: 0, tax: "10%", total_tax: 0 },
        { total_base: 0, tax: "15%", total_tax: 0 }])
    const [branchOfficeData, setBranchofficeData] = useState({
        "ruc": "",
        "dv": "",
        "name": "",
        "ruc_type": "",
        "direction": "",
        "dgi_name": "",
    });

    //We charge the items of the order 
    const orderItems = () => {
        let items = orderData.groups.map((product) => (
            <div className="d-flex text-muted pt-3 px-xs-2" key={product._id}>
                <img className="bd-placeholder-img flex-shrink-0 me-2 rounded" src={product.img} width="42" height="42" />
                <div className="pb-3 mb-0  lh-sm  w-100">
                    <div className="d-flex justify-content-between ">
                        {/* Name */}
                        <span className="text-dark fw-bold">{product.name}</span>
                        {/* Cantidad/ Quantity*/}
                        <div>
                            <span className='px-3'>x {product.amount} </span>
                            <label className="fw-bold" translate='no'>$ {product.price.toFixed(2)}</label>
                        </div>

                    </div>
                    {/** Segment items */}
                    <>
                        <div className="d-flex justify-content-between">
                            {/* Name */}

                            {/* Cantidad/ Quantity*/}
                            <div >
                                {/** We check the type of modal and we return the input discount field depending of it */}


                            </div>
                        </div>
                        {product.segments.map((segments) => (
                            <div className="d-flex justify-content-between" key={segments._id}>
                                {/* item name */}
                                <span className="">{segments.item.name}</span>
                            </div>
                        ))}

                        <div>
                            <div>
                                <small className='text-primary p-1' >
                                    Entrega: {product.delivery_type}
                                </small>
                            </div>
                            <div>
                                <RenderIf isTrue={product.note != ""}>
                                    <small className='text-danger p-1' >
                                        Nota: {product.note}
                                    </small>
                                </RenderIf>
                            </div>
                        </div>




                    </>
                </div>
            </div>
        ));
        return <>{items}</>
    }

    //Template card Group of setGroup
    const cardGroup = (product) => {
        return (
            <div className="card-group">
                <div className="card">
                    <div className='d-flex justify-constent- between'>
                        <img src={product.img === null ? '' : product.img} className='m-2' alt="..." width="50" height="50" />
                        <div className="card-body">
                            <h5 className="card-title">{product.name}</h5>
                            <details close >
                                <summary className=''>
                                    <span className='badge bg-light text-dark summary-text-pos  fw-bold border rounded-4  text-wrap '>
                                        <i className="uil uil-info-circle  px-1 "></i>  Detalles
                                    </span>
                                </summary>
                                {product.segments.map(item => (
                                    <span className="badge bg-light text-dark  summary-text-pos m-2 border rounded-4 my-1 text-wrap ">
                                        {/* <span className='kiosk-subtitle-s' >   </span> */}
                                        <span className='text-muted m-0 p-0'>{item.name}:</span>
                                        <br />
                                        <strong>
                                            <span className='me-2'>{item.item.name} </span>
                                            {parseFloat(item.item.price).toFixed(2)}
                                        </strong>
                                    </span>
                                ))}
                            </details>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const orderCombos = () => {
        let items = orderData.combos.map((product) => (
            <div className="d-flex text-muted pt-3 px-xs-2" key={product.combo_id}>
                <img className="bd-placeholder-img flex-shrink-0 me-2 rounded" src={product.img} width="42" height="42" />
                <div className="pb-3 mb-0  lh-sm  w-100">
                    <div className="d-flex justify-content-between ">
                        {/* Name */}
                        <span className="text-dark fw-bold">{product.name} </span>
                        {/* Cantidad/ Quantity*/}
                        <div>
                            <span className='px-3'>x {product.amount} </span>
                            <label className="fw-bold" translate='no'>$ {product.price.toFixed(2)}</label>
                        </div>

                    </div>
                    {/** Segment items */}
                    <>

                        {product.setGroups.map((setGroup) => (
                            <div className="d-flex justify-content-between" key={setGroup.set_id}>
                                <details close >
                                    <summary className=''>
                                        <span className='badge bg-light text-dark summary-text-pos  fw-bold border rounded-4  text-wrap m-1 '>
                                            <i className="uil uil-info-circle  px-1 "> {setGroup.name}</i>
                                        </span>
                                    </summary>
                                    {setGroup.groups.map((group) => (<>
                                        {cardGroup(group)}
                                    </>))}
                                </details>
                            </div>))}

                        <div>
                            <div>
                                <small className='text-primary p-1' >
                                    Entrega: {product.delivery_type}
                                </small>
                            </div>
                            <div>
                                <RenderIf isTrue={product.note != ""}>
                                    <small className='text-danger p-1' >
                                        Nota: {product.note}
                                    </small>
                                </RenderIf>
                            </div>
                        </div>




                    </>
                </div>
            </div>
        ));
        return <>{items}</>
    }

    useEffect(() => {
        (async () => {
         

            try {
                //We get the branchoffice info 
                const response_branch = await BranchOfficeFetchAPI.getBranchOfficeByCode(orderData.business.code, orderData.branchoffice.code);
                //We set the business data 
                setBranchofficeData(response_branch.data.branchoffice);

            } catch (err) {
                setBranchofficeData({
                    "ruc": "",
                    "dv": "",
                    "name": "",
                    "ruc_type": "",
                    "direction": "",
                    "dgi_name": "",
                })
            }
        })();
    }, []);
    return (<>

        <div className="d-flex align-items-center border-history shadow-sm mt-3 mx-1 bg-white" onClick={() => setShow(true)}>
            <div className="flex-shrink-0 p-2">
                <img src={refund} alt="En progreso" width="41" height="40" />
            </div>
            <div className="flex-grow-1 ms-3">
                <div className=' d-flex justify-content-between mt-2 me-2'>
                    <h5 className="mb-0 text-muted">Orden #{orderData.code} </h5>
                    <h5 className='mt-1'>Total:<strong > {orderData.total.toFixed(2)} $</strong ></h5>
                </div>
                <span className="text-nowrap text-primary h6 d-block mb-2">
                    <i class="uil uil-user-circle"></i>&nbsp;
                    Cliente: {orderData.client_name}
                </span>


                <span className="text-nowrap text-primary h6 d-block mb-2">
                    <i className="fa-solid fa-cash-register"></i>&nbsp;
                    {!orderData.machine_name ? 'No es caja' : orderData.machine_name} / {!orderData.user ? 'No es cajera' : orderData.user.name}
                </span>
                <span className="text-nowrap text-secondary h6">
                    <i className="fa-solid fa-mobile-screen"></i>&nbsp;
                    Máquina: {orderData.machine_code}
                </span>

                <div className='mt-1 me-2 d-flex justify-content-between'>
                    <span className="text-nowrap text-secondary h6">
                        <i className="fa-solid fa-store"></i>&nbsp;
                        Sucursal: {orderData.branchoffice.name}
                    </span>
                    <span className="text-nowrap text-secondary h6 d-block">
                        <i class="uil uil-list-ol"></i>
                        Cantidad de productos: {(orderData.groups.length + orderData.combos.length)}
                    </span>
                    <span className="text-nowrap text-primary h6 d-block mb-2">
                        <i class="uil uil-eye"></i>
                        Ver detalle
                    </span>
                </div>


            </div>
        </div>

        <Modal show={show} size='xl' onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Detalles</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className='mb-2'>
                    {/* Your card */}
                    <Row>
                        <Col sm={12} md={12}  >
                            <div className="py-4 px-lg-4 ">
                                <div className="head-section-sp mt-2">
                                    <div className="">
                                        <div className="mt-1 mb-3">


                                            <div className="d-flex justify-content-between align-items-center mb-3 ">
                                                <div className='header-invoice'>

                                                    <div>
                                                        <h5 className='p-2'>
                                                            <span className="text-success"> {orderData.order_type} </span>

                                                        </h5>
                                                    </div>
                                                </div>
                                                <h5>
                                                    <span className="text-success">Orden: </span>
                                                    <span className="text-secondary"> # {orderData.code ? orderData.code : 0}</span>
                                                </h5>
                                            </div>

                                            <div>
                                                <span className="text-nowrap text-primary h6 d-block mb-2">
                                                    <i class="uil uil-user-circle"></i>&nbsp;
                                                    Cliente: {orderData.client_name}
                                                </span>
                                                <span className="text-nowrap text-secondary h6 d-block">
                                                    <i className="fa-solid fa-store"></i>&nbsp;
                                                    Sucursal: {orderData.branchoffice.name}
                                                </span>
                                                <span className="text-nowrap text-secondary h6 d-block">
                                                    <i class="uil uil-list-ol"></i>
                                                    Cantidad de productos: {(orderData.groups.length + orderData.combos.length)}
                                                </span>
                                                <span className="text-nowrap text-primary h6 d-block">
                                                    <i className="fa-solid fa-cash-register"></i>&nbsp;
                                                    {orderData.machine_name} / {!orderData.user ? 'No es cajera' : orderData.user.name}
                                                </span>




                                            </div>
                                        </div>
                                    </div>

                                    <div className=' d-flex justify-content-between text-secondary opacity-75'>
                                        <div> Productos </div>
                                        <div> Precio </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-lg-12 order-md-last">
                                            {/* Item cards */}
                                            {orderItems()}
                                            {orderCombos()}
                                            {/**Total details */}
                                            <div className="d-flex text-muted pt-3">
                                                <div className="pb-3 mb-0 small lh-sm border-top w-100">

                                                    <div className="d-flex justify-content-end mt-3">
                                                        <span className="text-gray-dark fw-light h6"> Descuento: </span>
                                                        <span className='text-danger fw-light h6'>
                                                            <span className='px-2 ' translate='no'>- $</span>
                                                            {orderData.discount.toFixed(2)}
                                                        </span>
                                                    </div>

                                                    {/** We render the totals if the order is type permanent */}

                                                    <div className="d-flex justify-content-end mt-3">
                                                        <span className="text-gray-dark fw-light h6"> Subtotal: </span>
                                                        <span className='text-black fw-light h6'>
                                                            <span className='px-2 ' translate='no'>$</span>
                                                            {orderData.subtotal.toFixed(2)}
                                                        </span>
                                                    </div>
                                                    {orderData.taxes.map((tax) => (
                                                        <>
                                                            {tax.total.toFixed(3) > 0 ?
                                                                <div className="d-flex justify-content-end mt-3">
                                                                    <span className="text-gray-dark fw-light h6"> Impuesto {tax.tax}: </span>
                                                                    <span className='text-black fw-light h6'>
                                                                        <span className='px-2 ' translate='no'>$</span>
                                                                        {tax.total.toFixed(3)}
                                                                    </span>
                                                                </div> : <></>}
                                                        </>
                                                    ))}
                                                    <div className="d-flex justify-content-end mt-3">
                                                        <strong className="text-black h5"> Total: </strong>
                                                        <span className='text-black fw-bold h5'>
                                                            <span className='px-2 ' translate='no'>$ </span>
                                                            {orderData.total.toFixed(2)}
                                                        </span>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>

                    </Row>

                    <PDFDownloadLink className='pdf-viewer-sp' document={<DocPdf
                        doc_type={ "price"} invoiceQR={"0"} invoiceDate={orderData.createdAt} cufe={"0"} auth_protocol={orderData.auth_protocol ? orderData.auth_protocol : "00000000"}
                        receptor={{
                            name: orderData.client_name,
                            ruc: orderData.client_ruc,
                            direction: "Panama",
                            dv: orderData.client_dv,
                        }}
                        emitter={branchOfficeData}
                        products={orderData.groups} invoiceSubtotal={orderData.subtotal} totalTaxes={totalTaxes}
                        totalCashReceived={orderData.total} invoiceTotal={orderData.total} invoiceTotalItbms={orderData.tax} paymentMethod={orderData.paymenthod}
                    />} >
                        <button className='btn btn-secondary-sp border border-sp' > {orderData.invoice_type === "devolution" ? "Descargar nota de credito" : "Descargar factura electronica"} </button>
                    </PDFDownloadLink>

                </Container>

            </Modal.Body>
            <Modal.Footer>
                <div className='d-flex justify-content-between mt-3'>
                    <button className='btn btn-danger btn-lg' onClick={() => setShow(false)}> cerrar </button>
                </div>
            </Modal.Footer>
        </Modal>
    </>);
}

export default ModalDetailPreOrder;