import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { NotificationsFetchAPI } from '../../api/Notidication';
import ErrorAlert from '../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';

import { ListGroup } from 'react-bootstrap';

function ModalNotification() {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0)
    const [notifications, setnotifications] = useState([])
    let token = localStorage.getItem("x-access-machine-token")
    let branchoffice = localStorage.getItem('branchoffice');
    let business = localStorage.getItem('business');


    //We validate if the branch
    if (branchoffice !== null && branchoffice !== undefined && branchoffice !== '') {
        branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
    }

    //We validate if the business
    if (business !== null && business !== undefined && business !== '') {
        business = JSON.parse(localStorage.getItem('business'))
    }

    // get notification

    const getNotifications = async () => {
        try {
            const response = await NotificationsFetchAPI.getNotifications(branchoffice.code, business.code, token)
            setnotifications(response.data.notifications)

        } catch (err) {
            console.log(err);
        }
    }


    {/* COMENTADO TEMPORALMENTE
    let interval = 0
    const example = () => {
        console.log(interval);
        clearInterval(interval)
        interval = setInterval(example, 5000)
    }*/}




    const deleteNotification = async (notification_id) => {
        try {
            const response = await NotificationsFetchAPI.deleteNotification(notification_id, token)
            console.log(response);
            ErrorAlert("", "Notificacion eliminada", "success")
            getNotifications()
            setEventHandle(1)

        } catch (err) {
            ErrorAlert("", "Ocurrio un error", "success")
            console.log(err);
        }
    }


    const viewNotifications = () => {
        const listNotification = notifications.map((notification) => (
            <>
                <div className="alert alert-success" role="alert">
                    <div className='d-flex justify-content-between'>
                        <div>
                            <h4 className="alert-heading">{notification.title}</h4>
                            <p>{notification.body}</p>
                            <div className='d-flex gap-2 w-100 justify-content-between'>
                                <small className='ms-5 text-muted '><i className="uil uil-calendar-alt"></i>{notification.updatedAt.slice(0, 10)}</small>
                                <small className='text-muted '> <i className="uil uil-clock"></i>{notification.updatedAt.slice(11, 19)}</small>
                            </div>
                        </div>
                        <Button onClick={async () => (deleteNotification(notification._id))} variant="danger"><i className="uil uil-trash"></i></Button>
                    </div>
                </div>
            </>
        ));

        return (listNotification);
    }

    const handle = () => {
        setShowModal(true);
        getNotifications()
        //example();
    }

    useEffect(() => {
        setEventHandle(0)
    }, [eventHandle])


    const [count, setCount] = useState(0);

    useEffect(() => {

        //Implementing the setInterval method  COMENTADO TEMPORALMENTE INTERVALO DE NOTIFICACION
        {/*const interval = setInterval(() => {
            setCount(count + 1);
            getNotifications()
            //console.log(count);
        }, 10000);

        //Clearing the interval
    return () => clearInterval(interval);*/}
    }, [count]);


    return (
        <>
            <button
                className=' btn btn-outline-dark btn-sm  border-0 rounded-3 mx-1'
                onClick={() => (handle())}>
                <div className="position-relative ">
                    <i className="uil uil-bell user-name" title='Notificaciones'></i>                  
                     <small className='fw-bold fw-bold mx-1 menu-label'>Notificaciones</small>
                    <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-warning shadow">
                        {notifications.length}
                        <span className="visually-hidden ">
                            Notificaciones 
                        </span>
                    </span>
                </div>
                {/* <Badge bg="warning" text="dark">{notifications.length} 
                </Badge>*/}


            </button>
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-bell"></i> 
                        Notificaciones <Badge bg="warning" text="dark">{notifications.length}</Badge></h3>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {viewNotifications()}
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ModalNotification