import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom'
//Utils
import Socket from '../utils/Socket.config';
//Componenet App
import { Form, Button } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';


import { Modal } from 'react-bootstrap';



const ModalIngredient = ({setShowModalIngredient, showModalIngredient, groupNow, businessLogo, formatJsonToAddShoppingCart, segmentsNow, img_default}) => {
   const [show, setShow] = useState(false);

   //Function to populate the segment items
   const segmentsItems = (segment) => {

      //validates the item by default and if it does not exist, sets the first position
      let itemSegments = segment.items
      let turnDefault = false
      itemSegments.forEach((item) => {
         if (segment.segment_item_id_default == item.item_id) {
            turnDefault = true
            item.turnDefault = true
         }
      })

      //set first position
      if (turnDefault == false) { itemSegments[0].turnDefault = true }

      const listItems = itemSegments.map((item, index) => (<>

         <article className='my-1 list-info-products-inputs  border-0 card-product-segments-kiosk'>
            <input
               className="list-group-item-check " type={segment.type == "UNIQ" ? "radio" : "checkbox"} name={"listGroupCheckableRadios" + segment.segment_id}
               id={"listGroupCheckableRadios" + segment.segment_id + item.item_id}
               //defaultChecked={segment.segment_item_id_default === item.item_id || (!segment.segment_item_id_default && item.item_id === segment.items[0].item_id)}
               defaultChecked={segment.default_items.length == 0 ? index == 0 ? true : false : segment.default_items.find((default_item) => default_item.item_id == item.item_id) != undefined ? true: false} 
               //defaultChecked={item.turnDefault}
            />
            <label className="list-group-item rounded-3 mb-0 fw-bold text-truncate bg-white p-1 shadow-sm position-relative h-100" htmlFor={"listGroupCheckableRadios" + segment.segment_id + item.item_id}>
               {item.name}
               <div className="d-flex align-items-center gap-3 py-3 rounded" >
                  <img src={item.img === null ? img_default : item.img}
                     alt="Product" width="50" height="50" className="rounded-3 flex-shrink-0 " />
                  <div className="d-flex gap-2 justify-content-around">

                     <div className='my-2'>
                        <span className=" d-block mb-0 fw-normal text-wrap ">
                           {item.description}
                        </span>
                     </div>
                  </div>
               </div>
               <span class="badge bg-success-subtle text-success-emphasis rounded-pill bottom-0 end-0 position-absolute m-1">
                  {item.price == 0 ? "+ $0.00" : "+ $" + item.price.toFixed(2)}</span>
            </label>
         </article>
      </>
      ));
      return (listItems)
   }


   return (
      <>



         {/* Modal-Products Information*/}
         <Modal
            className='bg-blur-sp'
            show={showModalIngredient}
            size="xl"
            centered
            onHide={() => (setShowModalIngredient(false))}>
            <Modal.Body >


               <div className="d-flex justify-content-center border-bottom-0">
                  <h2 className="modal-title fw-bold fs-5">
                     <i class="uil uil-label"></i>
                     {groupNow.name}
                  </h2>
               </div>
               <div class="d-flex align-items-center border rounded-3 shadow-sm p-1">
                  <div class="flex-shrink-0">
                     <img className='rounded-sp border bg-secondary ' width="80px" height="80px"
                        src={groupNow.img === null || groupNow.img === '' ? businessLogo : groupNow.img} alt="img-product "
                     />
                  </div>
                  <div class="flex-grow-1 ms-3 text-description-info">
                     {groupNow.description}
                  </div>
                 
               </div>
               <div className='flex-container'>
                  {/* Product's Suggestions */}
                  {segmentsNow.length > 0 ? <>
                     <div className='item2'>
                        {/* <div className='suggestions-over'>*/}
                        <div className='item2 border-inset bg-light-subtle border border-2 border-1 border-light-subtle rounded-3 list-discount-framer-2 align-self-center  w-100'>
                           {segmentsNow.map((segment) => (
                              <>
                                 <div>
                                    <h5 className='pt-1 fw-bold text-underline'>
                                       <u>{segment.name}</u>
                                    </h5>
                                 </div>
                                 <div className=''>
                                    <div className="mt-1 ms-1 overflow-auto d-flex gap-3">
                                       {segmentsItems(segment)}
                                    </div>
                                 </div>
                              </>))}
                        </div>
                        {/*  </div> */}
                     </div>
                  </> : <></>}

                  <div className='d-block mt-lg-1 mt-0'>
                     <div className='d-flex justify-content-end mt-2'>
                        <button className='btn bg-danger-subtle text-danger  border-0 fw-bold '
                           onClick={() => (setShowModalIngredient(false))}> Cancelar </button>

                         <button className='btn bg-success-subtle text-success fw-bold border-0  ms-2'
                               onClick={() => ( formatJsonToAddShoppingCart(groupNow, true), setShowModalIngredient(false))}> Agregar al carrito </button>

                     </div>
                  </div>
               </div>
            </Modal.Body>
         </Modal>


      </>
   )
}

export default ModalIngredient
