import React from 'react'
import HeroImage from './img/backgrounds/bg-sp.png'


function Hero() {
  return (
   <>
      <div className="container col-xxl-8 px-4 py-5">
          <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
            <div className="col-10 col-sm-8 col-lg-6">
              <img src={HeroImage} className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy"/>
            </div>
            <div className="col-lg-6">
              <h1 className="display-4 fw-bold lh-1 mb-3 text-color-sp ">En manteminiento </h1>
              <p className="lead display-6 " >Pronto volveremos para innovar y automatizar los métodos de pago de tu negocio. </p>
            </div>
          </div>
        </div>
   </>
  )
}

export default Hero