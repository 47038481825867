import axios from 'axios';

const server = axios.create({
    baseURL: '',
    headers: { 'Content-Type': "application/json" },
});

export class MicrosFetchAPI {

    static async createCheckMicros(ip, RvcRef, employeeNum, host_url, data, token) {
        console.log(token);
        let response = await axios.post( `http://${ip}:9472/api/micros/checks?RvcRef=${RvcRef}&employeeNum=${employeeNum}&host_url=${host_url}`, data, {
            headers: {"x-access-token":token}
          });
        return response;
    }

    static async printkMicros(ip, RvcRef, employeeNum, host_url, data, token) {
        console.log(token);
        let response = await axios.post( `http://${ip}:9472/api/micros/checks?RvcRef=${RvcRef}&employeeNum=${employeeNum}&host_url=${host_url}`, data, {
            headers: {"x-access-token":token}
          });
        return response;
    }


}

