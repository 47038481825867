import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
});


export class InventoryFechtAPI {

   //Function to get inventory by products
   static async getInventoryByProducts(data, token) {
    const res = await server(`/inventory/product`, {
        method: "post",
        data: data,
        headers: { 'x-access-token': token },
    });
    return res
}
}