import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});


const serverAlterWhatssap = axios.create({
    baseURL: "https://ushopperbot.herokuapp.com/api",
    headers: { 'Content-Type': "application/json" },
});


export class WhatsappFetchAPI {

    static async sendNotificationWhatsapp(data, token) {
        const res = await server(`/whatssapp/notification`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async sentDiscountNotification(data, token) {
        const res = await serverAlterWhatssap(`/message`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }


}
