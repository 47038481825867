import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import QRCode from "react-qr-code";
import ErrorAlert from '../Alerts/ErrorAlert';
import imgCheck from "./img/check.png"
import { RenderIf } from '../utils/RenderIf';

function ModalConfirmation({ setResponseQr, setPageLoader, orderCode, orderNumber, total, change, setChange, totaltopay, invoiceTips, microsModule, datatransaction_code, order_id, printOrder }) {
    const [show, setShow] = useState(true);
    let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
    let routeQr = "https://smartpay.ai/order/" + orderCode + "/branchoffice/" + branchoffice.id


    const newOrder = () => {
        let message = "Gracias por su compra."
        let title = "Transaccion exitosa"
        let icon = 'success'

        setChange(0)
        setPageLoader(1)
        setResponseQr(false)
        ErrorAlert(message, title, icon)

        //This request validates that the transaction count exists in localstoage
        if (localStorage.getItem('countTransaction') === undefined || localStorage.getItem('countTransaction') === '' || localStorage.getItem('countTransaction') === null) {
            localStorage.setItem('countTransaction', 0);
        } else {
            //increments and validates the transaction counter determining the limit to update the page
            let countTransaction = parseFloat(localStorage.getItem('countTransaction'));
            if (countTransaction < 50) {
                localStorage.setItem('countTransaction', (countTransaction + 1));
            } else {
                localStorage.setItem('countTransaction', 0);
                updatePage()
            }

        }
    }
    console.log(routeQr);

    const updatePage = () => {
        window.location.reload()
    }

    return (
        <>
            <Modal
                show={show}
                size="md"
                onHide={() => setShow(false)}
                backdrop="static"
                centered
            >

                <Modal.Body>
                    <div className="m-4 d-flex align-items-center justify-content-center ">
                        <QRCode value={routeQr} size={150} bgColor="#282c34" fgColor="#fff" level="H" />
                    </div>
                    <div className="bg-light border-sp d-flex align-items-center justify-content-center ">
                        <img className='m-2' src={imgCheck} width="60px"></img>
                        <div >
                            <h1 className='text-center'>Orden: {orderNumber}</h1>

                            <div className=" col-md-12 d-flex  p-2 text-center" >
                                <span className="text-muted border border-2 rounded-3 p-1  w-100 bg-light"> Total de la orden:<br></br>
                                    <span translate='no' className='fw-bold'>${total.toFixed(2)}</span>
                                </span>
                            </div>
                            {invoiceTips > 0 ? <div>

                                <div className=" col-md-12 d-flex  p-2 text-center" >
                                    <span className="text-muted border border-2 rounded-3 p-1  w-100 bg-light"> Propina:<br></br>
                                        <span translate='no' className='fw-bold'>${invoiceTips}</span>
                                    </span>
                                </div>

                                <div className=" col-md-12 d-flex  p-2 text-center" >
                                <span className="text-muted border border-2 rounded-3 p-1  w-100 bg-light"><strong> Total pagado:</strong><br></br>
                                        <span translate='no' className='fw-bold'>${totaltopay}</span>
                                    </span>
                                </div>
                                
                            </div> : ""}

                            {change > 0 ? <><h3 className='text-center text-danger'>Total del cambio: <p translate='no'>${change}</p></h3></> : ""}

                        </div>

                    </div>

                    <div className="m-3 d-flex align-items-center justify-content-center ">
                        <Button className='m-1' variant='success btn-lg' onClick={() => newOrder()}>Nueva orden</Button>
                        <RenderIf isTrue={microsModule == 1}><Button className='m-1' variant='success btn-lg' onClick={() => printOrder(order_id, datatransaction_code, true)}>Imprimir en quiosco</Button></RenderIf>
                    </div>
                </Modal.Body>
            </Modal>


        </>
    )
}

export default ModalConfirmation