import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import { DonleeFechtAPI } from '../../api/Donlee';
import ErrorAlert from '../Alerts/ErrorAlert';
import { RenderIf } from '../utils/RenderIf';

import Accordion from 'react-bootstrap/Accordion';

function ModalDonlee({ client_phone, setClient_phone, locator, setLocator, dataClient, setDataClient }) {
    const [showModal, setShowModal] = useState(false)
    let token = localStorage.getItem('x-access-machine-token');
    const [tempClient_Phone, setTempClient_Phone] = useState("");
    const [handler, setHandler] = useState(0)

    const getClientByPhone = async () => {
        if (tempClient_Phone == "") {
            ErrorAlert("Ingresa el numero telefonico del cliente", "Debes ingresar el numero", "warning");
        } else {


            try {
                const response = await DonleeFechtAPI.getClientPhone(tempClient_Phone, token)
                console.log(response);
                setDataClient(response.data.client)
                console.log(response.data.client);

                setClient_phone(tempClient_Phone)
                ErrorAlert(" ", "Cliente guardado para la transaccion", "success")
                setHandler(1)
            } catch (err) {
                console.log(err);
                ErrorAlert(" ", "Ocurrio un error en la consulta", "error")
            }
        }
    }

    const handlerSavePhone = () => {
        setClient_phone(tempClient_Phone)
        ErrorAlert(" ", "Cliente guardado para la transaccion", "success")
        setShowModal(false)
    }

    const handlerSaveLocator = (number) => {
        console.log(number);
        if (number == "" || number == null || number == undefined) {
            setTempClient_Phone("0")
        } else {
            setTempClient_Phone(number)
        }
    }

    //Hook to load the initial info
    useEffect(() => {
        setHandler(0)
    }, [handler]);


    return (
        <>


            {/* <div className="col-6 col-md-8 mt-5">
                <Button className='m-1' onClick={() => (setShowModal(true))}>
                    Paisanitos
                </Button>
            </div> */}

            <div
                show={showModal}
                size="md"
                onHide={() => setShowModal(true)}
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >

                <Modal.Body>


                    <div className='row'>
                        <div className="col-12 col-lg-6">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Paisanitos: </Accordion.Header>
                                    <Accordion.Body>
                                        <div className='row'>
                                            <div className=' col-sm-8'>
                                                <input type="text" className="form-control border" placeholder='Ingrese número de teléfono ' onChange={(e) => (handlerSaveLocator(e.target.value))} />
                                            </div>

                                            <div className=' col-sm-4'>
                                                <button className='btn btn-primary' onClick={async () => getClientByPhone()}>Validar</button>
                                            </div>
                                        </div>
                                        <div className='col-md-6 mt-2'>
                                            {dataClient.length == 0 ?
                                                <>
                                                    <div className='row justify-content-center'>
                                                        <div className='col-sm-12'>
                                                            <a href="#" className="list-group-item list-group-item-action d-flex gap-3 py-3 rounded" aria-current="true">
                                                                <div className="d-flex gap-2 w-100 justify-content-around">
                                                                    <h5 className=" opacity-25 text-center">
                                                                        No se encontraron datos
                                                                    </h5>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </> : <>
                                                    {dataClient.map((client) => (
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <a href="#" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                                                                    <div className="d-flex gap-2 w-100 justify-content-around">
                                                                        <div>
                                                                            <h6 className=" text-center">Nombre: {client.name}</h6>
                                                                            <h6 className=" text-center">Telefono: {client.phone}</h6>
                                                                            <h6 className=" text-center">Email: {client.email}</h6>
                                                                            <h3 className=" text-center">Puntos: {client.points}</h3>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            {/*<div className='col-12'>
                                            <div className='d-flex justify-content-between mt-2'>
                                                <button className='btn btn-secondary border-0 btn-lg' onClick={() => setShowModal(false)}> Cancelar </button>
                                                <button className='btn btn-success btn-lg' onClick={() => handlerSavePhone()} > Aplicar  </button>
                                            </div>
                                        </div>*/}
                                                        </div>
                                                    ))}
                                                </>}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>

                        <div className="col-12 col-lg-6">
                            <Accordion defaultActiveKey={['0']} >
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Localizador:{locator}</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='d-flex justify-content-center'>
                                            <div>
                                                <span className='text-primary fw-bold'>Ingrese número de localizador</span>
                                                <input type="text" className="form-control border w-50" placeholder={'Número de localizador '} onChange={(e) => (setLocator(e.target.value))} />
                                            </div>
                                        </div>
                                        <div className='row justify-content-center'>
                                            <div className='col-md-6 mt-2'>
                                                <div className='row'>
                                                    <div className='col-sm-12'>
                                                        <a href="#" className="list-group-item list-group-item-action d-flex gap-3 py-3 rounded" aria-current="true">
                                                            <div className="d-flex gap-2 w-100 justify-content-around">
                                                                <div>
                                                                    <h5 className=" text-center">Localizador</h5>
                                                                    <h1 className=" text-center">{locator}</h1>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </Modal.Body>
            </div>
        </>
    )
}

export default ModalDonlee